import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { HiOutlineSparkles } from "react-icons/hi2";
import { Combobox } from "@components/utils/Combobox";
import { useSelector } from "react-redux";
import categories from "@data/categories.js";

const CategorySelection = ({ transaction, updateTransaction }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [filteredCategories, setFilteredCategories] = useState(categories);

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  if (transaction.category) {
    const selectedCategory = categories.find((cat) => cat.id == transaction.category);
    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1.5 overflow-hidden">
          <span className="truncate">{selectedCategory ? t(`transactions.${selectedCategory.id}`) : transaction.category}</span>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, category: null }, needTransactionsUpdate: true });
            }}
          />
        </div>
      </div>
    );
  }

  if (transaction.aiCategory && transaction.aiCategoryConfidence > 0.65) {
    const selectedCategory = categories.find((cat) => cat.id == transaction.aiCategory);
    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-purple-100 via-purple-50 to-purple-100 text-purple-500 px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1">
          <div className="flex items-center gap-1 overflow-hidden">
            <HiOutlineSparkles className="text-sm" />
            <span className="truncate">{selectedCategory ? t(`transactions.${selectedCategory.id}`) : transaction.aiCategory}</span>
          </div>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, aiCategory: null } });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <Combobox
        value={categories.find((category) => category.id == transaction.category)}
        options={filteredCategories}
        onChange={(category) => updateTransaction({ params: { ...transaction, category: category?.id || null }, needTransactionsUpdate: true })}
        getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
        displayValue={(e) => {
          if (!e) return null;
          return t(`transactions.${e.id}`);
        }}
        placeholder={t("category")}
        containerClassName="h-8 rounded-md border-gray-300 "
        width="w-full"
      />
    </div>
  );
};

export default CategorySelection;
