const invoiceTranslations = {
  FR: {
    address: "Adresse",
    invoice: "Facture",
    registration_number: "Numéro d'enregistrement",
    vat_number: "TVA",
    due_date: "Date d'échéance",
    name: "Nom",
    quantity: "Quantité",
    unit_price: "Prix unitaire",
    tax: "Taxe",
    products_edit_price_ht: "Prix HT",
    summary: "Résumé",
    sub_total: "Sous-total",
    discount: "Remise",
    invoices_editor_total_incl_tax: "Total TTC",
    invoices_editor_terms_conditions: "Termes et conditions",
    invoices_editor_terms_conditions_desc:
      "Veuillez payer dans les 30 jours. En cas de retard de paiement, une pénalité de 15% d'intérêt sera appliquée, à laquelle s'ajoutera une indemnité forfaitaire pour frais de recouvrement de 40 €.",
    custom_reference: "Référence personnalisée",
    custom_reference_placeholder: "Votre référence personnalisée",
  },
  EN: {
    address: "Address",
    invoice: "Invoice",
    registration_number: "Registration Number",
    vat_number: "VAT",
    due_date: "Due Date",
    name: "Name",
    quantity: "Quantity",
    unit_price: "Unit Price",
    tax: "VAT",
    products_edit_price_ht: "Price excl. VAT",
    summary: "Summary",
    sub_total: "Sub Total",
    discount: "Discount",
    invoices_editor_total_incl_tax: "Total Incl. VAT",
    invoices_editor_terms_conditions: "Terms and Conditions",
    invoices_editor_terms_conditions_desc:
      "Please pay within 30 days. In the event of late payment, a penalty of 15% interest rate will be applied, to which will be added a lump sum compensation for recovery costs of €40.",
    custom_reference: "Custom Reference",
    custom_reference_placeholder: "Your custom reference",
  },
};

function getTranslation(text, language) {
  return invoiceTranslations[language][text] || text;
}

export { getTranslation, invoiceTranslations };
