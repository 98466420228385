import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaFileInvoice, FaRegMoneyBillAlt, FaCashRegister } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import FileInputBtn from "@components/utils/FileInputBtn";
import Loader from "@components/utils/Loader";
import { classNames, currencyToStr, getTransactionAccountName } from "@utils";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import ThirdPartySelection from "./thirdPartySelection";
import TypeSelection from "./typeSelection";
import CategorySelection from "./categorySelection";

import StripeLogo from "@assets/stripe-logo.png";
import { MdArrowRight } from "react-icons/md";
import { GoArrowSwitch } from "react-icons/go";

const Transaction = ({
  transaction,
  index,
  setSelectedTransactions,
  selectedTransactions,
  updateTransaction,
  clientOptions,
  supplierOptions,
  employeeOptions,
  accountOptions,
  setLoading,
  loading,
  onSave,
  transactionsToReconcile,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const isBundledTransactions = transaction?.StripeTransaction?.ReversePayoutTransaction?.length || transaction?.category === "2100";
  const isTransactionSelected = selectedTransactions?.some((t) => t._id === transaction._id);

  const handleReconcile = async (e, transaction) => {
    e.preventDefault();
    setLoading((prev) => ({
      ...prev,
      [transaction._id]: true,
    }));
    try {
      // Create a copy of the transaction to apply AI suggestions if needed
      const transactionToReconcile = { ...transaction };

      // Apply AI type if not explicitly set but AI suggestion exists with good confidence
      if (!transactionToReconcile.type && transactionToReconcile.aiType && transactionToReconcile.aiTypeConfidence > 0.65)
        transactionToReconcile.type = transactionToReconcile.aiType;

      // Apply AI category if not explicitly set but AI suggestion exists with good confidence
      if (!transactionToReconcile.category && transactionToReconcile.aiCategory && transactionToReconcile.aiCategoryConfidence > 0.65)
        transactionToReconcile.category = transactionToReconcile.aiCategory;

      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transactionToReconcile, status: "RECONCILED" });
      if (!ok) throw { code };

      if (transaction.Invoices) {
        transaction.Invoices.forEach(async (invoice) => {
          await api.put(`/invoice/${invoice._id}`, { status: "PAID" });
        });
      }

      if (selectedTransactions.find((t) => t._id === transaction._id)) {
        setSelectedTransactions(selectedTransactions.filter((t) => t._id !== transaction._id));
      }
      toast.success(t("toast.success.reconciled"));
      onSave();
    } catch (e) {
      toast.error(e.code);
    } finally {
      setLoading((prev) => ({
        ...prev,
        [transaction._id]: false,
      }));
    }
  };

  const canReconcile = (transaction) => {
    // Check if there's either a category or an AI category with good confidence
    const hasCategory = transaction.category || (transaction.aiCategory && transaction.aiCategoryConfidence > 0.65);
    // Check if there's either a type or an AI type with good confidence
    const hasType = transaction.type || (transaction.aiType && transaction.aiTypeConfidence > 0.65);

    if (!hasCategory || !hasType) return false;

    // Get effective type (user selected or AI suggested)
    const effectiveType = transaction.type || transaction.aiType;

    if (effectiveType === "CLIENT" && !transaction.ClientId && !transaction.ClientIdSuggestion) return false;
    if (effectiveType === "SUPPLIER" && !transaction.SupplierId && !transaction.SupplierIdSuggestion) return false;
    if (effectiveType === "EMPLOYEE" && !transaction.EmployeeId && !transaction.EmployeeIdSuggestion) return false;
    if (effectiveType === "TRANSFER" && !transaction.TransferAccountId) return false;

    return true;
  };

  const handleInvoiceUpload = async (file) => {
    if (!file) return;
    setLoading((prev) => ({
      ...prev,
      [transaction._id]: true,
    }));
    try {
      const { data } = await api.post("/invoice", {
        reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
        status: "DUE",
        source: "EXTERNAL",
        file: file.target.value,
      });
      updateTransaction({
        params: { ...transaction, Invoices: [data, ...transaction.Invoices] },
        needTransactionsUpdate: true,
      });
    } catch (error) {
      console.error("Error uploading invoice:", error);
      toast.error("Failed to upload invoice");
    } finally {
      setLoading((prev) => ({
        ...prev,
        [transaction._id]: false,
      }));
    }
  };

  const handlePurchaseInvoiceUpload = async (file) => {
    if (!file) return;
    setLoading((prev) => ({
      ...prev,
      [transaction._id]: true,
    }));
    try {
      const { data } = await api.post("/purchase-invoice", {
        reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
        file: file.target.value,
      });
      updateTransaction({
        params: { ...transaction, PurchaseInvoices: [data, ...transaction.PurchaseInvoices] },
        needTransactionsUpdate: true,
      });
    } catch (error) {
      console.error("Error uploading purchase invoice:", error);
      toast.error("Failed to upload purchase invoice");
    } finally {
      setLoading((prev) => ({
        ...prev,
        [transaction._id]: false,
      }));
    }
  };

  return (
    <>
      {loading[transaction._id] && (
        <tr className="absolute flex items-center justify-center w-full h-16">
          <td colSpan="10" className="flex gap-2">
            <Loader size={"small"} />
          </td>
        </tr>
      )}

      <tr
        onClick={() => {
          if (isBundledTransactions) return setOpen((prev) => !prev);
          if (isTransactionSelected) return setSelectedTransactions(selectedTransactions.filter((t) => t._id !== transaction._id));
          return setSelectedTransactions([...selectedTransactions, transaction]);
        }}
        key={transaction._id}
        className={classNames("text-xs", isTransactionSelected ? "bg-blue-200" : "bg-white hover:bg-blue-100", loading[transaction._id] ? "opacity-40" : "opacity-100")}>
        <td className={`whitespace-nowrap text-center px-1 border-l border-l-gray-300 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          {isBundledTransactions ? (
            <div
              className="flex items-center gap-1 underline text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpen((prev) => !prev);
              }}>
              <MdArrowRight className={classNames("text-2xl transition duration-100 ease-in-out", open ? "rotate-90" : "rotate-0")} />
            </div>
          ) : (
            <input readOnly type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" checked={isTransactionSelected} />
          )}
        </td>

        <td className={`px-2 py-2 whitespace-nowrap ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          {new Date(transaction.date).toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </td>

        <td className={`pl-0 pr-1 py-2 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          <div
            className="cursor-pointer flex flex-col hover:underline hover:text-blue-600 transition-colors duration-200"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/transactions?id=${transaction._id}`);
            }}>
            <div className="flex items-center gap-2.5">
              <div className="flex items-center justify-center w-8 h-8">
                <TransactionIcon transaction={transaction} />
              </div>
              <div className="w-full overflow-hidden">
                <div
                  className="font-medium text-left line-clamp-2 w-full text-gray-800 leading-tight"
                  title={transaction.name || transaction.description || transaction.additionalInformation}>
                  {transaction.name || transaction.description || transaction.additionalInformation}
                </div>
                <div className="text-gray-500 text-xs text-left truncate mt-0.5 font-light">{getTransactionAccountName(transaction)}</div>
              </div>
            </div>
          </div>
        </td>

        <td className={`px-2 py-2 whitespace-nowrap ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          <div className=" flex items-center justify-end gap-1">
            <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
          </div>
        </td>

        {/* Type */}
        <td className={`relative px-2 py-2 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          {transaction.category !== "2100" && <TypeSelection transaction={transaction} updateTransaction={updateTransaction} />}
        </td>

        <td className={`max-w-[12rem] px-2 py-2 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          <ThirdPartySelection
            transaction={transaction}
            updateTransaction={updateTransaction}
            supplierOptions={supplierOptions}
            clientOptions={clientOptions}
            employeeOptions={employeeOptions}
            accountOptions={accountOptions}
          />
        </td>

        <td className={`max-w-[8rem] px-2 py-2 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          <CategorySelection transaction={transaction} updateTransaction={updateTransaction} />
        </td>

        <td className={`px-2 whitespace-nowrap gap-2 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          <div className="flex justify-start items-center gap-1">
            {transaction.amount > 0 ? (
              <FileInputBtn onChange={handleInvoiceUpload} name={transaction._id} folder="Invoices" />
            ) : transaction.amount < 0 ? (
              <FileInputBtn onChange={handlePurchaseInvoiceUpload} name={transaction._id} folder="PurchaseInvoices" />
            ) : null}

            {transaction.PurchaseInvoices.length > 0 && (
              <div className="relative group ml-1">
                <div className="flex justify-center items-center">
                  <a href={transaction.PurchaseInvoices[0].file} target="_blank" rel="noopener noreferrer" title={transaction.PurchaseInvoices[0].fileName}>
                    <FaFileInvoice size={17} />
                    <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-white group-hover:scale-100">{transaction.PurchaseInvoices[0].reference}</span>
                  </a>
                  {transaction.PurchaseInvoices.length > 1 && (
                    <div className="absolute -top-2 -right-1.5 bg-blue-500 text-white text-xs rounded-full w-[14px] h-[14px] flex items-center justify-center">
                      {transaction.PurchaseInvoices.length}
                    </div>
                  )}
                </div>
              </div>
            )}

            {transaction.Invoices.length > 0 && (
              <div className="relative group ml-1">
                <div className="flex justify-center items-center">
                  <a href={transaction.Invoices[0].file} target="_blank" rel="noopener noreferrer" title={transaction.Invoices[0].fileName}>
                    <FaFileInvoice size={17} />
                    <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{transaction.Invoices[0].reference}</span>
                  </a>
                  {transaction.Invoices.length > 1 && (
                    <div className="absolute -top-2 -right-1.5 bg-blue-500 text-white text-xs rounded-full w-[14px] h-[14px] flex items-center justify-center">
                      {transaction.Invoices.length}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </td>

        <td className={`pl-1 pr-2 whitespace-nowrap border-r border-r-gray-300 ${index !== transactionsToReconcile?.length - 1 && "border-b-[1px] border-gray-200"}`}>
          <button
            className="px-2 py-1.5 w-full font-medium text-xs bg-blue-500 hover:bg-blue-600 text-white rounded-md flex items-center justify-center gap-1.5 transition-colors duration-200 disabled:opacity-50 disabled:bg-gray-400"
            onClick={(e) => {
              e.stopPropagation();
              handleReconcile(e, transaction);
              Mixpanel.track(MIXPANEL_EVENTS.btn_reconcile_transactions);
            }}
            disabled={!canReconcile(transaction)}
            data-tooltip-id={`button-tooltip-${transaction._id}`}
            data-tooltip-content={canReconcile(transaction) ? "" : t("transaction.reconcile_disable")}>
            <span>{t("reconcile")}</span>
            <GoArrowSwitch className="text-sm w-3.5 h-3.5 my-[3px]" />
          </button>
          <Tooltip id={`button-tooltip-${transaction._id}`} style={{ zIndex: 10, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
        </td>
      </tr>

      {open && !transaction?.StripeTransaction?.ReversePayoutTransaction?.length && (
        <tr className="hover:bg-gray-100 border-y border-gray-200">
          <td colSpan={9} className="whitespace-nowrap px-2.5 border-l p-2 text-center">
            {t("no_data")}
          </td>
        </tr>
      )}

      {open &&
        transaction?.StripeTransaction?.ReversePayoutTransaction?.map((subTransaction) => {
          return (
            <tr key={subTransaction.id} className="text-xs hover:bg-gray-100 border-y border-gray-200">
              <td className={`whitespace-nowrap px-2.5 text-sm border-l`}></td>
              <td className={`w-24 px-2 py-2 text-sm border-l`}>
                <div className="inline-block">{subTransaction.created.slice(0, 10)}</div>
              </td>
              <td>
                <div className="flex items-center gap-1 w-fit">
                  <img src={StripeLogo} height={20} width={20} className="mr-2" />
                  <div> {subTransaction.description}</div>
                </div>
              </td>
              <td />
              <td>
                <div className="flex flex-col">
                  <span>
                    {subTransaction?.Client?.firstname} {subTransaction?.Client?.lastname}
                  </span>
                  <span>{subTransaction?.Client?.companyName ? `${subTransaction?.Client?.companyName}` : ""}</span>
                </div>
              </td>
              <td>{t(`stripe_transaction.status.${subTransaction?.type?.toUpperCase()}`)}</td>
              <td />
              <td className="w-full">
                <SubAmount amount={subTransaction.amount} fee={subTransaction.fee} currency={currencyToStr(subTransaction.currency)} />
              </td>
              <td className={`w-24 px-1 py-2 whitespace-nowrap border-r  border-l  right-0`}></td>
            </tr>
          );
        })}
    </>
  );
};

// todo if stripe payout, display a group of transactions, else display normal transactions
// duplicates children dans transactions ?
// mettre les payout avec un code comptable spécifique, pour les exclures stats

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`py-1 rounded-sm flex justify-center items-center font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

const SubAmount = ({ amount, fee, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`px-2 py-1 rounded-sm flex flex-col justify-center items-end text-sm ${color}`}>
      <span className={color}>
        {amount}
        {currency}
      </span>
      <span className="text-red-700 text-xs">
        -{fee}
        {currency}
      </span>
    </div>
  );
};

const TransactionIcon = ({ transaction }) => {
  if (transaction.accountDetails === "petty_cash")
    return (
      <div className="border border-blue-600 h-6 w-6 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaCashRegister size={16} className="text-blue-600" />
      </div>
    );
  if (transaction.accountDetails === "savings")
    return (
      <div className="border border-green-600 h-6 w-6 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaRegMoneyBillAlt size={16} className="text-green-600" />
      </div>
    );
  if (transaction.bankLogo) return <img src={transaction.bankLogo} height={24} width={24} />;
};

export default Transaction;
