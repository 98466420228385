import { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "@services/api";
import { HiOutlineSparkles } from "react-icons/hi";

import Loader from "../../../components/utils/Loader";
import { useSelector } from "react-redux";
import Transaction from "./transaction";
import { HiOutlineInformationCircle } from "react-icons/hi";

const TransactionsToReconcile = ({ loadingTransaction, transactions, selectedTransactions, setSelectedTransactions, onSave }) => {
  const [transactionsToReconcile, setTransactionsToReconcile] = useState(transactions);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [loading, setLoading] = useState({});
  const [accountOptions, setAccountOptions] = useState([]);

  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    setTransactionsToReconcile(transactions);
  }, [transactions]);

  const getClients = async () => {
    const { data: clientRes } = await api.post(`/client/search`, { per_page: 1000 });
    setClientOptions(clientRes.clients);
  };

  const getSuppliers = async () => {
    const { data: supplierRes } = await api.post(`/supplier/search`, { per_page: 1000 });
    setSupplierOptions(supplierRes.suppliers);
  };

  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000 });
    setEmployeeOptions(employeeRes.employees);
  };

  const getAccounts = async () => {
    try {
      const { ok, data } = await api.post("/account/search", { OrganizationId: organization?._id, includePettyCash: true, includeSavings: true });
      if (!ok) {
        return toast.error(t("error.something_went_wrong"));
      }
      setAccountOptions(data);
    } catch (error) {
      console.log("error getAccounts", error);
      toast.error(t("error.something_went_wrong"));
    }
  };

  useEffect(() => {
    getClients();
    getSuppliers();
    getEmployees();
  }, [transactions]);

  useEffect(() => {
    if (!organization) return;
    getAccounts();
  }, [organization]);

  const updateTransaction = async ({ params, successMessage = "toast.sucess.updated_sucessfully" }) => {
    try {
      if (selectedTransactions.some((t) => t._id === params._id)) {
        const updatedSelectedTransactions = selectedTransactions.map((transaction) => {
          if (transaction._id === params._id) return { ...transaction, ...params };
          return transaction;
        });
        setSelectedTransactions(updatedSelectedTransactions);
      }

      setTransactionsToReconcile(
        transactionsToReconcile.map((transaction) => {
          if (transaction._id === params._id) return { ...transaction, ...params };
          return transaction;
        }),
      );

      const { ok } = await api.put(`/transaction/${params._id}`, params);
      if (!ok) {
        return;
      }

      onSave();
    } catch (error) {
      console.log("error simple update", error);
      toast.error(error.code);
    } finally {
      setLoading((prev) => ({
        ...prev,
        [params._id]: false,
      }));
    }
  };

  return (
    <div className={`flow-root min-w-full align-middle`}>
      <table className="table-auto border-separate border-spacing-0">
        <thead className="z-10 bg-[#f5f6fc] h-10 sticky top-16">
          {/* Coins de remplissage */}
          <div className="absolute -left-0.5 top-0 w-4 h-4 bg-[#f5f6fc] -z-10" />
          <div className="absolute -right-0.5 top-0 w-4 h-4 bg-[#f5f6fc] -z-10" />
          <tr>
            <th className="w-7 whitespace-nowrap px-1 pt-1.5 text-center text-sm font-semibold bg-white border-y border-l border-gray-300 rounded-tl-xl">
              <input
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                checked={selectedTransactions?.length === transactions.length}
                onChange={() => {
                  if (selectedTransactions?.length === transactions.length) {
                    setSelectedTransactions([]);
                  } else {
                    setSelectedTransactions(transactions);
                  }
                }}
              />
            </th>
            <th scope="col" className="whitespace-nowrap px-2 pb-1 pt-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("date")}
            </th>
            <th scope="col" className="whitespace-nowrap pl-9 pr-1 pb-1 pt-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("name")}
            </th>
            <th scope="col" className="w-20 whitespace-nowrap px-2 pb-1 pt-2 text-right text-xs font-semibold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("amount")}
            </th>
            <th scope="col" className="min-w-[7rem] whitespace-nowrap px-2 pb-1 pt-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-y border-gray-300">
              <div className="flex items-center gap-1">
                {t("type")}
                <div className="relative">
                  <HiOutlineInformationCircle
                    className="h-4 w-4 text-gray-400 hover:text-purple-500 transition-colors"
                    onMouseEnter={(e) => {
                      const tooltip = e.currentTarget.nextElementSibling;
                      tooltip.classList.remove("opacity-0", "invisible");
                      tooltip.classList.add("opacity-100", "visible");
                    }}
                    onMouseLeave={(e) => {
                      const tooltip = e.currentTarget.nextElementSibling;
                      tooltip.classList.add("opacity-0", "invisible");
                      tooltip.classList.remove("opacity-100", "visible");
                    }}
                  />
                  <div className="absolute -left-10 top-full mt-2 opacity-0 invisible transition-opacity duration-150 ease-in-out w-64 bg-white text-gray-700 text-xs p-3 rounded-lg shadow-md z-50 border border-gray-100">
                    <div className="flex items-center gap-2 mb-1.5 text-sm font-medium text-purple-600">
                      <HiOutlineSparkles className="h-4 w-4 text-purple-500" />
                      <span>{t("transaction.ai_suggestion")}</span>
                    </div>
                    <p className="whitespace-normal leading-relaxed font-normal normal-case">{t("transaction.ai_tooltip")}</p>
                    <div className="absolute -top-1 left-11 w-2 h-2 bg-white border-t border-l border-gray-100 transform rotate-45"></div>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" className="min-w-[13rem] whitespace-nowrap px-2 pb-1 pt-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("tiers")}
            </th>
            <th scope="col" className="min-w-[11rem] whitespace-nowrap px-2 pb-1 pt-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-y border-gray-300">
              <div className="flex items-center gap-1">
                {t("category")}
                <div className="relative">
                  <HiOutlineInformationCircle
                    className="h-4 w-4 text-gray-400 hover:text-purple-500 transition-colors"
                    onMouseEnter={(e) => {
                      const tooltip = e.currentTarget.nextElementSibling;
                      tooltip.classList.remove("opacity-0", "invisible");
                      tooltip.classList.add("opacity-100", "visible");
                    }}
                    onMouseLeave={(e) => {
                      const tooltip = e.currentTarget.nextElementSibling;
                      tooltip.classList.add("opacity-0", "invisible");
                      tooltip.classList.remove("opacity-100", "visible");
                    }}
                  />
                  <div className="absolute -left-10 top-full mt-2 opacity-0 invisible transition-opacity duration-150 ease-in-out w-64 bg-white text-gray-700 text-xs p-3 rounded-lg shadow-md z-50 border border-gray-100">
                    <div className="flex items-center gap-2 mb-1.5 text-sm font-medium text-purple-600">
                      <HiOutlineSparkles className="h-4 w-4 text-purple-500" />
                      <span>{t("transaction.ai_suggestion")}</span>
                    </div>
                    <p className="whitespace-normal leading-relaxed font-normal normal-case">{t("transaction.ai_tooltip")}</p>
                    <div className="absolute -top-1 left-11 w-2 h-2 bg-white border-t border-l border-gray-100 transform rotate-45"></div>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" className="w-16 px-2 pb-1 pt-2 pr-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("proof")}
            </th>
            <th
              scope="col"
              className="w-32 whitespace-nowrap px-3 pb-1 pt-2 text-center text-xs font-semibold text-gray-500 uppercase bg-white rounded-tr-xl border-y border-r border-gray-300">
              {t("action")}
            </th>
          </tr>
        </thead>
        <tbody className="relative">
          {loadingTransaction && (
            <tr className="absolute z-10 inset-0 bg-black bg-opacity-30 flex items-start justify-center w-full h-full">
              <td colSpan="10" className="text-center pt-4 sticky top-1/3">
                <Loader className="m-5" />
                <p className="text-sm text-white">{t("transaction.loading_message")}</p>
              </td>
            </tr>
          )}
          {transactionsToReconcile.map((transaction, index) => {
            return (
              <Transaction
                key={transaction._id}
                transaction={transaction}
                index={index}
                setSelectedTransactions={setSelectedTransactions}
                selectedTransactions={selectedTransactions}
                updateTransaction={updateTransaction}
                clientOptions={clientOptions}
                supplierOptions={supplierOptions}
                employeeOptions={employeeOptions}
                accountOptions={accountOptions}
                setLoading={setLoading}
                loading={loading}
                onSave={onSave}
                transactionsToReconcile={transactionsToReconcile}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsToReconcile;
