import categories from "@data/categories.js";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { classNames, currencyToStr, getAccountName } from "@utils";
import CreateTransaction from "@components/modals/createTransaction";
import { Combobox } from "@components/utils/Combobox";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { SearchBar } from "@components/utils/SearchBar";
import { Select } from "@components/utils/Select";

import { Pagination } from "./pagination";
import EditTransactionModal from "./editTransactionModal";
import AllTransactions from "./allTransactions";
import TransactionsToReconcile from "./transactionsToReconcile";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import MultipleActionBar from "./multipleActionBar";

import { HiOutlineSparkles } from "react-icons/hi2";
import { FaRegMoneyBillAlt, FaCashRegister } from "react-icons/fa";
import { IoFilterOutline } from "react-icons/io5";

const ALL = "ALL";
const TO_RECONCILE = "TO_RECONCILE";

const List = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [transactions, setTransactions] = useState([]);
  const [transactionsToReconcile, setTransactionsToReconcile] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalToReconcile, setTotalToReconcile] = useState(0);
  const [tab, setTab] = useState(query.get("tab") || ALL);
  const [filter, setFilter] = useState({
    per_page: 20,
    page: 1,
    search: "",
    account: null,
    category: null,
    type: "",
    amountMin: null,
    amountMax: null,
    startDate: "",
    endDate: "",
  });
  const [accountOptions, setAccountOptions] = useState([]);
  const [clientOptions, setClientsOptions] = useState([]);
  const [supplierOptions, setSuppliersOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [open, setOpen] = useState(query.get("id"));
  const [openBankAccountToRenew, setOpenBankAccountToRenew] = useState(false);
  const [showCreateTransaction, setShowCreateTransaction] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const navigate = useNavigate();

  const getTransactions = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
      amountMin: filter.amountMin,
      amountMax: filter.amountMax,
      startDate: filter.startDate,
      endDate: filter.endDate,
    });
    setTransactions(data.transactions);
    setTotal(data.total);
  };

  const getTransactionsToReconciled = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      status: TO_RECONCILE,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
      orderBy: "date",
      order: "DESC",
      amountMin: filter.amountMin,
      amountMax: filter.amountMax,
      startDate: filter.startDate,
      endDate: filter.endDate,
    });

    setTransactionsToReconcile(data.transactions);
    setTotalToReconcile(data.total);
  };

  useEffect(() => {
    if (!organization) return;
    const promises = [getTransactions(), getTransactionsToReconciled()];
    Promise.all(promises);
  }, [filter, organization]);

  const getAccounts = async () => {
    try {
      const { ok, data } = await api.post("/account/search", { OrganizationId: organization?._id, includePettyCash: true, includeSavings: true });
      if (!ok) {
        return toast.error(t("toast.error.fetching_accounts"));
      }
      if (data?.find((account) => ["EXPIRED", "ERROR_3", "SUSPENDED"].includes(account.status) && ["ACTIVE"].includes(account.Requisition.status))) {
        setOpenBankAccountToRenew(true);
      }
      setAccountOptions(data);
    } catch (error) {
      console.log("error getAccounts", error);
      toast.error(t("toast.error.fetching_accounts"));
    }
  };

  useEffect(() => {
    if (!organization) return;
    getAccounts();
    Mixpanel.track(MIXPANEL_EVENTS.page_view_transactions);
  }, [organization]);

  useEffect(() => {
    if (!organization) return;
    getClients();
    getSuppliers();
    getEmployees();
  }, [transactionsToReconcile]);

  const getClients = async () => {
    const { data } = await api.post("/client/search", { per_page: 1000, OrganizationId: organization?._id });
    setClientsOptions(data.clients);
  };
  const getSuppliers = async () => {
    const { data } = await api.post("/supplier/search", { per_page: 1000, OrganizationId: organization?._id });
    setSuppliersOptions(data.suppliers);
  };
  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000 });
    setEmployeeOptions(employeeRes.employees);
  };

  useEffect(() => {
    if (query.get("id")) setOpen(true);
  }, [query]);

  const handleTabChange = (newTab) => {
    setTab(newTab);
    const newQuery = new URLSearchParams(query);
    newQuery.set("tab", newTab);
    navigate(`?${newQuery.toString()}`);
  };

  useEffect(() => {
    // Synchroniser l'URL avec l'état du tab actuel si le paramètre manque
    if (!query.get("tab") && tab) {
      const newQuery = new URLSearchParams(query);
      newQuery.set("tab", tab);
      navigate(`?${newQuery.toString()}`, { replace: true });
    }
  }, [tab, query, navigate]);

  // Écouter les changements du paramètre tab dans l'URL
  useEffect(() => {
    const tabParam = query.get("tab");
    if (tabParam && tabParam !== tab) {
      setTab(tabParam);
    }
  }, [query]);

  if (!transactions) return <Loader />;

  return (
    <div className="mx-auto">
      <EditTransactionModal
        isOpen={open}
        onClose={() => {
          navigate("/transactions");
          setOpen(false);
        }}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
      />
      <CreateTransaction
        isOpen={showCreateTransaction}
        onClose={() => setShowCreateTransaction(false)}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
        accountOptions={accountOptions}
      />
      <RenewAccountModal
        isOpen={false}
        onClose={() => {
          setOpen(false);
          setOpenBankAccountToRenew(false);
        }}
      />
      <div>
        <div className="flex items-center justify-between p-2 mb-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white">
          <span className="font-medium text-sm">{t("copilot.banner.title")}</span>
          <button
            onClick={() => {
              Mixpanel.track(MIXPANEL_EVENTS.btn_goto_copilot_from_transactions);
              navigate("/copilot");
            }}
            className="flex items-center gap-2 px-2.5 py-1 bg-white/20 rounded-full transition-all duration-300 hover:bg-white/30">
            <HiOutlineSparkles className="text-sm" />
            <span className="font-medium text-xs">{t("copilot.banner.button")}</span>
          </button>
        </div>
        <div className="flex flex-col px-4">
          <div className="flex flex-col bg-white border border-gray-300 rounded-xl p-4">
            <div className="flex gap-3  justify-between mb-2">
              <div>
                <h1 className="text-2xl font-bold ">{t("transactions.title_page")}</h1>
                <p className="mt-2 text-slate-600">{t("transactions_intro")}</p>
              </div>

              <div>
                <button className="btn-primary focus:bg-blue float-right" onClick={() => setShowCreateTransaction(true)}>
                  {t("transactions.add_transaction")}
                </button>
              </div>
            </div>

            <div className="w-full flex flex-col gap-2 text-sm">
              <div className="w-full grid grid-cols-1 lg:grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr,auto] items-end gap-4">
                <div className="w-full col-span-3">
                  <label className="text-xs text-gray-500 ">{t("search")}</label>
                  <SearchBar search={filter.search} setFilter={setFilter} className="w-full text-lg" />
                </div>

                <div className="w-full col-span-2">
                  <label className="text-xs text-gray-500 ">{t("account")}</label>
                  <Select
                    options={accountOptions}
                    value={filter.account}
                    onChange={(e) => setFilter((f) => ({ ...f, account: e }))}
                    placeholder={t("account")}
                    getLabel={(account) => <AccountOption account={account} />}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full col-span-1">
                  <label className="text-xs text-gray-500 ">{t("start_date")}</label>
                  <input
                    type="date"
                    value={filter.startDate}
                    onChange={(e) => setFilter((f) => ({ ...f, startDate: e.target.value }))}
                    className={`w-full px-3 py-1 text-sm rounded-md border border-gray-300 focus:border-sky-700 ${!filter.startDate ? "text-gray-400" : ""}`}
                    placeholder={t("start_date")}
                  />
                </div>
                <div className="w-full col-span-1">
                  <label className="text-xs text-gray-500 ">{t("end_date")}</label>
                  <input
                    type="date"
                    value={filter.endDate}
                    min={filter.startDate}
                    onChange={(e) => setFilter((f) => ({ ...f, endDate: e.target.value }))}
                    className={`w-full px-3 py-1 text-sm rounded-md border border-gray-300 focus:border-sky-700 ${!filter.endDate ? "text-gray-400" : ""}`}
                    placeholder={t("end_date")}
                  />
                </div>

                <div className="w-full border-l border-gray-200 pl-4">
                  <button
                    onClick={() => setShowMoreFilters(!showMoreFilters)}
                    className={`flex items-center justify-between gap-2 px-4 py-1.5 text-sm font-medium border rounded-md
              ${showMoreFilters ? "bg-blue-50 text-blue-600 border-blue-300" : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"}`}>
                    <div className="relative w-4 h-4 flex items-center justify-center">
                      <IoFilterOutline className={`${showMoreFilters ? "text-blue-600" : "text-gray-700"} text-sm`} />
                    </div>
                    <span className="text-sm">{t("more_filters")}</span>
                  </button>
                </div>
              </div>

              <AnimatePresence>
                {showMoreFilters && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="w-full grid grid-cols-1 lg:grid-cols-6 items-start gap-x-4 gap-y-2">
                    <div className="w-full border-b mt-2 mb-1 col-span-6"></div>
                    <div className="w-full">
                      <label className="text-xs text-gray-500 ">{t("client")}</label>
                      <Combobox
                        options={clientOptions}
                        value={clientOptions.find((client) => client._id === filter?.ClientId)}
                        onChange={async (e) => {
                          setFilter((f) => ({ ...f, ClientId: e._id }));
                        }}
                        placeholder={t("client")}
                        getLabel={(client) => {
                          if (client.entityType === "COMPANY") return client?.companyName || "";
                          return `${client?.firstname || ""} ${client?.lastname || ""}`;
                        }}
                        displayValue={(client) => {
                          if (!client) return null;
                          if (client.entityType === "COMPANY") return client?.companyName;
                          return `${client?.firstname || ""} ${client?.lastname || ""}`;
                        }}
                        className="shadow-sm"
                      />
                    </div>

                    <div className="w-full">
                      <label className="text-xs text-gray-500 ">{t("supplier")}</label>
                      <Combobox
                        options={supplierOptions}
                        value={supplierOptions.find((supplier) => supplier._id === filter?.SupplierId)}
                        onChange={async (e) => {
                          setFilter((f) => ({ ...f, SupplierId: e._id }));
                        }}
                        placeholder={t("supplier")}
                        getLabel={(supplier) => {
                          if (supplier.entityType === "COMPANY") return supplier?.companyName || "";
                          return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                        }}
                        displayValue={(supplier) => {
                          if (!supplier) return null;
                          if (supplier.entityType === "COMPANY") return supplier?.companyName;
                          return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                        }}
                        className="shadow-sm"
                      />
                    </div>

                    <div className="w-full">
                      <label className="text-xs text-gray-500 ">{t("amount")}</label>
                      <Select
                        options={["INCOME", "EXPENSE"]}
                        value={filter.type}
                        onChange={(e) => setFilter((f) => ({ ...f, type: e }))}
                        placeholder={t("amount")}
                        getLabel={(type) => <span>{t(`transactions.${type}`)}</span>}
                        width="w-full"
                        nullable={true}
                        className="shadow-sm"
                      />
                    </div>

                    <div className="w-full flex gap-2 col-span-2">
                      <div className="flex-1">
                        <label className="text-xs text-gray-500 ">{t("amount_min")}</label>
                        <input
                          type="number"
                          placeholder={t("amount_min")}
                          value={filter.amountMin || ""}
                          onChange={(e) => setFilter((f) => ({ ...f, amountMin: e.target.value ? Number(e.target.value) : null }))}
                          className="w-full text-sm px-3 py-1 rounded-md border border-gray-300 focus:border-sky-700"
                        />
                      </div>
                      <div className="flex-1">
                        <label className="text-xs text-gray-500 ">{t("amount_max")}</label>
                        <input
                          type="number"
                          placeholder={t("amount_max")}
                          value={filter.amountMax || ""}
                          onChange={(e) => setFilter((f) => ({ ...f, amountMax: e.target.value ? Number(e.target.value) : null }))}
                          className="w-full px-3 py-1 text-sm rounded-md border border-gray-300 focus:border-sky-700"
                        />
                      </div>
                    </div>

                    <div className="w-full relative">
                      <label className="text-xs text-gray-500 ">{t("category")}</label>
                      <Select
                        options={categories}
                        value={filter.category}
                        onChange={(e) => setFilter((f) => ({ ...f, category: e }))}
                        placeholder={t("category")}
                        getLabel={(category) => (category ? category.id + " - " + t(`transactions.${category.id}`) : null)}
                        width="w-full"
                        nullable={true}
                        className="shadow-sm"
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="w-full bg-[#f5f6fc] sticky top-0 z-20">
            <nav className="flex flex-row w-full justify-between gap-8 items-center my-2 px-2 pl-4 bg-white rounded-xl border border-gray-300">
              <div className="flex">
                <TabItem title={t("all")} tab={ALL} setTab={handleTabChange} active={tab === ALL} total={total} />
                <TabItem title={t("to_reconcile")} tab={TO_RECONCILE} setTab={handleTabChange} active={tab === TO_RECONCILE} total={totalToReconcile} />
              </div>
              {selectedTransactions.length > 0 && tab === TO_RECONCILE && (
                <MultipleActionBar
                  supplierOptions={supplierOptions}
                  clientOptions={clientOptions}
                  employeeOptions={employeeOptions}
                  accountOptions={accountOptions}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  setLoadingTransaction={setLoadingTransaction}
                  onSave={() => {
                    getTransactionsToReconciled();
                    getTransactions();
                  }}
                />
              )}
            </nav>
          </div>

          <div className="w-full">
            {tab === ALL && (
              <>
                <AllTransactions transactions={transactions} />
                <div className="w-full sticky bottom-0 pb-2 bg-[#f5f6fc]">
                  <Pagination total={total} filter={filter} setFilter={setFilter} />
                </div>
              </>
            )}
            {tab === TO_RECONCILE && (
              <>
                <TransactionsToReconcile
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  transactions={transactionsToReconcile}
                  loadingTransaction={loadingTransaction}
                  setLoadingTransaction={setLoadingTransaction}
                  onSave={() => {
                    getTransactionsToReconciled();
                    getTransactions();
                  }}
                />
                <div className="w-full sticky bottom-0 pb-2 bg-[#f5f6fc]">
                  <Pagination total={totalToReconcile} filter={filter} setFilter={setFilter} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, total }) => {
  return (
    <button
      onClick={() => setTab(tab)}
      className={classNames(
        "flex-1 py-3 px-4 text-center text-sm font-medium transition-all duration-200",
        active ? "text-blue-500 font-extrabold bg-blue-50 border-b-2 border-blue-500" : "text-gray-500 border-b-2 border-white hover:border-gray-300 hover:bg-gray-100",
      )}>
      <div className="flex items-center justify-center whitespace-nowrap">
        {title} ({total})
      </div>
    </button>
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash")
    return (
      <div className="border border-blue-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaCashRegister size={12} className="text-blue-600" />
      </div>
    );
  if (account.details === "savings")
    return (
      <div className="border border-green-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaRegMoneyBillAlt size={12} className="text-green-600" />
      </div>
    );
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

const RenewAccountModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} innerClassName="flex max-w-[40rem] w-[40vw] max-h-[calc(100vh-5rem)]">
      <div className="flex flex-col items-center justify-center w-full p-5">
        <div className="mb-5">{t("myintegrations.need_reconnection")}</div>
        <button
          className="btn-primary w-max"
          onClick={(e) => {
            Mixpanel.track(MIXPANEL_EVENTS.btn_renew_account_requisition);
            navigate("/bank");
          }}>
          {t("myintegrations.renew_account")}
        </button>
      </div>
    </Modal>
  );
};

export default List;
