import Modal from "@components/utils/Modal";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import onboarding from "../../assets/onboarding.png";
import finotor from "../../assets/icon.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import api from "@services/api";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setUser, setOrganization } from "@redux/auth/actions";
import LoadingButton from "@components/utils/LoadingButton";
import { FaFileInvoiceDollar, FaUniversity } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const STEPS = {
  WELCOME: "WELCOME",
  PERSONAL_INFO: "PERSONAL_INFO",
  COMPANY_INFO: "COMPANY_INFO",
  CONNECT_BANK: "CONNECT_BANK",
};

const Onboarding = ({ open }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, organization } = useSelector((state) => state.Auth);

  const queryParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(queryParams.entries());

  const isValidStep = params.step && Object.values(STEPS).includes(params.step);

  const [step, setStep] = useState(() => (isValidStep ? params.step : STEPS.WELCOME));
  const updateStep = (newStep) => {
    setStep(newStep);
    const params = new URLSearchParams(location.search);
    params.set("step", newStep);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  useEffect(() => {
    if (isValidStep && params.step !== step) {
      setStep(params.step);
    }
  }, [params.step]);

  useEffect(() => {
    // Track mixpanel events when step changes
    switch (step) {
      case STEPS.WELCOME:
        setProgress(0);
        Mixpanel.track(MIXPANEL_EVENTS.page_view_onboarding_welcome);
        break;
      case STEPS.PERSONAL_INFO:
        setProgress(18);
        Mixpanel.track(MIXPANEL_EVENTS.page_view_onboarding_personal_info);
        break;
      case STEPS.COMPANY_INFO:
        setProgress(50);
        Mixpanel.track(MIXPANEL_EVENTS.page_view_onboarding_company_info);
        break;
      case STEPS.CONNECT_BANK:
        setProgress(85);
        Mixpanel.track(MIXPANEL_EVENTS.page_view_onboarding_connect_bank);
        break;
      default:
        setProgress(0);
    }
  }, [step]);

  const onClose = async () => {
    try {
      const { ok, data } = await api.put(`/user/${user._id}`, { onboardedAt: new Date() });
      if (!ok) throw new Error(t("toast.error.updating_profile"));
      dispatch(setUser(data));
      if (organization.plan === "free" && !organization.freeTrialEndAt) {
        const organizationResponse = await api.put(`/organization/${organization._id}/start-free-trial`);
        if (!organizationResponse.ok) {
          toast.error(t("toast.error"));
        } else {
          dispatch(setOrganization(organizationResponse.data));
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.some_error") + e);
    }
  };

  const goBack = () => {
    switch (step) {
      case STEPS.PERSONAL_INFO:
        updateStep(STEPS.WELCOME);
        break;
      case STEPS.COMPANY_INFO:
        updateStep(STEPS.PERSONAL_INFO);
        break;
      case STEPS.CONNECT_BANK:
        updateStep(STEPS.COMPANY_INFO);
        break;
      default:
        updateStep(STEPS.WELCOME);
    }
  };

  return (
    <Modal
      isOpen={open}
      innerClassName="flex flex-col max-w-[65rem] w-[65vw] max-h-[calc(100vh-5rem)] bg-gradient-to-b from-white to-slate-50 rounded-xl shadow-2xl overflow-hidden">
      {/* Progress indicators */}
      {step !== STEPS.WELCOME && (
        <div className="px-12 pt-8 pb-4">
          {/* New Stepper Design */}
          <div className="stepper-container relative pb-2">
            {/* Progress bar underneath */}
            <div className="absolute h-1 bg-gray-200 w-full top-5 z-0">
              <div className="h-1 bg-blue-500 transition-all duration-500 ease-in-out" style={{ width: `${progress}%` }}></div>
            </div>

            {/* Steps */}
            <div className="flex justify-around relative z-10">
              {Object.keys(STEPS)
                .filter((s) => s !== "WELCOME")
                .map((stepKey, index) => {
                  const stepIndex = Object.keys(STEPS).indexOf(stepKey);
                  const currentStepIndex = Object.keys(STEPS).indexOf(step);
                  const isActive = stepIndex === currentStepIndex;
                  const isCompleted = stepIndex < currentStepIndex || progress === 100;

                  return (
                    <div key={stepKey} className={`flex flex-col items-center`}>
                      {/* Step Circle */}
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 transition-all duration-300
                          ${
                            isCompleted ? "bg-blue-500 text-white" : isActive ? "bg-white border-2 border-blue-500 text-blue-500" : "bg-white border border-gray-300 text-gray-400"
                          }`}>
                        {isCompleted ? (
                          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 13L9 17L19 7" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <span>{index + 1}</span>
                        )}
                      </div>

                      {/* Step Label */}
                      <span
                        className={`text-xs font-medium transition-all duration-300
                          ${isActive ? "text-blue-500" : isCompleted ? "text-gray-700" : "text-gray-400"}`}>
                        {t(`onboarding.steps.${stepKey.toLowerCase()}`)}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}

      {step === STEPS.WELCOME && <Welcome nextStep={() => updateStep(STEPS.PERSONAL_INFO)} />}
      {step === STEPS.PERSONAL_INFO && <PersonalInfo nextStep={() => updateStep(STEPS.COMPANY_INFO)} />}
      {step === STEPS.COMPANY_INFO && <CompanyInfo nextStep={() => updateStep(STEPS.CONNECT_BANK)} prevStep={goBack} />}
      {step === STEPS.CONNECT_BANK && <ConnectBank finishOnboarding={() => setProgress(100)} params={params} prevStep={goBack} onClose={onClose} />}
    </Modal>
  );
};

const Welcome = ({ nextStep }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col items-center justify-center px-16 py-16 text-center bg-gradient-to-b from-white to-blue-50 rounded-3xl shadow-xl">
      <img src={finotor} alt="Finotor" className="w-24 h-24 mb-8 object-contain filter drop-shadow-[0_0_8px_rgba(59,130,246,0.3)]" />

      <h1 className="text-4xl font-extrabold bg-gradient-to-r from-blue-600 via-blue-500 to-cyan-500 bg-clip-text text-transparent tracking-tight">
        {t("onboarding.welcome_finotor")}
      </h1>

      <div className="mt-10 max-w-xl p-6 rounded-2xl">
        <p className="text-slate-700 leading-relaxed text-lg">{t("onboarding.description_2")}</p>
      </div>

      <button
        className="mt-10 bg-blue-500 hover:bg-blue-600 text-white py-3 px-10 rounded-full font-medium shadow-lg shadow-blue-200 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        onClick={nextStep}>
        {t("lets_go")}
      </button>
    </div>
  );
};

const PersonalInfo = ({ nextStep }) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    firstname: user.firstname || "",
    lastname: user.lastname || "",
    language: user.language || "EN",
  });

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await api.put(`/user/${user._id}`, values);
      if (!res.ok) {
        toast.error(t("toast.error.updating_profile"));
      } else {
        i18n.changeLanguage(values.language);
        toast.success(t("toast.sucess.updated"));
        dispatch(setUser(res.data));
        nextStep();
      }
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.some_error") + e);
    }
    setLoading(false);
  }

  return (
    <div className="w-full py-8 px-12 flex flex-col overflow-y-auto bg-gradient-to-b from-white to-blue-50">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-800">{t("onboarding.personal_info.title")}</h1>
        <p className="text-slate-500 mt-2">{t("onboarding.personal_info.description")}</p>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col gap-6 flex-1">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-1">{t("first_name")}</label>
            <input
              className={`px-4 py-2 rounded-lg transition-all duration-200 bg-white border focus:ring-2 focus:ring-opacity-50 outline-none border-gray-200 focus:border-blue-500 focus:ring-blue-200`}
              value={values.firstname}
              onChange={(e) => setValues({ ...values, firstname: e.target.value })}
              placeholder={t("enter_first_name")}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-1">{t("last_name")}</label>
            <input
              className={`px-4 py-2 rounded-lg transition-all duration-200 bg-white border focus:ring-2 focus:ring-opacity-50 outline-none border-gray-200 focus:border-blue-500 focus:ring-blue-200`}
              value={values.lastname}
              onChange={(e) => setValues({ ...values, lastname: e.target.value })}
              placeholder={t("enter_last_name")}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">{t("profile.lang.my_lang")}</label>
          <div className="relative">
            <select
              className="w-full appearance-none px-4 py-2 rounded-lg bg-white border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 outline-none transition-all duration-200"
              value={values.language}
              onChange={(e) => setValues({ ...values, language: e.target.value })}>
              <option value="EN">{t("profile.lang.EN")}</option>
              <option value="FR">{t("profile.lang.FR")}</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-500">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center mt-auto pt-8">
          <LoadingButton
            type="submit"
            loading={loading}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center min-w-[140px] shadow-lg shadow-blue-200/50 disabled:opacity-50 disabled:cursor-not-allowed disabled:shadow-none">
            {t("continue")}
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

const CompanyInfo = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: organization.name || "",
    email: organization.email || "",
    activity: organization.activity || "",
    currency: "",
    registrationNumber: organization.registrationNumber || "",
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.put(`/organization/${organization._id}`, values);
      if (!res.ok) {
        toast.error(t("toast.error.organization_updating"));
      } else {
        toast.success(t("toast.sucess.organization_updated"));
        dispatch(setOrganization(res.data));
        nextStep();
      }
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.some_error") + e);
    }
    setLoading(false);
  }

  return (
    <div className="w-full py-8 px-12 flex flex-col overflow-y-auto bg-gradient-to-b from-white to-blue-50">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-800">{t("onboarding.company_info.title")}</h1>
        <p className="text-slate-500 mt-2">{t("onboarding.company_info.description")}</p>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col gap-8 flex-1">
        <div className="mb-4">
          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-1">{t("company_name")}</label>
              <input
                className="px-4 py-2 rounded-lg bg-white border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 outline-none transition-all duration-200"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                placeholder={t("company_name")}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-1">{t("business_email")}</label>
              <input
                className="px-4 py-2 rounded-lg bg-white border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 outline-none transition-all duration-200"
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
                placeholder={t("business_email")}
                type="email"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-1">{t("business_registration_number")}</label>
              <input
                className="px-4 py-2 rounded-lg bg-white border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 outline-none transition-all duration-200"
                value={values.registrationNumber}
                onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
                placeholder={t("business_registration_number")}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-1">
                {t("currency")} <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <select
                  className="w-full appearance-none px-4 py-2 rounded-lg bg-white border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 outline-none transition-all duration-200"
                  value={values?.currency ?? "EUR"}
                  onChange={(e) => setValues({ ...values, currency: e.target.value })}>
                  <option value="">{t("select_currency")}</option>
                  <option value="EUR">EUR (€)</option>
                  <option value="GBP">GBP (£)</option>
                  <option value="USD">USD ($)</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-500">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex flex-col col-span-2">
              <label className="text-sm font-medium text-gray-700 mb-1">{t("organization.activity")}</label>
              <textarea
                id="activity"
                rows={3}
                className="px-4 py-2 rounded-lg bg-white border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 outline-none transition-all duration-200"
                value={values?.activity ?? ""}
                onChange={(e) => setValues({ ...values, activity: e.target.value })}
                placeholder={t("organization.activity_placeholder")}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-auto pt-4">
          <button type="button" onClick={prevStep} className="flex items-center text-gray-500 hover:text-gray-700 font-medium transition-colors duration-200">
            <svg className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            {t("onboarding.bank_sync.back")}
          </button>
          <LoadingButton
            type="submit"
            loading={loading}
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2.5 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center min-w-[120px]"
            disabled={!values.currency}>
            {t("continue")}
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

const ConnectBank = ({ finishOnboarding, prevStep, onClose, params }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [syncingNordigen, setSyncingNordigen] = useState(false);
  const [syncProgress, setSyncProgress] = useState(1);
  const { organization } = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  async function syncNordigen() {
    try {
      if (syncingNordigen) return;

      setSyncingNordigen(true);
      setSyncProgress(1);

      // Step 1: Sync requisitions
      const { ok } = await api.post("/requisition/sync");
      if (!ok) throw new Error(t("toast.error.onboarding.sync_accounts"));

      // Wait a moment to show the first step completion
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Move to step 2
      setSyncProgress(2);

      // Step 2: Sync transactions
      const res = await api.post("/transaction/sync");
      if (!res.ok) throw new Error(t("toast.error.onboarding.sync_accounts"));

      // Success state will be handled by the caller (not handling completion yet)
      setSyncProgress(3);
      finishOnboarding();

      // Note: We're not completing the sync flow as requested
      // The UI will stay in the syncing state
    } catch (e) {
      toast.error(t("toast.error") + (e.code ? ` (${e.code})` : ""));
      setSyncingNordigen(false);
    }
  }

  async function searchBank() {
    try {
      if (!search.trim()) {
        setBanks([]);
        return;
      }
      setIsSearching(true);
      const res = await api.post("/nordigen-institution/search", { search, limit: 15 });
      setBanks(res.data.banks);
      setIsSearching(false);
    } catch (e) {
      console.error(e);
      toast.error(t("toast.error.onboarding.sync_accounts"));
      setIsSearching(false);
    }
  }

  useEffect(() => {
    if (params.ref) syncNordigen();
  }, [params.ref]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        searchBank();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleBankSubmit = async () => {
    try {
      if (!selectedBank) return toast.error(t("onboarding.bank_sync.select_bank"));
      setLoading(true);
      const res = await api.post("/nordigen-institution/connect", {
        bankId: selectedBank.nordigenId,
        OrganizationId: organization._id,
        redirectPath: "?step=CONNECT_BANK",
      });

      if (res.link) {
        window.location.href = res.link;
      } else {
        setSyncingNordigen(true);
        syncNordigen();
      }
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.some_error") + e.code);
    } finally {
      setLoading(false);
    }
  };

  if (syncingNordigen) return <Synchronization currentStep={syncProgress} onClose={onClose} />;

  return (
    <div className="w-full py-8 px-12 flex flex-col overflow-y-auto bg-gradient-to-b from-white to-blue-50">
      <div className="text-center mb-10">
        <h1 className="text-3xl font-bold mb-3 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">{t("onboarding.bank_sync.connect_title")}</h1>
        <p className="text-gray-600 max-w-xl mx-auto">{t("onboarding.bank_sync.connect_description")}</p>
      </div>

      <div className="bg-white rounded-xl shadow-md p-6 mb-8">
        <div className="relative mb-6">
          <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
            <HiMagnifyingGlass className="h-5 w-5 text-blue-500" aria-hidden="true" />
          </div>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="block w-full h-12 rounded-lg border-0 pl-12 pr-4 bg-gray-50 focus:ring-2 focus:ring-blue-300 focus:bg-white shadow-sm transition-all duration-200"
            placeholder={t("onboarding.bank_sync.search_placeholder")}
          />
          {search && (
            <button className="absolute inset-y-0 right-0 flex items-center pr-3" onClick={() => setSearch("")}>
              <svg className="h-5 w-5 text-gray-400 hover:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          )}
        </div>

        {isSearching && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        )}

        {!isSearching && banks.length === 0 && search && (
          <div className="text-center py-8">
            <FaUniversity className="mx-auto text-gray-300 mb-3" size={48} />
            <p className="text-gray-500">
              {t("onboarding.bank_sync.no_bank_found")} "{search}"
            </p>
            <p className="text-sm text-gray-400 mt-1">{t("onboarding.bank_sync.try_another_search")}</p>
          </div>
        )}

        {!isSearching && banks.length === 0 && !search && (
          <div className="text-center py-8">
            <FaUniversity className="mx-auto text-gray-300 mb-3" size={48} />
            <p className="text-gray-500">{t("onboarding.bank_sync.start_typing")}</p>
          </div>
        )}

        {!isSearching && banks.length > 0 && (
          <div className="overflow-y-auto max-h-72 rounded-lg border border-gray-100">
            {banks.map((bank) => (
              <div
                key={bank._id}
                className={`flex items-center p-4 border-b border-gray-100 cursor-pointer transition-all duration-200 ${
                  selectedBank?._id === bank._id ? "bg-blue-50 border-blue-200" : "hover:bg-gray-50"
                }`}
                onClick={() => setSelectedBank(bank)}>
                <div className="w-12 h-12 flex items-center justify-center bg-white rounded-lg shadow-sm mr-4 p-2">
                  <img src={bank.logo} alt={bank.name} className="max-w-full max-h-full object-contain" />
                </div>
                <div className="flex-1">
                  <div className="font-medium text-gray-800">{bank.name}</div>
                  <div className="text-xs text-gray-500">{bank.bic}</div>
                </div>
                {selectedBank?._id === bank._id && (
                  <div className="ml-2 text-blue-500">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="mt-auto">
        <div className="w-full h-px bg-gray-200 mb-6"></div>

        <div className="flex justify-between items-center">
          <button type="button" onClick={prevStep} className="flex items-center text-gray-500 hover:text-gray-700 font-medium transition-colors duration-200">
            <svg className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            {t("onboarding.bank_sync.back")}
          </button>
          <div className="flex gap-5 items-center">
            <button
              type="button"
              onClick={() => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_onboarding_ignore_bank_connection);
                onClose();
              }}
              className="text-gray-500 hover:text-gray-700 font-medium transition-colors duration-200">
              {t("onboarding.bank_sync.skip")}
            </button>
            <LoadingButton
              loading={loading}
              onClick={handleBankSubmit}
              disabled={!selectedBank}
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center min-w-[140px] shadow-lg shadow-blue-200/50 disabled:opacity-50 disabled:cursor-not-allowed disabled:shadow-none">
              {t("onboarding.bank_sync.connect")}
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const Synchronization = ({ currentStep = 1, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentStep === 3) Mixpanel.track(MIXPANEL_EVENTS.page_view_onboarding_bank_success);
  }, [currentStep]);

  return (
    <div className="w-full py-2 px-8 flex flex-col items-center justify-start min-h-[500px] bg-gradient-to-b from-white to-blue-50 overflow-hidden">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">{t("onboarding.bank_sync.title")}</h1>
        <p className="text-gray-600 mt-2">{t("onboarding.bank_sync.please_wait")}</p>
        <p className="text-sm text-orange-400 mt-3">{t("onboarding.bank_sync.warning")}</p>
      </div>

      <div className="relative w-full max-w-2xl mx-auto mt-8 perspective-1000">
        {/* First Card - Bank Connection */}
        <div
          className={`bg-white border border-blue-100 rounded-xl p-4 transition-all duration-700 ease-out transform ${
            currentStep >= 2 ? "shadow-lg -translate-y-16 scale-[0.98] z-0" : "shadow-xl shadow-blue-100/70"
          } ${currentStep >= 3 ? "-translate-y-24 scale-[0.95]" : ""}`}>
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              <div className="bg-blue-50 p-3 rounded-full mr-5">
                <div>
                  <FaUniversity className="w-10 h-10 text-blue-500" />
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-800">{t("onboarding.bank_sync.step1_title")}</h3>
                <p className="text-gray-500 mt-1">{t("onboarding.bank_sync.step1_description")}</p>
              </div>
            </div>
            <div className="ml-4">
              {currentStep !== 1 && (
                <div className="h-10 w-10 bg-green-100 rounded-full flex items-center justify-center">
                  <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              )}
            </div>
          </div>

          {currentStep === 1 && (
            <div className="w-full bg-gray-100 h-2 rounded-full mt-6 overflow-hidden">
              <div className="bg-blue-500 h-full rounded-full w-1/4 relative animate-[shimmer_2s_ease-in-out_infinite] before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/25 before:to-transparent"></div>
            </div>
          )}
        </div>

        {/* Second Card - Transaction Retrieval */}
        <div
          className={`bg-white border border-blue-100 rounded-xl p-4 absolute top-0 left-0 right-0 transition-all duration-700 ease-out transform ${
            currentStep >= 2 ? "translate-y-10 opacity-100 z-20 scale-[1.02] shadow-2xl shadow-indigo-200/80" : "translate-y-6 opacity-0 -z-10 shadow-lg"
          } ${currentStep >= 3 ? "-translate-y-4 scale-[0.98] z-10" : ""}`}>
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              <div className="bg-indigo-50 p-3 rounded-full mr-5">
                <div>
                  <FaFileInvoiceDollar className="w-10 h-10 text-indigo-500" />
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-800">{t("onboarding.bank_sync.step2_title")}</h3>
                <p className="text-gray-500 mt-1">{t("onboarding.bank_sync.step2_description")}</p>
              </div>
            </div>
            {currentStep === 3 && (
              <div className="h-10 w-10 bg-green-100 rounded-full flex items-center justify-center">
                <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </div>

          {currentStep === 2 && (
            <div className="w-full bg-gray-100 h-2 rounded-full mt-6 overflow-hidden">
              <div className="bg-indigo-500 h-full rounded-full w-1/4 relative animate-[shimmer_2s_ease-in_infinite] before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/25 before:to-transparent"></div>
            </div>
          )}
        </div>

        {/* Third Card - Success and Next Steps */}
        <div
          className={`bg-white border border-green-100 rounded-xl p-5 absolute top-0 left-0 right-0 transition-all duration-700 ease-out transform ${
            currentStep >= 3 ? "translate-y-20 opacity-100 z-30 scale-[1.1] shadow-xl shadow-green-200/80" : "translate-y-6 opacity-0 -z-10 shadow-lg"
          }`}>
          <div className="flex items-center">
            <div className="bg-green-50 p-2 rounded-full mr-4">
              <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">{t("toast.sucess.account_synced")}</h3>
              <p className="text-gray-500 mt-1">{t("onboarding.bank_sync.account_synced_description")}</p>
            </div>
          </div>

          <div className="mt-4 text-center">
            <button
              onClick={() => {
                onClose();
                navigate("/transactions");
              }}
              className="bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 text-white px-5 py-2 rounded-lg font-medium transition-all duration-200 shadow-md">
              {t("onboarding.bank_sync.view_transactions")}
            </button>
          </div>
        </div>
      </div>

      <style jsx global>{`
        @keyframes shimmer {
          0% {
            left: -100%;
          }
          100% {
            left: 100%;
          }
        }
        .perspective-1000 {
          perspective: 1000px;
        }
      `}</style>
    </div>
  );
};

export default Onboarding;
