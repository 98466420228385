import { useTranslation } from "react-i18next";
import { Paginator } from "@components/utils/Pagination";
import { Select } from "@components/utils/Select";

export const Pagination = ({ total, filter, setFilter }) => {
  const { t } = useTranslation();
  return (
    <div
      className="flex flex-row justify-between items-center w-full bg-white rounded-br-xl rounded-bl-xl
         py-2 px-6 border border-gray-300">
      <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />

      <div className="flex items-center gap-3">
        <p className=" whitespace-nowrap">{t("rows_per_page")}</p>
        <div className=" w-16">
          <Select
            options={[20, 50, 100]}
            value={filter.per_page}
            onChange={(e) => setFilter({ ...filter, per_page: e })}
            placeholder={t("per_page")}
            width="w-25"
            position="left-0 bottom-full"
          />
        </div>
        <p className=" whitespace-nowrap text-end ">
          <span className=" font-bold">
            {(filter.page - 1) * filter.per_page + 1} - {filter.page * filter.per_page}
          </span>
          {t("of")} {total}
        </p>
      </div>
    </div>
  );
};
