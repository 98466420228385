import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAccountName, currencyToStr } from "@utils";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { HiOutlineSparkles } from "react-icons/hi2";

import { Combobox } from "@components/utils/Combobox";
import { Select } from "@components/utils/Select";
import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";

import { FaRegMoneyBillAlt, FaCashRegister } from "react-icons/fa";

const ThirdPartySelection = ({ transaction, updateTransaction, supplierOptions, clientOptions, employeeOptions, accountOptions }) => {
  if (transaction.category === "2100") return null;

  switch (transaction.type) {
    case "SUPPLIER":
      return <Supplier transaction={transaction} updateTransaction={updateTransaction} supplierOptions={supplierOptions} />;
    case "CLIENT":
      return <Client transaction={transaction} updateTransaction={updateTransaction} clientOptions={clientOptions} />;
    case "EMPLOYEE":
      return <Employee transaction={transaction} updateTransaction={updateTransaction} employeeOptions={employeeOptions} />;
    case "TRANSFER":
      return <Transfer transaction={transaction} updateTransaction={updateTransaction} accountOptions={accountOptions} />;
    default:
      break;
  }

  switch (transaction.aiType) {
    case "SUPPLIER":
      return <Supplier transaction={transaction} updateTransaction={updateTransaction} supplierOptions={supplierOptions} />;
    case "CLIENT":
      return <Client transaction={transaction} updateTransaction={updateTransaction} clientOptions={clientOptions} />;
    case "EMPLOYEE":
      return <Employee transaction={transaction} updateTransaction={updateTransaction} employeeOptions={employeeOptions} />;
    case "TRANSFER":
      return <Transfer transaction={transaction} updateTransaction={updateTransaction} accountOptions={accountOptions} />;
    default:
      return null;
  }
};

const Transfer = ({ transaction, updateTransaction, accountOptions }) => {
  const { t } = useTranslation();

  if (transaction.TransferAccountId) {
    const selectedAccount = accountOptions.find((a) => a._id === transaction.TransferAccountId);

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1.5">
          <AccountOption account={selectedAccount} />
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, TransferAccountId: null }, needTransactionsUpdate: true });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <Select
      options={accountOptions}
      value={accountOptions.find((account) => account._id == transaction.TransferAccountId) || null}
      onChange={(e) => {
        updateTransaction({ params: { ...transaction, TransferAccountId: e?._id || null } });
      }}
      containerClassName="h-8 rounded-md border-gray-300"
      placeholder={t("account")}
      getLabel={(account) => <AccountOption account={account} />}
      width="w-full"
      nullable={true}
    />
  );
};

const Client = ({ transaction, updateTransaction, clientOptions, showAiSuggestions }) => {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState();
  const [showCreateClient, setShowCreateClient] = useState(false);

  useEffect(() => {
    setSelectedClientId(transaction?.ClientId || transaction?.ClientIdSuggestion || null);
  }, [transaction]);

  const handleChange = (e) => {
    updateTransaction({
      params: {
        ...transaction,
        ClientId: e?._id || null,
        category: transaction.category || e?.defaultCategory,
      },
      needTransactionsUpdate: true,
    });
    setSelectedClientId(e?._id || null);
  };

  if (transaction.ClientId) {
    const selectedClient = clientOptions.find((c) => c._id === transaction.ClientId);
    const clientLabel = selectedClient?.entityType === "INDIVIDUAL" ? selectedClient?.firstname + " " + selectedClient?.lastname : selectedClient?.companyName || "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center overflow-hidden justify-between gap-1.5">
          <span className="truncate">{clientLabel}</span>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, ClientId: null }, needTransactionsUpdate: true });
            }}
          />
        </div>
      </div>
    );
  }

  if (showAiSuggestions && transaction.AiClientId) {
    const aiClient = clientOptions.find((c) => c._id === transaction.AiClientId);
    const clientLabel = aiClient?.entityType === "INDIVIDUAL" ? aiClient?.firstname + " " + aiClient?.lastname : aiClient?.companyName || "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-purple-100 via-purple-50 to-purple-100 text-purple-500 px-2 py-1.5 rounded-md text-xs flex items-center overflow-hidden justify-between gap-1">
          <div className="flex items-center gap-1 overflow-hidden">
            <HiOutlineSparkles className="text-sm flex-shrink-0" />
            <span className="truncate">{clientLabel}</span>
          </div>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, AiClientId: null } });
            }}
          />
        </div>
      </div>
    );
  }

  if (transaction.ClientIdSuggestion) {
    const suggestedClient = clientOptions.find((c) => c._id === transaction.ClientIdSuggestion);
    const clientLabel = suggestedClient?.entityType === "INDIVIDUAL" ? suggestedClient?.firstname + " " + suggestedClient?.lastname : suggestedClient?.companyName || "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center overflow-hidden justify-between gap-1.5">
          <span className="truncate">
            {clientLabel} ({t("suggested")})
          </span>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, ClientIdSuggestion: null } });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <Combobox
        options={clientOptions}
        value={clientOptions.find((client) => client._id === selectedClientId)}
        onChange={async (e) => handleChange(e)}
        placeholder={t("client")}
        getLabel={(client) => {
          if (!client) return null;
          const clientLabel = client?.entityType === "INDIVIDUAL" ? client?.firstname + " " + client?.lastname : client?.companyName || "";

          if (!transaction?.ClientId && transaction?.ClientIdSuggestion === client._id) {
            return `${clientLabel} (${t("suggested")})`;
          }
          return clientLabel;
        }}
        nullable={true}
        containerClassName="h-8 rounded-md border-gray-300"
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm hover:bg-gray-100"
            onClick={() => {
              setShowCreateClient(true);
            }}>
            {t("clients.create_a_client")} <span>+</span>
          </button>
        }
        displayValue={() => ""}
      />
    </>
  );
};

const Employee = ({ transaction, updateTransaction, employeeOptions }) => {
  const { t } = useTranslation();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);

  useEffect(() => {
    setSelectedEmployeeId(transaction?.EmployeeId || transaction?.EmployeeIdSuggestion || null);
  }, [transaction]);

  const handleChange = (e) => {
    updateTransaction({
      params: {
        ...transaction,
        EmployeeId: e?._id || null,
        category: transaction.category || e?.defaultCategory,
      },
      needTransactionsUpdate: true,
    });
    setSelectedEmployeeId(e?._id || null);
  };

  if (transaction.EmployeeId) {
    const selectedEmployee = employeeOptions.find((e) => e._id === transaction.EmployeeId);
    const employeeLabel = selectedEmployee ? selectedEmployee.firstname + " " + selectedEmployee.lastname : "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between overflow-hidden gap-1.5">
          <span className="truncate">{employeeLabel}</span>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, EmployeeId: null }, needTransactionsUpdate: true });
            }}
          />
        </div>
      </div>
    );
  }

  if (transaction.EmployeeIdSuggestion) {
    const suggestedEmployee = employeeOptions.find((e) => e._id === transaction.EmployeeIdSuggestion);
    const employeeLabel = suggestedEmployee ? suggestedEmployee.firstname + " " + suggestedEmployee.lastname : "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1.5">
          <div className="flex items-center gap-1 overflow-hidden">
            <span className="truncate">
              {employeeLabel} ({t("suggested")})
            </span>
          </div>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, EmployeeIdSuggestion: null } });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <Combobox
        options={employeeOptions}
        value={employeeOptions.find((employee) => employee._id === selectedEmployeeId)}
        onChange={async (e) => handleChange(e)}
        placeholder={t("employee")}
        getLabel={(employee) => {
          if (!employee) return null;
          const employeeLabel = employee.firstname + " " + employee.lastname;
          if (!transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id) return `${employeeLabel} (${t("suggested")})`;
          return employeeLabel;
        }}
        containerClassName="h-8 rounded-md border-gray-300"
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm hover:bg-gray-100"
            onClick={() => {
              setShowCreateEmployee(true);
            }}>
            {t("employees.create")} <span>+</span>
          </button>
        }
        displayValue={() => ""}
      />
    </>
  );
};

const Supplier = ({ transaction, updateTransaction, supplierOptions, showAiSuggestions }) => {
  const { t } = useTranslation();
  const [selectedSupplierId, setSelectedSupplierId] = useState();
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);

  useEffect(() => {
    setSelectedSupplierId(transaction?.SupplierId || transaction?.SupplierIdSuggestion || null);
  }, [transaction]);

  const handleChange = (e) => {
    updateTransaction({
      params: {
        ...transaction,
        SupplierId: e?._id || null,
        category: transaction.category || e?.defaultCategory,
      },
      needTransactionsUpdate: true,
    });
    setSelectedSupplierId(e?._id || null);
  };

  if (transaction.SupplierId) {
    const selectedSupplier = supplierOptions.find((s) => s._id === transaction.SupplierId);
    const supplierLabel = selectedSupplier?.entityType === "INDIVIDUAL" ? selectedSupplier?.firstname + " " + selectedSupplier?.lastname : selectedSupplier?.companyName || "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between overflow-hidden gap-1.5">
          <span className="truncate">{supplierLabel}</span>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, SupplierId: null }, needTransactionsUpdate: true });
            }}
          />
        </div>
      </div>
    );
  }

  if (showAiSuggestions && transaction.AiSupplierId) {
    const aiSupplier = supplierOptions.find((s) => s._id === transaction.AiSupplierId);
    const supplierLabel = aiSupplier?.entityType === "INDIVIDUAL" ? aiSupplier?.firstname + " " + aiSupplier?.lastname : aiSupplier?.companyName || "";

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-purple-100 via-purple-50 to-purple-100 text-purple-500 px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1">
          <div className="flex items-center gap-1 overflow-hidden">
            <HiOutlineSparkles className="text-sm flex-shrink-0" />
            <span className="truncate">{supplierLabel}</span>
          </div>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, AiSupplierId: null } });
            }}
          />
        </div>
      </div>
    );
  }

  if (transaction.SupplierIdSuggestion) {
    const suggestedSupplier = supplierOptions.find((s) => s._id === transaction.SupplierIdSuggestion);
    const supplierLabel = suggestedSupplier?.entityType === "INDIVIDUAL" ? suggestedSupplier?.firstname + " " + suggestedSupplier?.lastname : suggestedSupplier?.companyName || "";

    return (
      <div className="flex items-center">
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between overflow-hidden gap-1.5">
          <div className="flex items-center gap-1 overflow-hidden">
            <span className="truncate">
              {supplierLabel} ({t("suggested")})
            </span>
          </div>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              updateTransaction({ params: { ...transaction, SupplierIdSuggestion: null } });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <Combobox
        options={supplierOptions}
        value={supplierOptions.find((supplier) => supplier._id === selectedSupplierId)}
        onChange={async (e) => handleChange(e)}
        placeholder={t("supplier")}
        getLabel={(supplier) => {
          if (!supplier) return null;
          const supplierLabel = supplier?.entityType === "INDIVIDUAL" ? supplier?.firstname + " " + supplier?.lastname : supplier?.companyName || "";

          if (!transaction?.SupplierId && transaction?.SupplierIdSuggestion === supplier._id) {
            return `${supplierLabel} (${t("suggested")})`;
          }
          return supplierLabel;
        }}
        nullable={true}
        containerClassName="h-8 rounded-md border-gray-300"
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm hover:bg-gray-100"
            onClick={() => {
              setShowCreateSupplier(true);
            }}>
            {t("supplier.create_supplier")} <span>+</span>
          </button>
        }
        displayValue={() => ""}
      />
    </>
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;
  const amount = account.amount ? account.amount + " " + currencyToStr(account.currency) : "";
  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-1 truncate">
        {getAccountName(account)} {amount}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash")
    return (
      <div className="border border-blue-600 h-[18px] w-[18px] rounded-full p-0.5 flex items-center justify-center shadow-sm bg-gray-50">
        <FaCashRegister size={10} className="text-blue-600" />
      </div>
    );
  if (account.details === "savings")
    return (
      <div className="border border-green-600 h-[18px] w-[18px] rounded-full p-0.5 flex items-center justify-center shadow-sm bg-gray-50">
        <FaRegMoneyBillAlt size={10} className="text-green-600" />
      </div>
    );
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-[18px] w-[18px]" />;
};

export default ThirdPartySelection;
