import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import api from "@services/api";
import { FaChevronDown, FaDownload } from "react-icons/fa6";
import { ResponsivePie } from "@nivo/pie";
import { Combobox } from "@components/utils/Combobox.jsx";
import { currencyToStr } from "@utils";
import { Listbox, Switch } from "@headlessui/react";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import CsvDownloadButton from "react-json-to-csv";
import { toast } from "react-hot-toast";
import { PRICING_FEATURES } from "../../constants";
import { useNavigate } from "react-router-dom";

const years = Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => new Date().getFullYear() - i);

const ProfitLoss = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.Auth);
  const [profitLoss, setProfitLoss] = useState({});
  const [profitLossDetails, setProfitLossDetails] = useState({});
  const [filter, setFilter] = useState({
    currency: organization?.currency,
    by: "month",
    to: new Date(),
    since: new Date(new Date().setMonth(new Date().getMonth() - 4)),
  });
  const [accountIds, setAccountIds] = useState(null);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_profit_loss);
  }, []);

  useEffect(() => {
    if (!organization) return;
  }, [organization]);

  const getAccountIds = async () => {
    try {
      const { data: accounts } = await api.post("/account/search", { OrganizationId: organization._id });
      setAccountIds(accounts.map(({ _id }) => _id));
    } catch (e) {
      console.error(e);
      toast.error(t("toast.error.fetching_accounts"));
    }
  };

  const getProfitAndLoss = async () => {
    try {
      const { data } = await api.post("/analytics/profit_loss", { accountsId: accountIds, OrganizationId: organization._id, ...filter });
      setProfitLoss(data);
    } catch (e) {
      console.error(e);
      toast.error(t("toast.error.fetching_profit_loss"));
    }
  };

  const getProfitAndLossDetails = async () => {
    try {
      const { data: details } = await api.post("/analytics/profit_loss_details", { accountsId: accountIds, OrganizationId: organization._id, ...filter });
      setProfitLossDetails(details);
    } catch (e) {
      console.error(e);
      toast.error(t("toast.error.fetching_profit_loss_details"));
    }
  };
  useEffect(() => {
    if (!organization) return;
    getAccountIds();
  }, [organization]);

  useEffect(() => {
    if (accountIds && filter) {
      getProfitAndLoss();
      getProfitAndLossDetails();
    }
  }, [accountIds, filter]);

  const handleSetToDate = (e) => {
    if (!e.target.value) {
      const defaultDate = new Date();
      defaultDate.setUTCMonth(defaultDate.getMonth() + 1);
      defaultDate.setUTCDate(0);
      defaultDate.setUTCHours(0, 0, 0, 0);
      return setFilter((date) => ({ ...date, to: defaultDate }));
    }
    const newDate = new Date(e.target.value);
    newDate.setUTCMonth(newDate.getMonth() + 1);
    newDate.setUTCDate(0);
    newDate.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, to: newDate }));
  };

  const handleSetSinceDate = (e) => {
    if (!e.target.value) {
      const defaultDate = new Date();
      defaultDate.setUTCMonth(defaultDate.getMonth() - 4);
      defaultDate.setUTCDate(1);
      defaultDate.setUTCHours(0, 0, 0, 0);
      return setFilter((date) => ({ ...date, since: defaultDate }));
    }
    const newDate = new Date(e.target.value);
    newDate.setUTCMonth(newDate.getMonth());
    newDate.setUTCDate(1);
    newDate.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, since: newDate }));
  };

  const handleChangeDateFormat = (checked) => {
    if (!checked) {
      const currentDate = new Date();
      currentDate.setUTCMonth(currentDate.getMonth() + 1);
      currentDate.setUTCDate(0);
      currentDate.setUTCHours(0, 0, 0, 0);

      const fromDate = new Date();
      fromDate.setUTCMonth(fromDate.getMonth() - 4);
      fromDate.setUTCDate(1);
      fromDate.setUTCHours(0, 0, 0, 0);

      return setFilter({ ...filter, by: "month", to: currentDate, since: fromDate });
    }
    const since = new Date();
    since.setFullYear(since.getFullYear() - 1);
    since.setUTCMonth(0);
    since.setUTCDate(1);
    since.setUTCHours(0, 0, 0, 0);

    const to = new Date();
    to.setUTCMonth(11);
    to.setUTCDate(31);
    to.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, by: "year", since: since, to: to }));
  };

  const formatDataForCSV = () => {
    const data = [];

    const formatPeriod = () => {
      if (filter?.by === "month") {
        return `${filter?.since.toLocaleString("fr-FR", { year: "numeric", month: "long" })} - ${filter?.to.toLocaleString("fr-FR", { year: "numeric", month: "long" })}`;
      }
      return `${filter?.since.getFullYear()} - ${filter?.to.getFullYear()}`;
    };

    // Add summary section
    data.push({
      Section: t("summary"),
      Category: t("total_revenue"),
      Amount: profitLoss.totalRevenue,
      Currency: filter?.currency,
      Period: formatPeriod(),
    });

    data.push({
      Section: t("summary"),
      Category: t("total_cost"),
      Amount: profitLoss.totalCost,
      Currency: filter?.currency,
      Period: formatPeriod(),
    });

    data.push({
      Section: t("summary"),
      Category: t("total_profit"),
      Amount: profitLoss.profit,
      Currency: filter?.currency,
      Period: formatPeriod(),
    });

    // Add revenue details
    profitLoss?.revenue?.forEach((item) => {
      data.push({
        Section: t("revenue_details"),
        Category: t(`transactions.${item.category}`),
        Amount: item.amount,
        Currency: filter?.currency,
        Percentage: ((item.amount / profitLoss.totalRevenue) * 100).toFixed(2) + "%",
        Period: formatPeriod(),
      });
    });

    // Add cost details
    profitLoss?.cost?.forEach((item) => {
      data.push({
        Section: t("cost_details"),
        Category: t(`transactions.${item.category}`),
        Amount: item.amount,
        Currency: filter?.currency,
        Percentage: ((item.amount / profitLoss.totalCost) * 100).toFixed(2) + "%",
        Period: formatPeriod(),
      });
    });

    return data;
  };
  return (
    <>
      <div className="relative flex flex-col gap-6 items-start text-left text-black-100 w-full">
        {!PRICING_FEATURES[organization?.plan]?.profitAndLossDashboard && (
          <div className="absolute inset-0 z-10 flex items-start justify-center bg-transparent backdrop-blur-sm -mx-[1%] -mt-[1%]">
            <div className="bg-white rounded-xl p-8 shadow-xl relative max-w-md w-full mt-32">
              <div className="absolute top-0 right-0 bg-yellow-400 text-sm px-2 py-1 rounded-tr-xl rounded-bl-xl font-medium shadow-sm">Premium</div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">{t("analytics.tab_profit_loss_premium_title")}</h1>
              <p className="text-gray-600 text-lg max-w-md mt-4">{t("analytics.tab_profit_loss_premium_description")}</p>
              <button
                onClick={() => {
                  Mixpanel.track(MIXPANEL_EVENTS.btn_upgrade_plan_profit_loss);
                  navigate("/settings/my-subscription");
                }}
                className="mt-6 w-full text-lg font-semibold bg-gradient-to-r from-blue-400 to-blue-500 text-white px-4 py-2 rounded-full shadow-lg hover:from-blue-500 hover:to-blue-600 transform hover:scale-105 transition-all duration-200">
                ✨ {t("upgrade_your_plan")}
              </button>
            </div>
          </div>
        )}

        <div className="w-full flex justify-between items-center">
          <div className="bg-white border border-gray-300 rounded-xl p-2 w-7/12">
            <div className="gap-8 flex items-center justify-between">
              <div className="flex items-center gap-2 bg-gray-100 p-1 rounded-lg">
                <button
                  onClick={() => handleChangeDateFormat(false)}
                  className={`px-3 py-1 rounded-md text-xs transition-colors ${
                    filter?.by === "month" ? "bg-white text-blue-300 font-bold shadow-sm" : "text-gray-600 hover:bg-gray-200"
                  }`}>
                  {t("month")}
                </button>
                <button
                  onClick={() => handleChangeDateFormat(true)}
                  className={`px-3 py-1 rounded-md text-xs transition-colors ${
                    filter?.by === "year" ? "bg-white text-blue-300 font-bold shadow-sm" : "text-gray-600 hover:bg-gray-200"
                  }`}>
                  {t("year")}
                </button>
              </div>

              {filter?.by === "month" ? (
                <div className="flex items-center justify-center gap-3">
                  <div className="flex gap-1">
                    <select
                      className="rounded-lg border border-gray-300 px-2 py-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-xs w-24"
                      value={filter?.since.getMonth()}
                      onChange={(e) => {
                        const newDate = new Date(filter.since);
                        newDate.setMonth(parseInt(e.target.value));
                        setFilter((prev) => ({ ...prev, since: newDate }));
                      }}>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString("fr-FR", { month: "long" })}
                        </option>
                      ))}
                    </select>
                    <select
                      className="rounded-lg border border-gray-300 px-2 py-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-xs w-20"
                      value={filter?.since.getFullYear()}
                      onChange={(e) => {
                        const newDate = new Date(filter.since);
                        newDate.setFullYear(parseInt(e.target.value));
                        setFilter((prev) => ({ ...prev, since: newDate }));
                      }}>
                      {Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => (
                        <option key={i} value={new Date().getFullYear() - i}>
                          {new Date().getFullYear() - i}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="text-gray-400">-</span>
                  <div className="flex gap-1">
                    <select
                      className="rounded-lg border border-gray-300 px-2 py-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-xs w-24"
                      value={filter?.to.getMonth()}
                      onChange={(e) => {
                        const newDate = new Date(filter.to);
                        newDate.setMonth(parseInt(e.target.value));
                        setFilter((prev) => ({ ...prev, to: newDate }));
                      }}>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString("fr-FR", { month: "long" })}
                        </option>
                      ))}
                    </select>
                    <select
                      className="rounded-lg border border-gray-300 px-2 py-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-xs w-20"
                      value={filter?.to.getFullYear()}
                      onChange={(e) => {
                        const newDate = new Date(filter.to);
                        newDate.setFullYear(parseInt(e.target.value));
                        setFilter((prev) => ({ ...prev, to: newDate }));
                      }}>
                      {Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => (
                        <option key={i} value={new Date().getFullYear() - i}>
                          {new Date().getFullYear() - i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-3">
                  <Listbox value={filter?.since.getFullYear()} onChange={(value) => handleSetSinceDate({ target: { value: `${value}-01` } })}>
                    <div className="relative">
                      <div className="w-32 flex items-center justify-between bg-white border border-gray-300 rounded-lg px-3 py-1 cursor-pointer hover:border-blue-500">
                        <Listbox.Button className="w-full text-left text-xs">{filter?.since.getFullYear()}</Listbox.Button>
                        <FaChevronDown className="text-gray-400" />
                      </div>
                      <Listbox.Options className="absolute z-20 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                        {years.map((year) => (
                          <Listbox.Option className="px-3 py-2 cursor-pointer hover:bg-gray-100 transition-colors text-xs" key={year} value={year}>
                            {year}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                  <span className="text-gray-400">-</span>
                  <Listbox value={filter?.to.getFullYear()} onChange={(value) => handleSetToDate({ target: { value: `${value}-12` } })}>
                    <div className="relative">
                      <div className="w-32 flex items-center justify-between bg-white border border-gray-300 rounded-lg px-3 py-1 cursor-pointer hover:border-blue-500">
                        <Listbox.Button className="w-full text-left text-xs">{filter?.to.getFullYear()}</Listbox.Button>
                        <FaChevronDown className="text-gray-400" />
                      </div>
                      <Listbox.Options className="absolute z-20 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                        {years.map((year) => (
                          <Listbox.Option className="px-3 py-2 cursor-pointer hover:bg-gray-100 transition-colors text-xs" key={year} value={year}>
                            {year}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
              )}

              <div className="w-50">
                <Combobox
                  id="currency"
                  options={["EUR", "USD", "GBP", "CHF", "CAD", "AUD", "YEN", "JPY", "NZD", "SEK", "CNH", "PLN", "AED"]}
                  value={filter?.currency}
                  onChange={(value) => setFilter({ ...filter, currency: value })}
                  nullable={false}
                  inputClassName="py-1 text-xs"
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="text-xs text-gray-500">
            {filter.since.toLocaleString("fr-FR", { year: "numeric", month: "long" })} - {filter.to.toLocaleString("fr-FR", { year: "numeric", month: "long" })}
          </div>

          {profitLoss && (
            <CsvDownloadButton
              data={formatDataForCSV()}
              filename={"profit_and_loss_report.csv"}
              header={[t("section"), t("category"), t("amount"), t("currency"), t("percentage"), t("period")]}
              className="btn-primary btn-small hover:bg-gray-700 focus:bg-blue flex items-center justify-center gap-2 transition-all duration-300 transform  hover:shadow-lg">
              <FaDownload />
              <span className="font-medium text-sm">{t("export_to_csv")}</span>
            </CsvDownloadButton>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
          <div className="p-5 bg-white border border-black-20 rounded-xl">
            <div className="text-sm mb-1">{t("revenue")}</div>
            <div className="font-semibold text-2xl mb-4">
              {Math.round(profitLoss?.totalRevenue * 100) / 100 || "0"} {currencyToStr(filter?.currency)}
            </div>
            <ul className="list-none">
              {profitLoss?.revenue?.map((category) => (
                <li key={category.category} className="grid grid-cols-2 gap-2 items-end my-1">
                  <div className="flex justify-between gap-1 items-end text-sm">
                    {t(`transactions.${category.category}`)} <span>:</span>
                  </div>
                  <div className="font-semibold">
                    {Math.round(category.amount * 100) / 100} {currencyToStr(filter?.currency)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="p-5 bg-white border border-black-20 rounded-xl">
            <div className="text-sm mb-1">{t("cost")}</div>
            <div className="font-semibold text-2xl mb-4">
              {Math.round(profitLoss?.totalCost * 100) / 100 || "0"} {currencyToStr(filter?.currency)}
            </div>
            <ul className="list-none">
              {profitLoss?.cost?.map((category) => (
                <li key={category.category} className="grid grid-cols-2 gap-2 items-end my-1">
                  <div className="flex justify-between gap-1 items-end text-sm">
                    {t(`transactions.${category.category}`)} <span>:</span>
                  </div>
                  <div className="font-semibold">
                    {Math.round(category.amount * 100) / 100} {currencyToStr(filter?.currency)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="p-5 bg-white border border-black-20 rounded-xl">
            <div className="text-sm mb-1">{t("profit")}</div>
            <div className="font-semibold text-2xl">
              {Math.round(profitLoss?.profit * 100) / 100 || "0"} {currencyToStr(filter?.currency)}
            </div>
          </div>
        </div>
        <Pies data={profitLoss}></Pies>
        <Details profitLossDetails={profitLossDetails} currency={currencyToStr(filter?.currency)} />
      </div>
    </>
  );
};

const Details = ({ profitLossDetails, currency }) => {
  const { t } = useTranslation();

  const [dataTable, setDataTable] = useState([]);
  const [dataToCompare, setDataToCompare] = useState([]);
  const [compareMode, setCompareMode] = useState(false);

  useEffect(() => {
    //Table construction for display
    const table = [];
    const dates = [""];
    const revenues = ["revenue"];
    const costs = ["cost"];
    const profit = ["profit"];

    //Fill table header
    profitLossDetails?.byDate?.toReversed().forEach((value) => dates.push(value.date));
    table.push(dates);

    //Revenue by category
    //For each category we look if there is a revenue for each month of the table
    profitLossDetails?.byCategory?.forEach(({ category, value }) => {
      const line = [];
      table[0].map((date, index) => {
        if (index === 0) return;
        let found = false;
        value.map(({ Date, Revenue }) => {
          if (Date === date) {
            line.push(Number(Revenue));
            found = true;
          }
        });
        if (!found) line.push(0);
      });
      if (line.some((value) => value !== 0)) table.push([category, ...line]);
    });
    //Total Revenue for each month (sum of all category's revenue)
    table[0].forEach((date, index) => {
      if (index === 0) return;
      let MonthRevenue = 0;
      for (let i = 1; i < table.length; i++) {
        MonthRevenue += table[i][index];
      }
      revenues.push(Math.round(MonthRevenue * 100) / 100);
    });
    table.push(revenues);
    const beginCost = table.length;

    //Cost by category
    //For each category we look if there is a cost for each month of the table
    profitLossDetails?.byCategory?.forEach(({ category, value }) => {
      const line = [];
      table[0].map((date, index) => {
        if (index === 0) return;
        let found = false;
        value.map(({ Date, Cost }) => {
          if (Date === date) {
            line.push(Number(Cost) * -1);
            found = true;
          }
        });
        if (!found) line.push(0);
      });
      if (line.some((value) => value !== 0)) table.push([category, ...line]);
    });
    //Total Cost for each month (sum of all category's cost)
    table[0].forEach((date, index) => {
      if (index === 0) return;
      let MonthCost = 0;
      for (let i = beginCost; i < table.length; i++) {
        MonthCost += table[i][index];
      }
      costs.push(Math.round(MonthCost * 100) / 100);
    });
    table.push(costs);

    //Total Profit for each month ( revenue + cost)
    table[0].forEach((date, index) => {
      if (index === 0) return;
      let MonthProfit = table[beginCost - 1][index] + table[table.length - 1][index];

      profit.push(Math.round(MonthProfit * 100) / 100);
    });
    table.push(profit);

    setDataTable(table);
  }, [profitLossDetails]);
  useEffect(() => {
    if (dataToCompare.length === 0) setCompareMode(false);
  }, [dataToCompare]);

  const isTotalRow = (row) => {
    return ["revenue", "cost", "profit"].includes(row[0]);
  };

  const formatDetailsForCSV = () => {
    if (!dataTable || dataTable.length === 0) return [];

    const csvData = dataTable.map((row, rowIndex) => {
      const rowData = {};

      dataTable[0].forEach((header, colIndex) => {
        const columnName = colIndex === 0 ? "Category" : header;
        const value = row[colIndex];

        if (colIndex !== 0) {
          rowData[columnName] = value ? `${value} ${currency}` : "-";
          return;
        }

        rowData[columnName] = isTotalRow(row) ? t(`${value}`) : t(`transactions.${value}`);
      });

      return rowData;
    });

    return csvData.slice(1);
  };

  return (
    <div className="bg-white w-full py-3 rounded-xl">
      <div className="flex justify-between items-center px-5 pb-3 border-b border-black-20">
        <div className="font-semibold">{t("details")}</div>
        <div className="flex gap-4 items-center">
          <div className="flex gap-2 items-center font-semibold">
            <Switch
              checked={compareMode}
              onChange={setCompareMode}
              className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition aria-checked:bg-blue-600">
              <span className="w-4 aspect-square translate-x-1 rounded-full bg-white transition group-aria-checked:translate-x-6" />
            </Switch>
            <span>Compare</span>
          </div>
          {dataTable.length > 0 && (
            <CsvDownloadButton
              data={formatDetailsForCSV()}
              filename={`profit_loss_details.csv`}
              className="btn-primary btn-small hover:bg-gray-700 focus:bg-blue flex items-center justify-center gap-2 transition-all duration-300 transform  hover:shadow-lg">
              <FaDownload />
              <span className="font-medium text-sm">{t("export_to_csv")}</span>
            </CsvDownloadButton>
          )}
        </div>
      </div>
      <div className="p-5 relative overflow-hidden bg-white">
        <div className="relative overflow-hidden overflow-x-auto bg-white">
          {profitLossDetails?.byDate?.length && profitLossDetails?.byCategory?.length ? (
            <table>
              <thead className="p-2">
                <tr>
                  {dataTable[0]?.map((colHeader, index) => {
                    if (index === 0 || !compareMode || (compareMode && dataToCompare.find((i) => i === index))) {
                      return (
                        <th key={colHeader} className={`${index === 0 ? "sticky left-0 z-10" : ""} p-4 bg-white`}>
                          <div className="flex gap-2 items-center">
                            <span>{colHeader}</span>
                            {index !== 0 && (
                              <input
                                type="checkbox"
                                value={dataToCompare.find((i) => i === index)}
                                onClick={() => {
                                  const i = dataToCompare.find((v) => v === index);
                                  const newToCompare = i ? [...dataToCompare.filter((d) => d !== i)] : [...dataToCompare, index];
                                  setDataToCompare(newToCompare);
                                }}
                              />
                            )}
                          </div>
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {dataTable?.map((row, index) => {
                  if (index === 0) return;
                  return (
                    <tr className={`${isTotalRow(row) ? "bg-black-20 font-semibold" : "bg-white text-black-90"} border-b border-black-20`} key={`row-${index}`}>
                      {row.map((value, indexCol) => {
                        if (indexCol === 0 || !compareMode || (compareMode && dataToCompare.find((i) => i === indexCol))) {
                          if (indexCol == 0)
                            return (
                              <td key={`${index}-${indexCol}`} className={`${isTotalRow(row) ? "bg-black-20" : "bg-white"} p-4 sticky left-0 z-10`}>
                                {isTotalRow(row) ? t(`${value}`) : t(`transactions.${value}`)}
                              </td>
                            );
                          return (
                            <td key={`${index}-${indexCol}`} className={`p-4 ${value < 0 ? "text-red-600" : ""}`}>
                              {value ? `${value} ${currency}` : "-"}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="text-center font-semibold text-xl">{t("no_data")}</div>
          )}
        </div>
      </div>
    </div>
  );
};
const palette = ["#132a39", "#274e63", "#277293", "#40a5c6", "#b5e0ec", "#c8a800", "#fad200", "#fbdb32", "#fce466"];
const Pies = ({ data }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [arrangedRevenue, setArrangedRevenue] = useState(null);
  const [arrangedCost, setArrangedCost] = useState(null);

  useEffect(() => {
    setArrangedRevenue(
      data?.revenue
        ?.map(({ category, amount }, index) => ({
          id: t(`transactions.${category}`),
          label: t(`transactions.${category}`),
          value: data?.totalRevenue ? amount / data?.totalRevenue : 0,
          color: palette[index % 10],
        }))
        .sort((a, b) => b.value - a.value),
    );
    setArrangedCost(
      data?.cost
        ?.map(({ category, amount }, index) => ({
          id: t(`transactions.${category}`),
          label: t(`transactions.${category}`),
          value: data?.totalCost ? amount / data?.totalCost : 0,
          color: palette[index % 10],
        }))
        .sort((a, b) => b.value - a.value),
    );
  }, [data]);

  return (
    <div className="grid grid-cols-1 h-fit md:grid-cols-2 w-full gap-5 relative">
      <div className="bg-white flex flex-col h-fit w-full rounded-lg relative">
        <div className="text-sm px-5 py-3 font-bold border-b border-black-20">{t("revenue")}</div>
        <div className="absolute top-[150px] left-0 right-0 grid text-center place-content-center font-bold">
          <div>{t("amount")}</div>
          <div>
            {Math.round(data.totalRevenue * 100) / 100} {currencyToStr(data?.currency ?? organization?.currency)}
          </div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="h-[250px]">
            <ResponsivePie
              data={arrangedRevenue ?? []}
              margin={{ top: 10, right: 50, bottom: 10, left: 50 }}
              valueFormat=" >-.2%"
              innerRadius={0.7}
              padAngle={0}
              cornerRadius={0}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              colors={arrangedRevenue?.map(({ color }) => color)}
            />
          </div>
          <div className=" text-[11px] relative left-0 right-0 bottom-1 p-3 flex flex-wrap justify-center gap-3">
            {arrangedRevenue?.map((legend) => (
              <div key={legend.label} className="flex gap-1 items-center">
                <div className="w-4 aspect-square rounded-sm" style={{ backgroundColor: legend.color }}></div>
                {legend.label} ({Math.round(legend.value * 10000) / 100}%)
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white flex flex-col w-full rounded-lg relative">
        <div className="text-sm px-5 py-3 font-bold border-b border-black-20">{t("cost")}</div>
        <div className="absolute top-[150px] left-0 right-0 grid text-center place-content-center font-bold">
          <div>{t("amount")}</div>
          <div>
            {Math.round(data.totalCost * 100) / 100} {currencyToStr(data?.currency ?? organization?.currency)}
          </div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="h-[250px]">
            <ResponsivePie
              data={arrangedCost ?? []}
              margin={{ top: 10, right: 50, bottom: 10, left: 50 }}
              valueFormat=" >-.2%"
              innerRadius={0.7}
              padAngle={0}
              cornerRadius={0}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              colors={arrangedCost?.map(({ color }) => color)}
            />
          </div>
          <div className="text-[11px] relative left-0 right-0 bottom-1 p-3 flex flex-wrap justify-center gap-3">
            {arrangedCost?.map((legend) => (
              <div key={legend.label} className="flex gap-1 items-center">
                <div className="w-4 aspect-square rounded-sm" style={{ backgroundColor: legend.color }}></div>
                {legend.label} ({Math.round(legend.value * 10000) / 100}%)
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfitLoss;
