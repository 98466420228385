import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaQuestionCircle, FaUserCircle } from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import { setUser } from "@redux/auth/actions";
import api from "@services/api";
import { AiOutlineMenu } from "react-icons/ai";
import { HiUserCircle, HiOutlineSparkles, HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import Organizations from "./Organizations";
import { truncate } from "@utils";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "@utils/mixpanelEvents";
import HelpCenter from "./helpCenter";
import { MdOutlineLogout, MdOutlineSettings } from "react-icons/md";

const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClick, true);
    return () => {
      document.removeEventListener("mousedown", handleClick, true);
    };
  }, [callback]);

  return ref;
};

const Header = ({ setSideBarOpen }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isHelpCenterOpen, setIsHelpCenterOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);

  const location = useLocation();
  const navigate = useNavigate();

  const userMenuRef = useOutsideClick(() => setOpen(false));

  if (location.pathname.indexOf("/auth") !== -1) return null;

  async function logout() {
    await api.post(`/user/logout`);
    dispatch(setUser(null));
  }

  return (
    <div className="p-2 bg-white border-b border-[#a0a6b124] flex justify-between items-center w-full right-4 z-50">
      <div className="flex gap-1 items-center">
        <div id="menu-icon" onClick={() => setSideBarOpen((state) => !state)} className="md:hidden cursor-pointer hover:bg-[#f5f5f5] rounded-full p-2">
          <AiOutlineMenu className="text-lg" />
        </div>
      </div>
      <Organizations />

      <div className="flex items-center gap-2">
        <button
          onClick={() => {
            Mixpanel.track(MIXPANEL_EVENTS.btn_goto_copilot_from_header);
            navigate("/copilot");
          }}
          className="flex items-center gap-1.5 px-3 py-1.5 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full transition-all duration-300 hover:scale-[102%]">
          <HiOutlineSparkles className="text-base" />
          <span className="font-medium text-sm">{t("drawer.copilot")}</span>
          <span className="text-xs bg-white/20 px-1.5 py-0.5 rounded-full">{t("copilot.banner.badge")}</span>
        </button>

        <button
          className="group flex items-center gap-2 px-3 py-3 rounded-2xl hover:bg-blue-50 text-gray-700 hover:text-blue-500 transition-all duration-200 whitespace-nowrap"
          onClick={() => navigate("/referral")}>
          <div className="flex items-center justify-center w-5 h-5 bg-gray-700 group-hover:bg-blue-500 rounded-full transition-all duration-200">
            <FaGift className={`w-[11px] h-[11px] text-lg text-white`} />
          </div>
          <span className="text-sm font-bold">{t("promo_code.hook")}</span>
        </button>

        <div>
          <button
            className={`help-center-toggle group flex items-center gap-1.5 px-3 py-3 rounded-2xl hover:bg-blue-50 transition-all duration-200 ${
              isHelpCenterOpen ? "bg-blue-50" : ""
            }`}
            onClick={() => setIsHelpCenterOpen(!isHelpCenterOpen)}>
            <FaQuestionCircle className={`w-5 h-5 text-lg transition-colors duration-200 ${isHelpCenterOpen ? "text-blue-500" : "text-gray-700 group-hover:text-blue-500"}`} />
          </button>
          <HelpCenter isOpen={isHelpCenterOpen} setIsOpen={setIsHelpCenterOpen} />
        </div>

        <div ref={userMenuRef} className="relative">
          <button
            onClick={() => setOpen(!open)}
            className={`group flex items-center gap-1.5 px-3 py-3 rounded-2xl hover:bg-blue-50 transition-all duration-200 ${open ? "bg-blue-50" : ""}`}>
            <FaUserCircle className={`w-5 h-5 text-lg transition-colors duration-200 ${open ? "text-blue-500" : "text-gray-700 group-hover:text-blue-500"}`} />
          </button>

          {open && (
            <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-2xl border border-gray-200 overflow-hidden z-20">
              {/* User Info */}
              <div className="p-4 border-b border-gray-200">
                <p className="text-sm font-medium text-gray-900">{truncate(`${user.firstname ?? ""} ${user.lastname ?? ""}`, 25)}</p>
                <p className="text-sm text-gray-500">{truncate(user.email, 30)}</p>
              </div>

              {/* Menu Items */}
              <div className="py-2">
                <Link
                  to="/settings/my-profile"
                  onClick={() => setOpen(false)}
                  className="flex items-center w-full px-4 py-2 font-medium text-sm text-gray-700 hover:bg-gray-100 transition-colors">
                  <MdOutlineSettings className="w-4 h-4 mr-2" />

                  {t("header.settings")}
                </Link>
                <button
                  onClick={() => {
                    logout();
                    setOpen(false);
                  }}
                  className="flex items-center w-full font-medium text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors">
                  <MdOutlineLogout className="w-4 h-4 mr-2" />
                  {t("logout")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
