import { readFileAsync } from "@utils/index";
import api from "@services/api";
import { useState } from "react";
import { IoIosAdd } from "react-icons/io";

const FileInputBtn = ({ onChange, name, folder }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex items-center">
      <label
        htmlFor={`openFile-${name}`}
        className="rounded-md border border-gray-300 p-1 flex items-center justify-center hover:bg-gray-50 transition-colors duration-200 cursor-pointer">
        <IoIosAdd className="w-3.5 h-3.5 flex-shrink-0" />
      </label>
      <input
        id={`openFile-${name}`}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const f = e.target.files[0];
          const rawBody = await readFileAsync(f);
          const { data } = await api.post(`/file`, {
            file: { rawBody, name: f.name },
            folder,
          });
          onChange({ target: { value: data, name } });
          setLoading(false);
        }}
      />
    </div>
  );
};

export default FileInputBtn;
