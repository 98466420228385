import { Fragment, useRef, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine, RiCheckLine } from "react-icons/ri";

export const Select = ({
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  nullable = false,
  placeholder = "Sélectionner une option",
  width = "w-full",
  buttonClassName = "",
  containerClassName = "",
  position = "left-0",
  listHeader,
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
}) => {
  const [dropUp, setDropUp] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handlePosition = () => {
      if (!selectRef.current) return;
      const rect = selectRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      setDropUp(spaceBelow < 260 && spaceAbove > spaceBelow);
    };

    handlePosition();
    window.addEventListener("scroll", handlePosition);
    window.addEventListener("resize", handlePosition);

    return () => {
      window.removeEventListener("scroll", handlePosition);
      window.removeEventListener("resize", handlePosition);
    };
  }, []);

  return (
    <Listbox value={value || ""} onChange={onChange} disabled={disabled} by={by} multiple={multiple}>
      {({ open }) => (
        <>
          <div className="relative w-full" ref={selectRef}>
            <div className={`w-full flex items-center justify-between bg-white border border-gray-300 rounded-md hover:border-sky-700 ${containerClassName}`}>
              <Listbox.Button
                className={`${buttonClassName} flex-1 text-left placeholder:text-gray-600 py-1 px-3 w-11/12`}
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                {multiple && value.length > 0 ? (
                  <p className="truncate text-left text-black">
                    {getLabel(value[0])} {value.length > 1 && `+${value.length - 1}`}
                  </p>
                ) : !multiple && value ? (
                  <p className="truncate text-left text-black">{getLabel(value)}</p>
                ) : (
                  <p className="truncate text-gray-700 opacity-60 text-left">{placeholder}</p>
                )}
                {((multiple && !value.length) || (!multiple && !value) || !nullable) && !disabled && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2">{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
                )}
              </Listbox.Button>
              {((multiple && value.length > 0) || (!multiple && value)) && !disabled && nullable && (
                <button
                  type="button"
                  className="flex items-center pr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(multiple ? [] : null);
                  }}>
                  <RiCloseLine />
                </button>
              )}
            </div>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options
                className={`absolute z-30 ${
                  dropUp ? "bottom-full mb-1" : "top-full mt-1"
                } max-h-64 ${width} ${position} overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
                <div className={dropUp ? "flex flex-col-reverse" : ""}>
                  {listHeader}
                  {options.map((option, i) => (
                    <Listbox.Option key={i} value={option} className="relative w-full cursor-pointer select-none list-none" onClick={(e) => e.stopPropagation()}>
                      {({ active, selected }) => (
                        <div className={`${active ? "bg-gray-100" : "bg-transparent"} w-full !m-0 px-4 py-2 flex items-center justify-between gap-2`}>
                          <p className={`w-full block truncate text-xs ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
                          {selected && <RiCheckLine />}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
