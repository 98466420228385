import { useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";

import { useTranslation } from "react-i18next";
import FileInput from "../../components/utils/FileInput";
import { Mixpanel } from "../../services/mixpanel";

import LoadingButton from "../../components/loadingButton";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegFile } from "react-icons/fa";
import { AiFillFileImage } from "react-icons/ai";

const CreatePurchaseInvoice = ({ onCreated }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        className="btn-primary focus:bg-blue float-right"
        onClick={() => {
          setOpen(true);
          Mixpanel.track(MIXPANEL_EVENTS.btn_open_purchase_invoice_create_modal);
        }}>
        {t("purchase_invoices.create")}
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} innerClassName="flex max-w-[40rem] w-[90vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-8 pt-8 pb-3 border-b flex justify-between">
            <h2 className="text-2xl font-semibold text-gray-800">{t("purchase_invoices.create")}</h2>
            <button type="submit" onClick={() => setOpen(false)}>
              <RxCross1 />
            </button>
          </div>
          <div className="p-6 overflow-auto">
            <div className="flex flex-col row-start-1">
              <Form setOpen={setOpen} onCreated={onCreated} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Form = ({ setOpen, onCreated }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [loadingMessage, setLoadingMessage] = useState();
  const [values, setValues] = useState({});
  const { organization } = useSelector((state) => state.Auth);

  const handleSubmit = async (event) => {
    setLoading(true);
    setLoadingMessage(t("purchase_invoices.loading_message_create"));
    try {
      event.preventDefault();
      const { data } = await api.post("/purchase-invoice", {
        OrganizationId: organization._id,
        reference: decodeURIComponent(values.file.split("/").pop().split(".").shift()),
        file: values?.file,
      });
      setLoadingMessage(t("purchase_invoices.loading_message_ocr"));
      const responseOcr = await api.post(`/purchase-invoice/${data._id}/ocr`, { OrganizationId: organization._id });

      await api.post(`/purchase-invoice/${data._id}/sync-transaction`, { OrganizationId: organization._id });

      toast.success(t("toast.sucess.created"));
      setLoadingMessage("");
      setLoading(false);
      setOpen(false);
      onCreated(responseOcr.data);
      setValues();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="flex flex-col row-start-1">
        <div className="text-base mb-1 font-semibold"> {t("purchase_invoices.import")} </div>
        {values?.file ? (
          <div className="border rounded-lg p-4 relative w-full">
            <div className="flex justify-between items-start mb-2">
              <a href={values.file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                {values.file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
              </a>
              <button type="button" onClick={() => setValues({ ...values, file: null })} className="text-gray-500 hover:text-red-500">
                <MdDeleteOutline size={20} />
              </button>
            </div>
            <a href={values.file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
              {decodeURIComponent(values.file.split("/").pop())}
            </a>
          </div>
        ) : (
          <div className="flex gap-3 w-full">
            <FileInput onChange={(e) => setValues({ ...values, file: e.target.value })} name="{transaction._id}" folder="purchaseInvoiceTransaction" width="w-full" />
          </div>
        )}
      </div>
      <div className="flex justify-end mt-4 gap-6">
        <LoadingButton
          disabled={!values?.file}
          type="submit"
          loading={loading}
          loadingMessage={loadingMessage}
          className="btn-primary"
          onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_purchase_invoice)}>
          {t("purchase_invoices.create")}
        </LoadingButton>
      </div>
    </form>
  );
};
export default CreatePurchaseInvoice;
