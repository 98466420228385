import Logo from "@assets/icon.png";
import LogoFull from "@assets/logo-full.svg";

import { Formik, Field } from "formik";
import validator from "validator";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import queryString from "query-string";
import { Mixpanel } from "../../services/mixpanel";
import { useEffect } from "react";

import { setUser } from "@redux/auth/actions";

import api from "@services/api";
import LoadingButton from "@components/utils/LoadingButton";

import { useTranslation } from "react-i18next";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
const SignIn = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const params = queryString.parse(location.search);
  const { redirect } = params;
  const { t, i18n } = useTranslation();

  const affiliateLink = i18n.language === "FR" ? "https://affiliate-program.finotor.com/?lang=fr" : "https://affiliate-program.finotor.com/?lang=en";

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_signin);
  }, []);

  if (user) return <Navigate to={redirect || "/"} />;

  return (
    <>
      <div className="hidden md:block bg-[#00A8C914] flex-1">
        <div className="flex h-full flex-col justify-around items-center max-w-lg w-full mx-auto p-10">
          <img src={LogoFull} alt="logo" className="h-32 object-contain" />
          <div className="flex flex-col items-center">
            <div className="font-bold text-4xl text-blue-600">{t("landing.happy_to_see_you")}</div>
            <div className="my-4">
              {t("landing.not_a_client")}&nbsp;
              <a href="/auth/signup" className="underline text-blue-700">
                {t("landing.signup")}
              </a>
            </div>
            <div>
              {t("landing.affiliate")}&nbsp;
              <a href={affiliateLink} target="_blank" className="underline text-blue-700">
                {t("landing.see_more")}
              </a>
            </div>
          </div>
          <div className="text-blue-600 text-center">
            <b className="text-lg">{t("landing.tagline1")}</b>
            <div>{t("landing.tagline2")}</div>
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex items-center justify-center p-6 text-center h-full">
          <div className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
            <div className="font-[Helvetica] text-center text-[32px] font-semibold	mb-[15px] flex flex-col justify-center items-center gap-2">
              <img src={Logo} alt="logo" className="w-[100px] h-[100px] " />
              <p> {t("signup_welcome_title")} </p>
            </div>
            <Formik
              enableReinitialize
              initialValues={{ email: "", password: "" }}
              onSubmit={async (values, actions) => {
                try {
                  const { user, token } = await api.post(`/user/signin`, values);
                  if (user) dispatch(setUser(user));
                  if (token) api.setToken(token);
                } catch (e) {
                  console.log("e", e);
                  toast.error(t(e.code));
                }
                actions.setSubmitting(false);
              }}>
              {({ values, errors, isSubmitting, handleChange, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-[25px]">
                      <div className="flex flex-col-reverse">
                        <Field
                          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                          validate={(v) => !validator.isEmail(v) && "Invalid email address"}
                          name="email"
                          type="email"
                          id="email"
                          placeholder="example@mail.com"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label className="peer-focus:text-[#116eee]" htmlFor="email">
                          {t("email")}
                        </label>
                      </div>
                      {/* Error */}
                      <p className="text-[12px] text-[#FD3131]"> {errors.email ? t("error_email_field") : null} </p>
                    </div>
                    <div className="mb-[25px]">
                      <div className="flex flex-col-reverse">
                        <Field
                          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                          validate={(v) => validator.isEmpty(v) && "This field is Required"}
                          name="password"
                          type="password"
                          id="password"
                          placeholder={t("enter_password")}
                          value={values.password}
                          onChange={handleChange}
                        />
                        <label className="peer-focus:text-[#116eee]" htmlFor="password">
                          {t("password")}
                        </label>
                      </div>
                      {/* Error */}
                      <p className="text-[12px] text-[#FD3131]">{errors.password ? t("error_required_field") : null}</p>
                    </div>
                    {/* Forget Password */}
                    <div className="text-right mb-[20px] mt-[-20px] text-[12px] hover:underline">
                      <Link to="/auth/forgot"> {t("forgot_password")} </Link>
                    </div>
                    {/* SignIn Button */}
                    <LoadingButton
                      className="font-[Helvetica] w-[220px] bg-blue-500 hover:bg-blue-400 text-white rounded-[30px] m-auto block text-[16px] p-[8px] min-h-[42px] "
                      loading={isSubmitting}
                      type="submit"
                      color="primary">
                      {t("signin")}
                    </LoadingButton>
                  </form>
                );
              }}
            </Formik>
            <div className="text-sm mt-4 text-center">
              <Link to="/auth/signup" className="font-medium hover:underline">
                {t("signup_title")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
