import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import api from "@services/api";
import categories from "@data/categories.js";
import { Mixpanel } from "@services/mixpanel";
import { classNames, getAccountName, currencyToStr } from "@utils";
import { Select } from "@components/utils/Select";
import { Combobox } from "@components/utils/Combobox";
import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import { FaRegMoneyBillAlt, FaCashRegister } from "react-icons/fa";
import { GoArrowSwitch } from "react-icons/go";

const MultipleActionBar = ({ supplierOptions, clientOptions, employeeOptions, accountOptions, selectedTransactions, setSelectedTransactions, setLoadingTransaction, onSave }) => {
  const { t } = useTranslation();

  const updateMultipleTransactions = async ({ params }) => {
    setLoadingTransaction(true);

    try {
      let updatedTransactions = selectedTransactions;

      for (const transaction of selectedTransactions) {
        const { ok, data } = await api.put(`/transaction/${transaction._id}`, {
          ...params,
        });
        if (!ok) throw new Error(`Error updating transaction ${transaction.name}`);

        //sync the transaction with the selected transactions
        if (updatedTransactions.some((t) => t._id === data._id)) {
          const updatedSelectedTransactions = updatedTransactions.map((transaction) => {
            if (transaction._id === data._id) return { ...transaction, ...params };
            return transaction;
          });
          updatedTransactions = updatedSelectedTransactions;
        }
      }

      toast.success(t("toast.sucess.updated_sucessfully"));
      setSelectedTransactions(updatedTransactions);
      onSave();
    } catch (error) {
      console.log("error multiple update", error);
      toast.error(error.code);
    } finally {
      setLoadingTransaction(false);
    }
  };

  const reconcileMultipleTransactions = async (e) => {
    try {
      e.preventDefault();
      if (!window.confirm("Are you sure you want to reconcile these transactions?")) return;

      for (const transaction of selectedTransactions) {
        // Create a copy of the transaction to apply AI suggestions if needed
        const transactionToReconcile = { ...transaction };

        // Apply AI type if not explicitly set but AI suggestion exists with good confidence
        if (!transactionToReconcile.type && transactionToReconcile.aiType && transactionToReconcile.aiTypeConfidence > 0.65) {
          transactionToReconcile.type = transactionToReconcile.aiType;
        }

        // Apply AI category if not explicitly set but AI suggestion exists with good confidence
        if (!transactionToReconcile.category && transactionToReconcile.aiCategory && transactionToReconcile.aiCategoryConfidence > 0.65) {
          transactionToReconcile.category = transactionToReconcile.aiCategory;
        }

        const { ok } = await api.put(`/transaction/${transaction._id}`, { ...transactionToReconcile, status: "RECONCILED" });
        if (!ok) throw new Error(`Error reconciling transaction ${transaction.name}`);
      }

      setSelectedTransactions([]);
      onSave();
      toast.success(t("toast.success.reconciled"));
    } catch (e) {
      console.log("error multiple reconcile", e);
      toast.error(e.code);
    }
  };

  const canReconcileMultiple = () => {
    return selectedTransactions.every((transaction) => {
      // Check if there's either a category or an AI category with good confidence
      const hasCategory = transaction.category || (transaction.aiCategory && transaction.aiCategoryConfidence > 0.65);
      // Check if there's either a type or an AI type with good confidence
      const hasType = transaction.type || (transaction.aiType && transaction.aiTypeConfidence > 0.65);

      if (!hasCategory || !hasType) return false;

      // Get effective type (user selected or AI suggested)
      const effectiveType = transaction.type || transaction.aiType;

      if (effectiveType === "CLIENT" && !transaction.ClientId && !transaction.ClientIdSuggestion && !transaction.AiClientId) return false;
      if (effectiveType === "SUPPLIER" && !transaction.SupplierId && !transaction.SupplierIdSuggestion && !transaction.AiSupplierId) return false;
      if (effectiveType === "EMPLOYEE" && !transaction.EmployeeId && !transaction.EmployeeIdSuggestion) return false;
      if (effectiveType === "TRANSFER" && !transaction.TransferAccountId) return false;

      return true;
    });
  };

  return (
    <div className="">
      <div className="flex justify-between items-center gap-4">
        <div className={classNames("p-1 text-center text-sm font-medium transition-all duration-200 bg-white rounded-xl border-2 border-white")}>
          <div className="flex items-center justify-center gap-2">
            <span className="text-xs">
              {t("transactions_selected")} ({selectedTransactions.length})
            </span>
          </div>
        </div>
        {!selectedTransactions.every((t) => t.category === "2100") && (
          <>
            <div className={`text-xs w-28`}>
              <Select
                options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
                value={selectedTransactions.every((transaction) => transaction.type === selectedTransactions[0].type) ? selectedTransactions[0].type : null}
                onChange={async (type) => {
                  updateMultipleTransactions({ params: { type: type } });
                }}
                containerClassName="h-8 rounded-md border-gray-300"
                width="w-32"
                getLabel={(e) => t(e.toLowerCase())}
                placeholder={t("type")}
                nullable={false}
              />
            </div>
            <div className={`text-xs w-52`}>
              {selectedTransactions.every((t) => t.type === selectedTransactions[0].type) && (
                <MultipleTypeSelector
                  type={selectedTransactions[0].type}
                  onUpdateTransaction={updateMultipleTransactions}
                  supplierOptions={supplierOptions}
                  clientOptions={clientOptions}
                  employeeOptions={employeeOptions}
                  accountOptions={accountOptions}
                  selectedTransactions={selectedTransactions}
                />
              )}
            </div>
          </>
        )}
        <div className={`text-xs`}>
          <MultipleCategoryComboBox selectedTransactions={selectedTransactions} updateMultipleTransactions={updateMultipleTransactions} />
        </div>
        <div className={`text-xs`}>
          <button
            className="px-3 py-1.5 w-full font-medium text-xs bg-blue-500 hover:bg-blue-600 text-white rounded-md flex items-center justify-center whitespace-nowrap gap-1.5 transition-colors duration-200 disabled:opacity-50 disabled:bg-gray-400"
            onClick={(e) => {
              reconcileMultipleTransactions(e);
              Mixpanel.track(MIXPANEL_EVENTS.btn_reconcile_transactions);
            }}
            disabled={!canReconcileMultiple()}>
            <span>{t("reconcile_all")}</span>
            <GoArrowSwitch className="text-sm w-3.5 h-3.5 my-[3px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

const MultipleClientComboBox = ({ onUpdateTransaction, clientOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState();
  const [showCreateClient, setShowCreateClient] = useState(false);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.ClientId === selectedTransactions[0].ClientId)) return setSelectedClientId(null);
    const clientId = clientOptions.find((client) => client._id === selectedTransactions[0].ClientId)?._id;
    setSelectedClientId(clientId);
  }, [selectedTransactions, clientOptions]);

  const handleChange = async (e) => {
    await onUpdateTransaction({ params: { ClientId: e?._id || null } });
    setSelectedClientId(e?._id || null);
  };

  const clientOption = (item) => {
    if (!item) return null;
    const clientLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    return clientLabel;
  };

  return (
    <>
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <div className="relative">
        <Combobox
          options={clientOptions}
          value={clientOptions.find((client) => client._id === selectedClientId)}
          onChange={async (e) => handleChange(e)}
          placeholder={t("client")}
          getLabel={(client) => clientOption(client)}
          nullable={true}
          containerClassName="h-8 rounded-md border-gray-300"
          listHeader={
            <button
              className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
              onClick={() => {
                setShowCreateClient(true);
              }}>
              {t("clients.create_a_client")} <span>+</span>
            </button>
          }
          displayValue={(client) => {
            return clientOption(client);
          }}
        />
      </div>
    </>
  );
};

const MultipleSupplierComboBox = ({ onUpdateTransaction, supplierOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedSupplierId, setSelectedSupplierId] = useState();
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.SupplierId === selectedTransactions[0].SupplierId)) return setSelectedSupplierId(null);
    const supplierId = supplierOptions.find((supplier) => supplier._id === selectedTransactions[0].SupplierId)?._id;
    setSelectedSupplierId(supplierId);
  }, [selectedTransactions, supplierOptions]);

  const handleChange = async (e) => {
    await onUpdateTransaction({ params: { SupplierId: e?._id || null } });
    setSelectedSupplierId(e?._id || null);
  };

  const supplierOption = (item) => {
    if (!item) return null;
    const supplierLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    return supplierLabel;
  };

  return (
    <>
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <div className="relative">
        <Combobox
          options={supplierOptions}
          value={supplierOptions.find((supplier) => supplier._id === selectedSupplierId)}
          onChange={async (e) => handleChange(e)}
          placeholder={t("supplier")}
          getLabel={(supplier) => supplierOption(supplier)}
          nullable={true}
          containerClassName="h-8 rounded-md border-gray-300"
          listHeader={
            <button
              className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
              onClick={() => {
                setShowCreateSupplier(true);
              }}>
              {t("supplier.create_supplier")} <span>+</span>
            </button>
          }
          displayValue={(supplier) => supplierOption(supplier)}
        />
      </div>
    </>
  );
};

const MultipleEmployeeComboBox = ({ onUpdateTransaction, employeeOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.EmployeeId === selectedTransactions[0].EmployeeId)) return setSelectedEmployeeId(null);
    const employeeId = employeeOptions.find((employee) => employee._id === selectedTransactions[0].EmployeeId)?._id;
    setSelectedEmployeeId(employeeId);
  }, [selectedTransactions, employeeOptions]);

  const handleChange = async (e) => {
    await onUpdateTransaction({ params: { EmployeeId: e?._id || null } });
    setSelectedEmployeeId(e?._id || null);
  };

  const employeeOption = (item) => {
    if (!item) return null;
    const employeeLabel = item.firstname + " " + item.lastname;
    return employeeLabel;
  };

  return (
    <>
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <div className="relative">
        <Combobox
          options={employeeOptions}
          value={employeeOptions.find((employee) => employee._id === selectedEmployeeId)}
          onChange={async (e) => handleChange(e)}
          placeholder={t("employee")}
          getLabel={(employee) => employeeOption(employee)}
          containerClassName="h-8 rounded-md border-gray-300"
          listHeader={
            <button
              className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
              onClick={() => {
                setShowCreateEmployee(true);
              }}>
              {t("employees.create")} <span>+</span>
            </button>
          }
          displayValue={(employee) => employeeOption(employee)}
        />
      </div>
    </>
  );
};

const MultipleTransferSelect = ({ onUpdateTransaction, accountOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.TransferAccountId === selectedTransactions[0].TransferAccountId)) return setSelectedAccountId(null);
    const accountId = accountOptions.find((account) => account._id === selectedTransactions[0].TransferAccountId)?._id;
    setSelectedAccountId(accountId);
  }, [selectedTransactions, accountOptions]);

  return (
    <Select
      options={accountOptions}
      value={accountOptions.find((account) => account._id == selectedAccountId) || null}
      onChange={(e) => {
        onUpdateTransaction({ params: { TransferAccountId: e?._id || null } });
      }}
      placeholder={t("account")}
      getLabel={(account) => <AccountOption account={account} />}
      width="w-72"
      containerClassName="h-8 rounded-md border-gray-300"
      position="left-1/2 -translate-x-1/2 top-full"
      nullable={true}
    />
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash")
    return (
      <div className="border border-blue-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaCashRegister size={12} className="text-blue-600" />
      </div>
    );
  if (account.details === "savings")
    return (
      <div className="border border-green-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaRegMoneyBillAlt size={12} className="text-green-600" />
      </div>
    );
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

const MultipleCategoryComboBox = ({ selectedTransactions, updateMultipleTransactions }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState(categories);

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.category === selectedTransactions[0].category)) return setSelectedCategory(null);
    setSelectedCategory(selectedTransactions[0].category);
  }, [selectedTransactions]);

  return (
    <Combobox
      value={selectedCategory}
      options={filteredCategories}
      onChange={async (category) => {
        updateMultipleTransactions({ params: { category: category?.id || null }, needTransactionsUpdate: true });
      }}
      getLabel={(e) => {
        return e ? e.id + " - " + t(`transactions.${e.id}`) : null;
      }}
      containerClassName="h-8 rounded-md border-gray-300"
      displayValue={(e) => {
        if (!e) return null;
        return t(`transactions.${e}`);
      }}
      placeholder={t("category")}
    />
  );
};

const MultipleTypeSelector = ({ type, onUpdateTransaction, supplierOptions, clientOptions, employeeOptions, accountOptions, selectedTransactions }) => {
  const { t } = useTranslation();

  if (type === "SUPPLIER") {
    return <MultipleSupplierComboBox onUpdateTransaction={onUpdateTransaction} supplierOptions={supplierOptions} selectedTransactions={selectedTransactions} />;
  }

  if (type === "CLIENT") {
    return <MultipleClientComboBox onUpdateTransaction={onUpdateTransaction} clientOptions={clientOptions} selectedTransactions={selectedTransactions} />;
  }

  if (type === "EMPLOYEE") {
    return <MultipleEmployeeComboBox onUpdateTransaction={onUpdateTransaction} employeeOptions={employeeOptions} selectedTransactions={selectedTransactions} />;
  }

  if (type === "TRANSFER") {
    return <MultipleTransferSelect onUpdateTransaction={onUpdateTransaction} accountOptions={accountOptions} selectedTransactions={selectedTransactions} />;
  }

  return <div className="w-full h-8 flex items-center justify-center text-xs text-gray-400 bg-gray-50 rounded-md border border-gray-200 backdrop-blur-sm opacity-80"></div>;
};

export default MultipleActionBar;
