import { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import api from "@services/api";
import Loader from "@components/utils/Loader";
import { currencyToStr, getAccountName } from "@utils";
import { useTranslation } from "react-i18next";
import { Select } from "../../components/utils/Select";
import { Mixpanel } from "../../services/mixpanel";
import StripeLogo from "../../assets/stripe-logo.png";
import { FaDownload } from "react-icons/fa6";
import { FaCashRegister, FaRegMoneyBillAlt } from "react-icons/fa";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";
import CsvDownloadButton from "react-json-to-csv";
import toast from "react-hot-toast";
import { PRICING_FEATURES } from "../../constants";
import { useNavigate } from "react-router-dom";

const List = () => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [accounts, setAccounts] = useState([]);
  const [stripeAccounts, setStripeAccounts] = useState([]);
  const [totalPerCurrency, setTotalPerCurrency] = useState([]);
  const [pettyCash, setPettyCash] = useState(null);
  const [savings, setSavings] = useState(null);
  const [cashFlowData, setCashFlowData] = useState(null);

  const fetchBankAccounts = async () => {
    try {
      const { data, ok } = await api.post("/account/search", { OrganizationId: organization._id });
      if (!ok) throw new Error("Failed to fetch bank accounts");
      setAccounts(data);
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.fetching_bank_accounts"));
    }
  };

  const fetchStripeAccounts = async () => {
    try {
      const { data, ok } = await api.post("/stripe/requisitions/search", { OrganizationId: organization._id });
      if (!ok) throw new Error("Failed to fetch stripe accounts");
      setStripeAccounts(data);
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.fetching_stripe_accounts"));
    }
  };

  const fetchTotalPerCurrency = async () => {
    try {
      const { data, ok } = await api.post("/analytics/balances", { OrganizationId: organization._id });
      if (!ok) throw new Error("Failed to fetch balances");
      setTotalPerCurrency(data);
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.fetching_balances"));
    }
  };

  const fetchPettyCash = async () => {
    try {
      const { data, ok } = await api.post("/analytics/pettycash", { OrganizationId: organization._id });
      if (!ok) return;
      setPettyCash(data);
    } catch (e) {
      if (e.code === "NOT_FOUND") return;
      console.log(e);
      toast.error(t("toast.error.fetching_petty_cash"));
    }
  };

  const fetchSavings = async () => {
    try {
      const { data, ok } = await api.post("/analytics/savings", { OrganizationId: organization._id });
      if (!ok) return;
      setSavings(data);
    } catch (e) {
      if (e.code === "NOT_FOUND") return;
      console.log(e);
      toast.error(t("toast.error.fetching_savings"));
    }
  };

  const fetchCashFlow = async () => {
    try {
      const { data, ok } = await api.post("/analytics/cashflow", { OrganizationId: organization._id });
      if (!ok) return;
      const formattedCashFlow = data.map((e) => {
        const percent = ((parseFloat(e.Benefit) / parseFloat(e.Revenue)) * 100).toFixed(2);
        return { ...e, Month: `${e.Month} (${percent}%)` };
      });
      setCashFlowData(formattedCashFlow);
    } catch (e) {
      console.log(e);
      toast.error(t("toast.error.fetching_cashflow"));
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_accounting);
  }, []);

  useEffect(() => {
    if (!organization) return;
    fetchBankAccounts();
    fetchStripeAccounts();
    fetchTotalPerCurrency();
    fetchPettyCash();
    fetchSavings();
    fetchCashFlow();
  }, [organization]);

  const formatDataForCSV = () => {
    const data = [];

    totalPerCurrency?.forEach((item) => {
      data.push({
        Section: t("analytics.total_cash_bank"),
        Institution: "",
        Name: "Total",
        Amount: item.amount,
        Currency: item.currency,
      });
    });

    accounts?.forEach((account) => {
      data.push({
        Section: t("bank_accounts"),
        Institution: account.Requisition.NordigenInstitution.name,
        Name: getAccountName(account),
        Amount: account.amount,
        Currency: account.currency,
      });
    });

    stripeAccounts?.forEach((account) => {
      data.push({
        Section: t("stripe_accounts"),
        Institution: "Stripe",
        Name: account.name,
        Amount: account.amount,
        Currency: account.currency,
      });
    });

    if (pettyCash) {
      data.push({
        Section: t("petty_cash"),
        Institution: "",
        Name: t("petty_cash"),
        Amount: pettyCash.amount,
        Currency: pettyCash.currency,
      });
    }

    if (savings) {
      data.push({
        Section: t("savings"),
        Institution: "",
        Name: t("savings"),
        Amount: savings.amount,
        Currency: savings.currency,
      });
    }

    return data;
  };

  return (
    <div>
      <div className="flex justify-end mb-4">
        <CsvDownloadButton
          data={formatDataForCSV()}
          filename="accounts_summary.csv"
          headers={["Section", "Institution", "Name", "Amount", "Currency"]}
          className="btn-primary btn-small hover:bg-gray-700 focus:bg-blue flex items-center justify-center gap-2 transition-all duration-300 transform  hover:shadow-lg">
          <FaDownload />
          <span className="font-medium text-sm">{t("export_to_csv")}</span>
        </CsvDownloadButton>
      </div>
      <div className="grid grid-cols-1">
        <div className="flex gap-10 overflow-hidden">
          <AccountsTotalPerCurrency totalPerCurrency={totalPerCurrency} />
          <StripeBalances stripeAccounts={stripeAccounts} />
          <AccountsBalance accounts={accounts} />
          <PettyCash pettyCash={pettyCash} />
          <Savings savings={savings} />
        </div>
      </div>
      <CashFlow organization={organization} accounts={accounts} cashFlowData={cashFlowData} setCashFlowData={setCashFlowData} />
    </div>
  );
};

const AccountsBalance = ({ accounts }) => {
  if (!accounts) return <Loader />;

  return (
    <div className="flex gap-4 pb-3 overflow-x-auto">
      {accounts.map((account) => {
        return (
          <div key={account._id} className="border border-black-20 bg-white rounded-lg p-5 h-36">
            <div className="flex justify-between items-center flex-col h-full">
              <div className="flex justify-center items-center gap-2">
                <img src={account?.Requisition?.NordigenInstitution?.logo} width={20} height={20} />
                <div>{account.Requisition.NordigenInstitution.name}</div>
              </div>
              <div className="text-xl font-bold">{account.amount + currencyToStr(account.currency)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const StripeBalances = ({ stripeAccounts }) => {
  if (!stripeAccounts) return <Loader />;

  return (
    <div className="flex gap-4 pb-3">
      {stripeAccounts.map((account) => (
        <div key={account._id} className="border border-black-20 bg-white rounded-lg w-max p-5 h-36">
          <div className="flex justify-between items-center flex-col h-full">
            <div className="flex justify-center items-center gap-2">
              <img src={StripeLogo} width={20} height={20} />
              <div>{account.name}</div>
            </div>
            <div className="text-xl font-bold">{account.amount + currencyToStr(account.currency)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const AccountsTotalPerCurrency = ({ totalPerCurrency }) => {
  const { t } = useTranslation();
  if (!totalPerCurrency) return <Loader />;

  return (
    <div className="border border-black-20 bg-white rounded-lg flex flex-col justify-between p-4 w-max h-36">
      <div className="text-sm"> {t("analytics.total_cash_bank")}</div>

      <div className="flex gap-7">
        {totalPerCurrency.map((item) => (
          <div key={item.currency} className="text-2xl font-semibold">
            {item.amount + currencyToStr(item.currency)}
          </div>
        ))}
      </div>
    </div>
  );
};

const PettyCash = ({ pettyCash }) => {
  const { t } = useTranslation();
  if (!pettyCash) return null;

  return (
    <div className="flex gap-4 pb-3">
      <div className="border border-black-20 bg-white rounded-lg w-max p-5 h-36">
        <div className="flex justify-between items-center flex-col h-full">
          <div className="flex justify-center items-center gap-2">
            <FaCashRegister />
            <div>{t("petty_cash")}</div>
          </div>
          <div className="text-xl font-bold">
            <div key={pettyCash.amount}>
              {pettyCash.amount}
              {currencyToStr(pettyCash.currency)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Savings = ({ savings }) => {
  const { t } = useTranslation();
  if (!savings) return null;

  return (
    <div className="flex gap-4 pb-3">
      <div className="border border-black-20 bg-white rounded-lg w-max p-5 h-36">
        <div className="flex justify-between items-center flex-col h-full">
          <div className="flex justify-center items-center gap-2">
            <FaCashRegister />
            <div>{t("savings")}</div>
          </div>
          <div className="text-xl font-bold">
            <div key={savings.amount}>
              {savings.amount}
              {currencyToStr(savings.currency)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CashFlow = ({ organization, accounts, cashFlowData, setCashFlowData }) => {
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    accountsId: [],
    months: 6,
  });

  // Generate sample data for preview
  const sampleData = [
    { Month: "Jan (15%)", Revenue: 50000, Cost: 35000, Benefit: 15000 },
    { Month: "Feb (18%)", Revenue: 55000, Cost: 37000, Benefit: 18000 },
    { Month: "Mar (20%)", Revenue: 60000, Cost: 40000, Benefit: 20000 },
    { Month: "Apr (22%)", Revenue: 65000, Cost: 42000, Benefit: 23000 },
    { Month: "May (25%)", Revenue: 70000, Cost: 45000, Benefit: 25000 },
    { Month: "Jun (28%)", Revenue: 75000, Cost: 47000, Benefit: 28000 },
  ];

  async function get() {
    const date = new Date();
    date.setUTCMonth(date.getMonth() - filter.months);
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    const res = await api.post("/analytics/cashflow", {
      accountsId: filter.accountsId,
      OrganizationId: organization._id,
      since: date,
    });
    const result = res.data.map((e) => {
      const percent = ((parseFloat(e.Benefit) / parseFloat(e.Revenue)) * 100).toFixed(2);
      return { ...e, Month: `${e.Month} (${percent}%)` };
    });

    setCashFlowData(result);
  }

  const formatCashFlowForCSV = () => {
    return (
      cashFlowData?.map((item) => ({
        Month: item.Month.split(" (")[0],
        Revenue: item.Revenue,
        Cost: item.Cost,
        Benefit: item.Benefit,
      })) || []
    );
  };

  useEffect(() => {
    if (!organization) return;
    get();
  }, [filter, organization]);

  if (!cashFlowData) return <Loader />;

  if (!PRICING_FEATURES[organization?.plan]?.cashFlowDashboard) {
    return (
      <div className="mt-6">
        <div className="min-h-[408px] h-[calc(100vh-500px)] bg-white mt-4 p-2 rounded-xl py-6 border border-black-20 relative">
          <div className="absolute inset-0 bg-white/80 z-10 backdrop-blur-[2px]" />
          <div className="flex flex-col items-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-center w-full max-w-lg px-8">
            <div className="bg-white/90 p-8 rounded-xl shadow-lg border border-black-10 flex items-center justify-center flex-col relative">
              <div className="absolute top-0 right-0 bg-yellow-400 text-sm px-2 py-1 rounded-tr-xl rounded-bl-xl font-medium shadow-sm">Premium</div>
              <h3 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent mb-4">{t("analytics.tab_cash_flow_premium_title")}</h3>
              <p className="text-gray-600 mb-4 leading-relaxed">{t("analytics.tab_cash_flow_premium_description")}</p>
              <p className="text-gray-600 mb-8 leading-relaxed">{t("analytics.tab_cash_flow_premium_description_2")}</p>
              <button
                className="flex items-center justify-center px-5 py-2.5 text-sm font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition-all duration-200 hover:scale-105"
                onClick={() => {
                  Mixpanel.track(MIXPANEL_EVENTS.btn_upgrade_plan_cashflow);
                  navigate("/settings/my-subscription");
                }}>
                <span className="mr-2">✨</span>
                {t("upgrade_your_plan")}
              </button>
            </div>
          </div>

          <div className="flex gap-8 items-center justify-between opacity-50">
            <div className="flex flex-col gap-4">
              <div className="text-xl font-bold">6 Months Cash Flow Analysis</div>
              <div className="flex items-center gap-4">
                <div className="flex rounded-md shadow-sm" role="group">
                  <button className="px-4 py-2 text-sm font-medium w-fit border bg-gray-700 text-white border-gray-700 rounded-l-lg">6 months</button>
                  <button className="px-4 py-2 text-sm font-medium border bg-white text-gray-700 border-gray-300 rounded-r-lg">12 months</button>
                </div>
                <div className="w-full md:w-60">
                  <Select options={[]} placeholder="Choose bank account" disabled={true} />
                </div>
              </div>
            </div>
            <button disabled className="btn-primary btn-small flex items-center justify-center gap-2">
              <FaDownload />
              <span className="font-medium text-sm">Export to CSV</span>
            </button>
          </div>
          <div className="h-[calc(100%-100px)] mt-8">
            <Graph data={sampleData} keys={["Revenue", "Cost", "Benefit"]} legendY="Cash Flow" months={6} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <div className="min-h-[408px] h-[calc(100vh-500px)] bg-white mt-4 p-2 rounded-xl py-6 border border-black-20">
        <div className="flex gap-8 items-center justify-between">
          <div className="flex flex-col gap-4">
            <div className="text-xl font-bold">{filter.months === 6 ? t("analytics.6_months_cashflow") : t("analytics.12_months_cashflow")}</div>
            <div className="flex items-center gap-4">
              <div className="flex rounded-md shadow-sm" role="group">
                <button
                  type="button"
                  onClick={() => setFilter((prev) => ({ ...prev, months: 6 }))}
                  className={`px-4 py-2 text-sm font-medium w-fit border ${
                    filter.months === 6 ? "bg-gray-700 text-white border-gray-700" : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                  } rounded-l-lg focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-700 focus:text-white`}>
                  6 {t("months")}
                </button>
                <button
                  type="button"
                  onClick={() => setFilter((prev) => ({ ...prev, months: 12 }))}
                  className={`px-4 py-2 text-sm font-medium border ${
                    filter.months === 12 ? "bg-gray-700 text-white border-gray-700" : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                  } rounded-r-lg focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-700 focus:text-white`}>
                  12 {t("months")}
                </button>
              </div>
              <div className="w-full md:w-60">
                <Select
                  options={accounts}
                  onChange={(selectedOptions) => {
                    setFilter((prev) => ({ ...prev, accountsId: selectedOptions.map((option) => option._id) }));
                  }}
                  placeholder={t("analytics.choose_bank")}
                  value={accounts.filter((option) => filter.accountsId.includes(option._id))}
                  multiple
                  getLabel={(option) => <AccountOption account={option} />}
                />
              </div>
            </div>
          </div>
          <CsvDownloadButton
            data={formatCashFlowForCSV()}
            filename={`${filter.months}months_cashflow.csv`}
            className="btn-primary btn-small hover:bg-gray-700 focus:bg-blue flex items-center justify-center gap-2 transition-all duration-300 transform hover:shadow-lg">
            <FaDownload />
            <span className="font-medium text-sm">{t("export_to_csv")}</span>
          </CsvDownloadButton>
        </div>
        <div className="h-[calc(100%-100px)] mt-8">
          <Graph data={cashFlowData} keys={["Revenue", "Cost", "Benefit"]} legendY={t("cashflow")} months={filter.months} />
        </div>
      </div>
    </div>
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash")
    return (
      <div className="border border-blue-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaCashRegister size={12} className="text-blue-600" />
      </div>
    );
  if (account.details === "savings")
    return (
      <div className="border border-green-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaRegMoneyBillAlt size={12} className="text-green-600" />
      </div>
    );
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

const Graph = ({ data, keys, indexBy = "Month", groupMode = "grouped", legendX, legendY, months }) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 40, right: 30, bottom: 100, left: 80 }}
    padding={0.2}
    groupMode={groupMode}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: months === 6 ? 0 : -20, legend: legendX, legendPosition: "middle", legendOffset: 32 }}
    axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: legendY, legendPosition: "middle", legendOffset: -60 }}
    enableLabel={months === 6}
    labelSkipWidth={12}
    labelSkipHeight={12}
    animate={true}
    motionStiffness={10}
    motionDamping={10}
    role="application"
    ariaLabel={legendY}
    legends={[
      {
        anchor: "top",
        direction: "row",
        translateY: -35,
        itemWidth: 150,
        itemHeight: 20,
        symbolSize: 20,
        symbolShape: "circle",
        textColor: "#999",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000",
            },
          },
        ],
      },
    ]}
  />
);

export default List;
