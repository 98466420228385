import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import LogoAffiliate from "@assets/full-logo-affiliate.webp";
import { Mixpanel } from "../../services/mixpanel";
import { useEffect } from "react";

import LoadingButton from "@components/utils/LoadingButton";
import api from "@services/api";
import { setUser } from "@redux/auth/actions";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const SignupAffiliation = () => {
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState({ email: "", firstname: "", lastname:  "" });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      values.language = i18n.language;
      values.organization_name = values.firstname + "-affiliation";
      values.createdFrom = "SIGNUP_AFFILIATION";
      
      const { user, token } = await api.post(`/user/signup`, values);
      setLoading(false);
      if (user) dispatch(setUser(user));
      if (token) api.setToken(token);
    } catch (e) {
      setLoading(false);
      console.log("e", e);
      toast.error(t(e.code));
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_signup_affiliation);
  }, []);


  return (
    <>
      <div className="hidden md:block bg-[#00A8C914] flex-1">
        <div className="flex h-full flex-col justify-around items-center max-w-lg w-full mx-auto my-auto p-10">
          <div className="flex flex-col items-center gap-10">
            <img src={LogoAffiliate} alt="logo_affiliate" className="h-[170px] object-contain" />
            <div className="flex flex-col items-center gap-8">
              <div className="font-bold text-4xl text-blue-600 text-center">{t("landing.welcome_affiliate")}</div>
              <div className="my-4 text-center text-blue-600 font-semibold">
                {t("landing.affiliate_tagline1")}&nbsp;
              </div>
              </div>
            </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex items-center justify-center flex-col h-full">
          <div className="max-w-lg mx-auto w-full p-4">
            <div className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
              <div className="text-3xl font-bold mb-8">{t("signup_title")}</div>
              <form onSubmit={handleSubmit}>
                <div className="flex items-center space-x-4 mb-6">
                  <div>
                    <label className="block text-sm mb-1" htmlFor="firstname">
                      {t("firstname")}
                      <span className="text-blue-500">*</span>
                    </label>
                    <input
                      required
                      className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                      placeholder="Jean"
                      value={values.firstname}
                      onChange={(e) => setValues({ ...values, firstname: e.target.value})}
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1" htmlFor="lastname">
                      {t("lastname")}
                      <span className="text-blue-500">*</span>
                    </label>
                    <input
                      className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                      required
                      placeholder="Michel"
                      value={values.lastname}
                      onChange={(e) => setValues({ ...values, lastname: e.target.value })}
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <label className="block text-sm mb-1" htmlFor="email">
                    {t("email")}
                    <span className="text-blue-500">*</span>
                  </label>
                  <input
                    className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    required
                    type="email"
                    placeholder="example@mail.com"
                    value={values.email}
                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-sm mb-1" htmlFor="password">
                    {t("signup_password")}
                    <span className="text-blue-500">*</span>
                  </label>
                  <input
                    className="border-[1px] w-full border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    required
                    type="password"
                    placeholder={t("enter_password")}
                    value={values.password}
                    onChange={(e) => setValues({ ...values, password: e.target.value })}
                  />
                </div>
                {/* SignIn Button */}
                <LoadingButton className="w-full bg-blue-500 hover:bg-blue-400 text-white p-3 rounded-lg text-sm mt-10 block" loading={loading} type="submit" color="primary">
                  {t("signup_affiliate")}
                </LoadingButton>
              </form>
              <div className="text-sm mt-4 text-center">
                {t("have_account")}{" "}
                <Link to="/auth" className="font-medium hover:underline">
                  {t("signin")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupAffiliation;
