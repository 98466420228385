import { useState, useEffect, useRef } from "react";
import { currencyToStr, getTransactionAccountName } from "@utils";
import { useTranslation } from "react-i18next";
import { FaFileInvoice, FaRegMoneyBillAlt, FaCashRegister } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AllTransactions = ({ transactions }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLast = (index) => index === transactions.length - 1;

  return (
    <div className={`flow-root min-w-full align-middle`}>
      <table className="table-auto border-separate border-spacing-0">
        <thead className="z-10 bg-[#f5f6fc] h-10 sticky top-16">
          {/* Coins de remplissage */}
          <div className="absolute -left-0.5 top-0 w-4 h-4 bg-[#f5f6fc] -z-10" />
          <div className="absolute -right-0.5 top-0 w-4 h-4 bg-[#f5f6fc] -z-10" />
          <tr>
            <th
              scope="col"
              className="w-24 whitespace-nowrap px-2 py-1 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-y border-l border-gray-300 rounded-tl-xl">
              {t("date")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("account")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("category")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("proof")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-y border-gray-300">
              {t("amount")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white  rounded-tr-xl border-y border-r border-gray-300">
              {t("status")}
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={transaction._id} className={`text-xs ${index % 2 === 1 ? "bg-white hover:bg-blue-100" : "bg-gray-100 hover:bg-blue-100"}`}>
              <td className={`whitespace-nowrap px-2 py-2 border-l border-gray-300`}>
                <div>{transaction.date.slice(0, 10)}</div>
              </td>
              <td className={`whitespace-nowrap px-2 py-2  `}>
                <div
                  className="cursor-pointer underline font-bold"
                  onClick={() => {
                    navigate(`/transactions?id=${transaction._id}`);
                  }}>
                  {(transaction.name || transaction.description || transaction.additionalInformation)?.length > 50
                    ? (transaction.name || transaction.description || transaction.additionalInformation).substring(0, 47) + "..."
                    : transaction.name || transaction.description || transaction.additionalInformation}
                </div>
              </td>

              <td className={`flex items-center gap-1 whitespace-nowrap pl-2 pr-5 py-2`}>
                <TransactionIcon transaction={transaction} />
                {getTransactionAccountName(transaction)}
              </td>

              <td className={`whitespace-nowrap w-[200px] px-2 py-2`}>{transaction.category ? transaction.category + " - " + t(`transactions.${transaction.category}`) : null}</td>

              <td className={`whitespace-nowrap px-2 py-2`}>
                <div className="flex gap-2">
                  {transaction.PurchaseInvoices.length > 0 && (
                    <>
                      {transaction.PurchaseInvoices.map((invoice) => (
                        <div key={invoice._id} className="flex justify-center">
                          <a href={invoice.file} target="_blank" rel="noopener noreferrer" title={invoice.fileName}>
                            <FaFileInvoice size={17} />
                            <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{invoice.reference}</span>
                          </a>
                        </div>
                      ))}
                    </>
                  )}
                  {transaction.Invoices.length > 0 && (
                    <>
                      {transaction.Invoices.map((invoice) => (
                        <div key={invoice._id} className="flex justify-center">
                          <a href={invoice.file} target="_blank" rel="noopener noreferrer" title={invoice.fileName}>
                            <FaFileInvoice size={17} />
                            <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{invoice.reference}</span>
                          </a>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </td>

              <td className={`whitespace-nowrap px-2 py-2`}>
                <div className="flex items-center gap-1">
                  <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
                </div>
              </td>
              <td className={`whitespace-nowrap px-2 py-2 text-sm border-r border-gray-300`}>
                <Status status={transaction.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`pr-2 py-1 rounded-sm flex justify-center items-center text-xs font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

const Status = ({ status }) => {
  const { t } = useTranslation();
  if (status === "RECONCILED")
    return (
      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-600">
        <div className="h-2 w-2 rounded-full bg-green-400" />
        {t(`transactions.RECONCILED`)}
      </span>
    );
  if (status === "TO_RECONCILE")
    return (
      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">
        <div className="h-2 w-2 rounded-full bg-red-400" />
        {t(`transactions.TO_RECONCILE`)}
      </span>
    );
};

const TransactionIcon = ({ transaction }) => {
  if (transaction.accountDetails === "petty_cash")
    return (
      <div className="border border-blue-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaCashRegister size={12} className="text-blue-600" />
      </div>
    );
  if (transaction.accountDetails === "savings")
    return (
      <div className="border border-green-600 h-5 w-5 rounded-full p-1 flex items-center justify-center shadow-sm bg-gray-50">
        <FaRegMoneyBillAlt size={12} className="text-green-600" />
      </div>
    );
  if (transaction.bankLogo) return <img src={transaction.bankLogo} height={20} width={20} />;
};

export default AllTransactions;
