import { SiTrustpilot } from "react-icons/si";
import { useTranslation } from "react-i18next";

const TrustBox = (handleClick) => {
  const { t } = useTranslation();
  return (
    <a
      href="https://fr.trustpilot.com/evaluate/finotor.com"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center px-2 py-1 border border-green-500 font-bold text-black bg-white rounded hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-500 text-sm mx-2"
      onClick={handleClick}>
      <span className="mr-1">{t("nps.trustpilot.button")}</span>
      <SiTrustpilot className="text-[#00B67A]" />
      <span className="ml-1">Trustpilot</span>
    </a>
  );
};

export default TrustBox;
