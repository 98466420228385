import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import categories from "@data/categories.js";
import api from "@services/api";

import { currencyToStr, getTransactionAccountName } from "@utils/index";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Combobox } from "@components/utils/Combobox";
import Modal from "@components/utils/Modal";
import { RxCross1 } from "react-icons/rx";
import Loader from "@components/utils/Loader";
import { Mixpanel } from "../../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";

const ReconcileModal = ({ isOpen, invoice, onClose, onDone }) => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const { organization } = useSelector((state) => state.Auth);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [category, setCategory] = useState(null);

  const getClientLabel = (client) => {
    return client?.entityType === "INDIVIDUAL" ? client?.firstname + " " + client?.lastname : client?.companyName || "";
  };

  const getTransactions = async () => {
    try {
      const { data } = await api.post("/transaction/search", {
        per_page: 10000,
        status: "TO_RECONCILE",
        amountMin: 0,
        amountMax: invoice.amount,
        orderBy: "date",
        order: "DESC",
      });
      setTransactions(data.transactions);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error(t("toast.error"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    getTransactions();
  }, [isOpen]);

  const handleReconcile = async () => {
    try {
      const totalAmount = selectedTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
      if (totalAmount !== invoice.amount) {
        if (!window.confirm(t("invoices.viewer.reconcile_amount_mismatch_confirm"))) return;
      }

      Mixpanel.track(MIXPANEL_EVENTS.btn_invoice_reconciled_transaction);

      for (const transaction of selectedTransactions) {
        await api.put(`/transaction/${transaction._id}`, { status: "RECONCILED", category: category, Invoices: [invoice] });
      }
      onDone();
      onClose();
      toast.success(t("toast.success.reconciled"));
    } catch (error) {
      console.error("Error reconciling:", error);
      toast.error(t("toast.error"));
    }
  };

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  return (
    <Modal isOpen={isOpen} className={"w-4/5"} innerClassName="w-full h-[calc(100vh-2rem)] max-h-[calc(100vh-2rem)]" onClose={onClose}>
      <div className="flex flex-col h-full">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">{t("invoices.viewer.reconcile_invoice")}</h2>
          <button type="button" onClick={onClose}>
            <RxCross1 />
          </button>
        </div>
        <div className="px-8 py-4 flex justify-between items-center gap-4 bg-blue-50">
          <div className="flex items-center gap-3">
            <p className="text-sm text-gray-700 font-medium">{t("invoices.viewer.reconcile_description")}</p>
          </div>
          <div className="px-4 py-2 bg-white rounded-lg shadow-sm border border-blue-200">
            <p className="text-sm font-semibold text-blue-700">{t("invoices.reconcile.total_amount", { amount: total })}</p>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <Loader />
            </div>
          ) : (
            <table className="min-w-full">
              <thead className="bg-blue-50 shadow-sm sticky -top-0 z-10">
                <tr>
                  <th className="w-9 px-2 py-3 text-left text-xs font-semibold text-gray-500">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 rounded"
                      checked={selectedTransactions.length === transactions.length}
                      onChange={() => {
                        setSelectedTransactions(selectedTransactions.length === transactions.length ? [] : transactions);
                      }}
                    />
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-semibold text-gray-500">{t("date")}</th>
                  <th className="px-2 py-3 text-left text-xs font-semibold text-gray-500">{t("description")}</th>
                  <th className="px-2 py-3 text-left text-xs font-semibold text-gray-500 w-50">{t("account")}</th>
                  <th className="px-2 py-3 text-left text-xs font-semibold text-gray-500">{t("amount")}</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <tr
                    key={transaction._id}
                    onClick={() => {
                      const isSelected = selectedTransactions.some((t) => t._id === transaction._id);
                      setSelectedTransactions(isSelected ? selectedTransactions.filter((t) => t._id !== transaction._id) : [...selectedTransactions, transaction]);
                    }}
                    className={`text-sm cursor-pointer ${selectedTransactions.some((t) => t._id === transaction._id) ? "bg-blue-50" : "hover:bg-gray-50"}`}>
                    <td className="px-2 py-2">
                      <input type="checkbox" className="w-4 h-4 text-blue-600 rounded" checked={selectedTransactions.some((t) => t._id === transaction._id)} readOnly />
                    </td>
                    <td className="px-2 py-2">{transaction.date.slice(0, 10)}</td>
                    <td className="px-2 py-2">
                      {(transaction.name || transaction.description || transaction.additionalInformation)?.length > 100
                        ? (transaction.name || transaction.description || transaction.additionalInformation).substring(0, 97) + "..."
                        : transaction.name || transaction.description || transaction.additionalInformation}
                    </td>

                    <td className={`w-50 px-4 py-2`}>
                      <div className="flex items-center gap-1 w-fit">
                        <img src={transaction.bankLogo} height={20} width={20} className="mr-2" />
                        <div>{getTransactionAccountName(transaction)}</div>
                      </div>
                    </td>
                    <td className="px-2 py-2">
                      <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="flex gap-2"></div>

        <div className="p-4 border-t">
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-3">
              <div className={`w-2 h-2 rounded-full ${selectedTransactions.length ? "bg-green-500" : "bg-gray-300"}`}></div>
              <span className="text-sm text-gray-600">
                {selectedTransactions.length > 0 ? (
                  <span className="font-medium">
                    {selectedTransactions.length} {selectedTransactions.length === 1 ? "transaction" : "transactions"} selected
                  </span>
                ) : (
                  t("invoices.reconcile.select_transactions")
                )}
              </span>
            </div>
            <div className="flex items-center gap-3">
              {selectedTransactions.length > 0 && (
                <div className="flex items-center gap-2 bg-blue-50 px-3 py-1.5 rounded-lg">
                  <span className="text-sm font-medium text-gray-900">{t("invoices.reconcile.client", { name: getClientLabel(invoice.Client) })}</span>
                  <span className="px-2 py-0.5 bg-blue-100 text-blue-700 rounded-full text-xs">{t("invoices.reconcile.type")}</span>
                </div>
              )}
              <div className="w-72">
                <Combobox
                  value={categories.find((cat) => cat.id == category)}
                  options={filteredCategories}
                  onChange={async (category) => setCategory(category?.id || null)}
                  getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
                  displayValue={(e) => (e ? t(`transactions.${e.id}`) : null)}
                  position="bottom-full left-0"
                  inputClassName="py-2"
                  containerClassName="rounded-lg border-gray-300"
                  placeholder={t("category")}
                />
              </div>
              <button onClick={handleReconcile} disabled={!selectedTransactions.length || !category} className={"btn-primary"}>
                {t("reconcile")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Amount = ({ amount, currency }) => {
  const color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <span className={`font-semibold ${color}`}>
      {amount}
      {currency}
    </span>
  );
};

export default ReconcileModal;
