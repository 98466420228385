import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-tagsinput/react-tagsinput.css";

import api from "@services/api";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import InviteUser from "./InviteUser";
import { PRICING_FEATURES } from "@constants";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const MyUsers = ({ setTab }) => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState(null);

  const get = async () => {
    const res = await api.post("/organization/search");
    if (!res.ok) toast.error(t("toast.error.fetching_org"));
    setOrganizations(res.data);
  };

  useEffect(() => {
    get();
  }, []);

  if (!organizations) return <Loader />;

  return (
    <div className="flex container mx-auto">
      <div className="flex flex-col gap-4 bg-white rounded-xl w-full">
        {organizations.map((org) => {
          return <Users key={org._id} organization={org} get={get} setTab={setTab} />;
        })}
      </div>
    </div>
  );
};

const Users = ({ organization, get, setTab }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-6 bg-white rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-primary text-2xl font-semibold">
          {t("users_from")} {organization.name}
        </h1>
        {organization.Users.length >= PRICING_FEATURES[organization.plan]?.userLimit ? (
          <div className="flex items-center space-x-4 px-6 pb-4">
            <div className="flex-1">
              <span className="text-gray-700 font-medium">{t("users_premium")}</span>
              <p className="text-sm text-gray-500 mt-1">{t("users_premium_description")}</p>
            </div>
            <button
              className="flex items-center px-5 py-2.5 text-sm font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition-all duration-200 hover:scale-105"
              onClick={() => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_upgrade_plan_user_limit);
                setTab("my-subscription");
              }}>
              <span className="mr-2">✨</span>
              {t("upgrade_your_plan")}
            </button>
          </div>
        ) : (
          <InviteUser organization={organization} onCreated={get} />
        )}
      </div>

      <div className="border border-gray-200 rounded-lg divide-y">
        {organization.Users.map((user) => (
          <div key={user._id} className="flex items-center p-4 hover:bg-gray-50 transition-colors">
            <div className="flex-1">
              <p className="text-gray-900 font-medium">
                {user.firstname} {user.lastname}
              </p>
              <p className="text-gray-500 text-sm mt-1">{user.email}</p>
              <p className="text-gray-500 text-xs mt-2">Member since {new Date(user.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyUsers;
