import { useTranslation } from "react-i18next";
import { FaClipboardList } from "react-icons/fa6";
import { FaUpload, FaPlug } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { currencyToStr } from "@utils";
import { Fragment, useEffect, useState } from "react";
import api from "@services/api";
import { SiGoogleanalytics } from "react-icons/si";
import { useSelector } from "react-redux";
import { FaFileInvoice, FaUniversity, FaUser } from "react-icons/fa";
import Onboarding from "@components/modals/onboarding";
import { LiaPiggyBankSolid, LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Index = () => {
  const { t, i18n } = useTranslation();
  const [lastTransaction, setLastTransaction] = useState([]);
  const [lastSaleInvoice, setLastSaleInvoice] = useState([]);
  const [lastPurchaseInvoice, setLastPurchaseInvoice] = useState([]);
  const [overdueInvoice, setOverdueInvoice] = useState({});
  const [accounts, setAccounts] = useState(null);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [balance, setBalance] = useState({});
  const [cashFlow, setCashFlow] = useState({});
  const [totalInvoiced, setTotalInvoiced] = useState({});
  const [totalPurchase, setTotalPurchase] = useState({});
  const [totaltransactionToReconcile, setTotaltransactionToReconcile] = useState(0);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const { user, organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!user) return;
    setOpenOnboardingModal(!user.onboardedAt);
  }, [user]);

  useEffect(() => {
    if (!accounts || !organization) return;
    const since = new Date();
    since.setMonth(since.getMonth());
    since.setUTCDate(1);
    since.setUTCHours(0, 0, 0, 0);
    const getLastMonthCashFlow = async () => {
      const { data } = await api.post("/analytics/cashflow", {
        accountsId: accounts.map((account) => account._id),
        since,
        OrganizationId: organization._id,
      });
      setCashFlow(data[0]);
    };
    const getAnalyticsPaidInvoice = async () => {
      const { data } = await api.post("/analytics/purchase_transaction", { accountsId: accounts.map((account) => account._id), since });
      setTotalPurchase(data[0]);
    };
    getLastMonthCashFlow();
    getAnalyticsPaidInvoice();
  }, [accounts, organization]);

  useEffect(() => {
    if (!organization) return;
    Mixpanel.track(MIXPANEL_EVENTS.page_view_home);

    const since = new Date();
    since.setMonth(since.getMonth());
    since.setUTCDate(1);
    since.setUTCHours(0, 0, 0, 0);
    const getAccount = async () => {
      const { data } = await api.post("/account/search", { OrganizationId: organization._id });
      setAccounts(data);
    };

    const getBalance = async () => {
      const { data } = await api.post("/analytics/balances", { OrganizationId: organization._id });
      setBalance(data[0]);
    };
    const getAnalyticsInvoice = async () => {
      const { data } = await api.post("/analytics/invoices", { statuses: ["PAID", "DUE", "SENT"], since, OrganizationId: organization._id });
      setTotalInvoiced(data);
    };
    const getAnalyticsOverdueInvoice = async () => {
      const { data } = await api.post("/analytics/invoices", { overdue: true, statuses: ["DUE", "SENT"], since, OrganizationId: organization._id });
      setOverdueInvoice(data);
    };
    const getTransactionToReconcile = async () => {
      const { data } = await api.post("/transaction/search", { per_page: 0, status: "TO_RECONCILE", OrganizationId: organization._id });
      setTotaltransactionToReconcile(data.total);
    };
    const getTransaction = async () => {
      const { data } = await api.post("/transaction/search", { per_page: 3, OrganizationId: organization._id });
      setLastTransaction(data.transactions);
    };
    const getPurchaseInvoice = async () => {
      const { data } = await api.post("/purchase-invoice/search", { per_page: 3, OrganizationId: organization._id });
      setLastPurchaseInvoice(data.purchaseInvoices);
    };
    const getSaleInvoice = async () => {
      const { data } = await api.post("/invoice/search", { per_page: 3, OrganizationId: organization._id });
      setLastSaleInvoice(data.invoices);
      setInvoiceCount(data.total);
    };
    getAccount();
    getBalance();
    getAnalyticsInvoice();
    getAnalyticsOverdueInvoice();
    getTransactionToReconcile();
    getTransaction();
    getPurchaseInvoice();
    getSaleInvoice();
  }, [organization]);

  const currentMonth = () => {
    const today = new Date();
    const month = today.toLocaleString(i18n.language, { month: "long" });

    return month.charAt(0).toUpperCase() + month.slice(1);
  };

  return (
    <div className=" flex flex-col gap-6 text-black-100 p-8 ">
      <div>
        <h1 className="font-bold text-[28px] leading-9">
          {t("welcome")}&nbsp;{user.firstname}&nbsp;👋
        </h1>
        <p className=" mt-2 text-slate-600">{t("welcome_intro")}</p>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        <Link to="/bank" className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black">
          <span className="text-blue">
            <FaUniversity />
          </span>
          {t("myintegrations.add_new_bank")}
        </Link>
        <Link to="/invoices" className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black">
          <span className="text-blue">
            <FaClipboardList />
          </span>
          {t("create_sale_invoice")}
        </Link>
        <Link to="/purchase-invoice" className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black">
          <span className="text-blue">
            <FaUpload />
          </span>
          {t("add_purchase_invoice")}
        </Link>
        <Link
          to="/settings/my-organizations"
          className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black">
          <span className="text-blue">
            <FaUser />
          </span>
          {t("complete_profile")}
        </Link>
      </div>
      <div>
        <div className="flex gap-4 justify-between mb-4">
          <h2 className="font-bold text-lg">
            {t("key-numbers")}
            {" - "}
            {currentMonth()}
          </h2>
          <Link className="flex gap-2 font-bold text-lg items-center" to="/dashboard">
            <span className="text-blue">
              <SiGoogleanalytics />
            </span>
            {t("view_dashboard")}
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="p-5 pr-4 bg-white rounded flex flex-col gap-6 border border-black-20">
            {accounts?.length > 0 && (
              <>
                <div>
                  <h3 className="text-sm mb-2">{t("account_balance")}</h3>
                  <div className="flex gap-4 items-center">
                    <div className="text-lg font-semibold">
                      {balance?.amount}
                      <span className="ml-1.5">{currencyToStr(balance?.currency)}</span>
                    </div>
                    <AccountOverview />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>
                    <h3 className="text-sm mb-2">{t("income")}</h3>
                    <div className="text-lg font-semibold">
                      {cashFlow?.Revenue} {currencyToStr(organization?.currency)}
                    </div>
                  </div>
                  <div>
                    <h3 className="text-sm mb-2">{t("outcome")}</h3>
                    <div className="text-lg font-semibold">
                      {cashFlow?.Cost} {currencyToStr(organization?.currency)}
                    </div>
                  </div>
                </div>
              </>
            )}
            {accounts?.length === 0 && (
              <div className="flex flex-col justify-between h-full gap-3">
                <p className="text-center">{t("home.no_account")}</p>
                <LiaPiggyBankSolid size={100} className="mx-auto text-black-100 font-light" />
                <Link to="/bank" className="btn-primary bg-blue hover:bg-blue-700 border-none flex items-center justify-center gap-4 px-4">
                  {t("connect_pro")} <FaPlug className="rotate-90" />
                </Link>
              </div>
            )}
          </div>
          <div className="p-5 pr-4 bg-white rounded flex flex-col gap-6 border border-black-20">
            {invoiceCount > 0 && (
              <>
                <div className="grid grid-cols-2">
                  <div>
                    <h3 className="text-sm mb-2">{t("total_invoiced")}</h3>
                    <div className="text-lg font-semibold">
                      {totalInvoiced.total} {currencyToStr(organization?.currency)} <span className="text-sm font-normal">HT</span>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-sm mb-2">{t("overdue_invoice")}</h3>
                    <div className="text-lg font-semibold">
                      {overdueInvoice.total} {currencyToStr(organization?.currency)} <span className="text-sm font-normal">HT</span>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="text-sm mb-2">{t("total_purchase")}</h3>
                  <div className="text-lg font-semibold">
                    {totalPurchase?.Cost} {currencyToStr(organization?.currency)} <span className="text-sm font-normal">TTC</span>
                  </div>
                </div>
              </>
            )}
            {invoiceCount === 0 && (
              <div className="flex flex-col justify-between h-full gap-2">
                <p className="text-center">{t("home.no_invoice")}</p>
                <LiaFileInvoiceDollarSolid size={100} className="mx-auto" />
                <Link to="/invoices" className="btn-primary bg-blue border-none hover:bg-blue-700 flex items-center justify-center gap-4 px-4">
                  {t("invoices.create")} <FaFileInvoice />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <h2 className="font-bold text-lg mb-4">{t("to_do")}</h2>
        <div className="p-5 pr-4 bg-white rounded flex gap-24 border border-black-20">
          <div className="">
            <h3 className="text-sm">{t("unjustified")}</h3>
            <div className="text-lg font-semibold flex gap-1.5 mt-2 mb-3">
              <span>{totaltransactionToReconcile}</span>
              {t("transactions")}
            </div>
            <Link className="bg-blue hover:bg-blue-700 py-1 px-2.5 rounded text-white " to="/transactions?tab=TO_RECONCILE">
              {t("justify")}
            </Link>
          </div>
          <div>
            <h3 className="text-sm">
              {t("overdue_sale_invoice")}
              <span className="bg-red-600 ml-3 py-0.5 px-1 bg-opacity-10 text-red-600 rounded-3xl">{overdueInvoice?.count}</span>
            </h3>
            <div className="text-lg font-semibold mt-2 mb-3">
              {overdueInvoice?.total} {currencyToStr(organization?.currency)}
            </div>
            <Link className="bg-blue hover:bg-blue-700 py-1 px-2.5 rounded text-white" to="/invoices">
              {t("view")}
            </Link>
          </div>
        </div>
      </div>

      <div>
        <h2 className="font-bold text-lg mb-4">{t("home.key_metrics")}</h2>
        <div className="grid grid-cols-3 gap-6">
          <BestClients />
          <WorstSuppliers />
          <BestProducts />
        </div>
      </div>
      <div>
        <h2 className="font-bold text-lg mb-4">{t("recent_activity")}</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="p-5 pr-4 bg-white rounded flex flex-col gap-3.5 border border-black-20">
            <h3 className="text-lg font-semibold border-b border-black-30 pb-3.5">{t("transactions")}</h3>
            <ul className="list-none flex flex-col justify-between h-full ">
              {lastTransaction?.map((transaction) => (
                <li key={transaction._id} className="pb-3.5 border-b border-black-30 last:border-none">
                  <TransactionCard transaction={transaction} />
                </li>
              ))}
            </ul>
          </div>
          <div className="p-5 pr-4 bg-white rounded flex flex-col gap-3.5 border border-black-20">
            <h3 className="text-lg font-semibold border-b border-black-30 pb-3.5">{t("sale_invoice")}</h3>
            <ul className="list-none flex flex-col justify-between h-full">
              {lastSaleInvoice?.map((invoice) => (
                <li key={invoice._id} className="pb-3.5 border-b border-black-30 last:border-none">
                  <InvoiceCard invoice={invoice} />
                </li>
              ))}
            </ul>
          </div>
          <div className="p-5 pr-4 bg-white rounded flex flex-col gap-3.5 border border-black-20">
            <h3 className="text-lg font-semibold border-b border-black-30 pb-3.5">{t("purchase_invoice")}</h3>
            <ul className="list-none flex flex-col justify-between h-full">
              {lastPurchaseInvoice?.map((invoice) => (
                <li key={invoice._id} className="pb-3.5 border-b border-black-30 last:border-none">
                  <PurchaseInvoiceCard invoice={invoice} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Onboarding open={openOnboardingModal} setOpen={setOpenOnboardingModal} />
    </div>
  );
};

const TransactionCard = (transaction) => {
  const { organization } = useSelector((state) => state.Auth);
  if (!transaction) return;
  return (
    <Link to={`/transactions?id=${transaction.transaction._id}`} className="flex gap-2 justify-between items-center  mt-3.5">
      <div className="flex gap-2 items-center">
        <img src={transaction.transaction.bankLogo} alt="" className="w-6 aspect-square" />
        <div>
          <div className="text-sm font-semibold">{transaction.transaction.name}</div>
          <div className="text-sm">{transaction.transaction.date.slice(0, 10)}</div>
        </div>
      </div>
      <div className="text-sm font-semibold">
        {transaction.transaction.amount}
        {currencyToStr(transaction.transaction.currency ?? organization?.currency)}
      </div>
    </Link>
  );
};

const InvoiceCard = (invoice) => {
  const { organization } = useSelector((state) => state.Auth);
  if (!invoice) return;
  return (
    <Link to={`/invoices?id=${invoice.invoice._id}`} className="flex gap-2 justify-between items-center border-b border-black-30 last:border-none mt-3.5">
      <div className="flex gap-2 items-center">
        <img />
        <div>
          <div className="text-sm font-semibold">{invoice.invoice.name}</div>
          <div className="text-sm">{invoice.invoice.createdAt?.slice(0, 10)}</div>
        </div>
      </div>
      <div className="text-sm font-semibold">
        {invoice.invoice.totalTaxIncluded}
        {currencyToStr(invoice.invoice.currency ?? organization?.currency)}
        TTC
      </div>
    </Link>
  );
};

const PurchaseInvoiceCard = (invoice) => {
  const { organization } = useSelector((state) => state.Auth);
  if (!invoice) return;
  return (
    <Link to={`/purchase-invoice?id=${invoice.invoice._id}`} className="flex gap-2 justify-between items-center border-b border-black-30 last:border-none mt-3.5">
      <div className="flex gap-2 items-center">
        <img />
        <div>
          <div className="text-sm font-semibold">{invoice.invoice.reference}</div>
          <div className="text-sm">{invoice.invoice.createdAt?.slice(0, 10)}</div>
        </div>
      </div>
      <div className="text-sm font-semibold">
        {invoice.invoice.amount}
        {currencyToStr(invoice.invoice.currency ?? organization?.currency)}
        TTC
      </div>
    </Link>
  );
};

const AccountOverview = () => {
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    const getAccount = async () => {
      const { data: accounts } = await api.post("/account/search");
      setAccounts(accounts);
    };
    getAccount();
  }, []);
  return (
    <div className="flex -space-x-2 flex-wrap">
      {accounts.map((account, index) =>
        index < 3 ? (
          <img key={index} src={account.Requisition.NordigenInstitution.logo} className="w-8 h-8 rounded-full ring-2 ring-white aspect-square" alt="" />
        ) : (
          <Fragment key={index}></Fragment>
        ),
      )}
      {accounts.length > 3 ? (
        <div className="w-8 bg-white aspect-square rounded-full border border-gray-300 text-sm grid place-content-center ring-2 ring-white"> {accounts.length - 3} +</div>
      ) : (
        <></>
      )}
    </div>
  );
};

const BestClients = () => {
  const [bestClients, setBestClients] = useState([]);
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);

  const fetchBestClients = async () => {
    try {
      const { ok, data } = await api.post("/analytics/best_clients", { OrganizationId: organization?._id });
      if (!ok) throw new Error("Failed to fetch best clients");
      setBestClients(data);
    } catch (error) {
      console.error("Error fetching best clients:", error);
    }
  };

  useEffect(() => {
    if (!organization) return;
    fetchBestClients();
  }, [organization]);

  return (
    <div className="bg-white rounded p-4 h-full border-l-2 border-l-green-500">
      <h3 className="text-sm font-semibold text-green-600 mb-4">{t("home.best_clients")}</h3>

      {bestClients?.length > 0 ? (
        <div className="space-y-3">
          {bestClients?.map((client) => (
            <div key={client.clientId} className="border-b border-gray-100 pb-3 last:border-0 last:pb-0">
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium">{client.name || t("home.unnamed_client")}</span>
                <span className="text-sm text-green-600">
                  {client.totalAmount} {currencyToStr(organization?.currency)}
                </span>
              </div>
              <div className="flex justify-between text-xs text-gray-400">
                <span>{client.emails?.[0] || "-"}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-sm text-center text-gray-400 py-6">{t("home.no_data_available")}</p>
      )}
    </div>
  );
};

const WorstSuppliers = () => {
  const [worstSuppliers, setWorstSuppliers] = useState([]);
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);

  const fetchWorstSuppliers = async () => {
    try {
      const { ok, data } = await api.post("/analytics/worst_suppliers", { OrganizationId: organization?._id });
      if (!ok) throw new Error("Failed to fetch worst suppliers");
      setWorstSuppliers(data);
    } catch (error) {
      console.error("Error fetching worst suppliers:", error);
    }
  };

  useEffect(() => {
    if (!organization) return;
    fetchWorstSuppliers();
  }, [organization]);

  return (
    <div className="bg-white rounded p-4 h-full border-l-2 border-l-orange-500">
      <h3 className="text-sm font-semibold text-orange-600 mb-4">{t("home.best_suppliers")}</h3>

      {worstSuppliers?.length > 0 ? (
        <div className="space-y-3">
          {worstSuppliers?.map((supplier) => (
            <div key={supplier.supplierId} className="border-b border-gray-100 pb-3 last:border-0 last:pb-0">
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium">{supplier.name || t("home.unnamed_supplier")}</span>
                <span className="text-sm text-orange-600">
                  {supplier.totalAmount} {currencyToStr(organization?.currency)}
                </span>
              </div>
              <div className="flex justify-between text-xs text-gray-400">
                <span>{supplier.email || "-"}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-sm text-center text-gray-400 py-6">{t("home.no_data_available")}</p>
      )}
    </div>
  );
};

const BestProducts = () => {
  const [bestProducts, setBestProducts] = useState([]);
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);

  const fetchBestProducts = async () => {
    try {
      const { ok, data } = await api.post("/analytics/best_products", { OrganizationId: organization?._id });
      if (!ok) throw new Error("Failed to fetch best products");
      setBestProducts(data);
    } catch (error) {
      console.error("Error fetching best products:", error);
    }
  };

  useEffect(() => {
    if (!organization) return;
    fetchBestProducts();
  }, [organization]);

  return (
    <div className="bg-white rounded p-4 h-full border-l-2 border-l-blue-500">
      <h3 className="text-sm font-semibold text-blue-600 mb-4">{t("home.best_products")}</h3>

      {bestProducts?.length > 0 ? (
        <div className="space-y-3">
          {bestProducts?.map((product) => (
            <div key={product.productId} className="border-b border-gray-100 pb-3 last:border-0 last:pb-0">
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium">{product.name || t("home.unnamed_product")}</span>
                <span className="text-sm text-blue-600">
                  {product.totalAmount} {currencyToStr(organization?.currency)}
                </span>
              </div>
              <div className="flex justify-between text-xs text-gray-400">
                <span>
                  {product.totalQuantity} {t("home.units")}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-sm text-center text-gray-400 py-6">{t("home.no_data_available")}</p>
      )}
    </div>
  );
};

export default Index;
