import { useEffect, useState } from "react";
import api from "@services/api";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { setOrganization } from "@redux/auth/actions";
import { copyToClipboard } from "@utils";
import { ENVIRONMENT } from "@config";
import { FaLink, FaArrowRight, FaBookOpen } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Modal from "@components/utils/Modal";
import ReferralsTable from "./components/table";
import undrawTerms from "@assets/undraw_terms.svg";

const termsUrl = {
  fr: "https://finotor.cellar-c2.services.clever-cloud.com/file//611bb4dbebde094858825eded1f70870/finotor-affiliation-program-terms-and-conditions-fr.pdf",
  en: "https://finotor.cellar-c2.services.clever-cloud.com/file//9f3b66c05b9f8a5f1544c817a6444b8f/finotor-affiliation-program-terms-and-conditions-en.pdf",
};

const notionUrl = {
  fr: "https://blue-shape-2ae.notion.site/Espace-Affili-s-de-Finotor-FR-127bbfd511cc80b2bbe2f9453762a9bc?pvs=4",
  en: "https://blue-shape-2ae.notion.site/Finotor-Affiliates-Space-EN-19fbbfd511cc808fb5dbfc31ac1c5916?pvs=4",
};

const List = () => {
  const organization = useSelector((state) => state.Auth.organization);
  const user = useSelector((state) => state.Auth.user);
  const [referrals, setReferrals] = useState([]);
  const [open, setOpen] = useState(!organization.referralCGVAcceptedAt);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redirectUrl = ENVIRONMENT === "development" ? "http://localhost:8082" : "https://app.finotor.com";

  const copyAffiliateLink = () => {
    copyToClipboard(`${redirectUrl}/auth/signup?referrerCode=${organization.referralCode}&lang=${user.language.toLowerCase()}`);
    toast.success(t("promo_code.copy_to_clipboard"));
    Mixpanel.track(MIXPANEL_EVENTS.btn_copy_referral_link);
  };

  const getReferrals = async () => {
    try {
      const { data } = await api.post(`/organization/referrals`, {
        OrganizationId: organization._id,
      });

      setReferrals(data.mergedReferrals);
    } catch (error) {
      toast.error(t("promo_code.error_referrals"));
      console.error(error);
    }
  };

  const acceptReferralCGV = async () => {
    if (organization.referralCGVAcceptedAt) {
      setOpen(false);
      return;
    }
    try {
      const { ok, data } = await api.put(`/organization/${organization._id}`, {
        referralCGVAcceptedAt: new Date(),
      });
      if (!ok) {
        toast.error(t("promo_code.error_accept_cgv"));
        return;
      }
      dispatch(setOrganization(data));
      toast.success(t("promo_code.success_accept_cgv"));
    } catch (error) {
      toast.error(t("promo_code.error_accept_cgv"));
      console.error(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_referral_page);
    getReferrals();
    setOpen(!organization.referralCGVAcceptedAt);
  }, [organization]);

  if (!organization.referralCGVAcceptedAt) {
    return (
      <div className="mx-auto">
        <div className="py-6 px-6">
          <div className="flex flex-col bg-white rounded-xl p-6">
            <div className="flex justify-between items-start">
              <div>
                <h1 className="text-2xl font-bold">{t("promo_code.title")}</h1>
                <p className="mt-2 text-slate-600">{t("promo_code.description")}</p>
              </div>
              <div className="flex flex-col gap-2">
                <button className="btn-secondary w-fit mt-4" onClick={() => setOpen(true)}>
                  {t("promo_code.button_open_cgv")}
                </button>
              </div>
              <ReferralCGVModal open={open} setOpen={setOpen} onClick={acceptReferralCGV} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("promo_code.title")}</h1>
              <p className="mt-2 text-slate-600">{t("promo_code.description")}</p>
            </div>
            <div className="flex gap-2">
              <button className="btn-secondary focus:bg-blue float-right mb-2" onClick={() => window.open(notionUrl[user.language.toLowerCase()], "_blank")}>
                <FaBookOpen className="mr-2" />
                {t("promo_code.documentation_link")}
              </button>
              <button className="btn-primary focus:bg-blue float-right mb-2" onClick={() => copyAffiliateLink()}>
                <FaLink className="mr-2" />
                {t("promo_code.share_link")}
              </button>
            </div>
          </div>
          <div className="mt-6 flex flex-col space-y-6">
            <span className="text-xl text-slate-700 font-semibold">{t("promo_code.rules")}</span>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-items-center">
              <div className="flex flex-col bg-[#BBF7D0] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.direct")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.starter")}</span> {t("promo_code.rules.direct.starter_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.lite")}</span> {t("promo_code.rules.direct.lite_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.premium")}</span> {t("promo_code.rules.direct.premium_description")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-[#FED7AA] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.indirect")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.lite")}</span> {t("promo_code.rules.indirect.lite_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.premium")}</span> {t("promo_code.rules.indirect.premium_description")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-[#E9D5FF] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.extended")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.lite")}</span> {t("promo_code.rules.extended.lite_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.premium")}</span> {t("promo_code.rules.extended.premium_description")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-[#F3F6FF] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.bonuses")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.100_bonus")}</span> {t("promo_code.rules.100_bonus_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.150_bonus")}</span> {t("promo_code.rules.150_bonus_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.300_bonus")}</span> {t("promo_code.rules.300_bonus_description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col space-y-3">
            <span className="text-xl text-slate-700 font-semibold">{t("promo_code.follow")}</span>
            <ReferralsTable organizations={referrals} />
          </div>
          <div className="mt-8 flex-row flex gap-1 w-full items-center justify-start hover:underline hover:cursor-pointer">
            <a href={termsUrl[user.language.toLowerCase()]} target="_blank" rel="noopener noreferrer" className="flex items-center gap-1">
              <span className="">{t("promo_code.read_cgv")}</span>
              <FaArrowRight size={12} />
            </a>
          </div>
        </div>
        <ReferralCGVModal open={open} setOpen={setOpen} onClick={acceptReferralCGV} />
      </div>
    </div>
  );
};

const ReferralCGVModal = ({ open, setOpen, onClick }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.Auth.user);

  return (
    <Modal isOpen={open} onClose={() => setOpen(!open)} className="flex w-[600px] max-h-full">
      <div className="w-full h-full px-8 py-6 bg-white rounded-xl flex flex-col items-center justify-center gap-6">
        <img src={undrawTerms} alt="Terms and Conditions" className="w-48" />
        <h1 className="text-2xl font-bold text-center">{t("promo_code.accept_cgv")}</h1>
        <div className="text-gray-700 flex flex-col items-center gap-4">
          <p className="text-center mx-auto">
            {t("promo_code.cgv_description_1")}{" "}
            <a href={termsUrl[user.language.toLowerCase()]} target="_blank" rel="noopener noreferrer" className="font-semibold underline hover:text-blue-600">
              {t("promo_code.cgv")}
            </a>{" "}
            {t("promo_code.cgv_description_2")}
          </p>
          <p className="text-center mx-auto">{t("promo_code.cgv_confirm")}</p>
        </div>

        <div className="flex justify-center gap-4">
          <button className="btn-secondary px-6 py-5 min-w-[150px]" onClick={() => setOpen(false)}>
            {t("promo_code.button_cancel_cgv")}
          </button>
          <button className="btn-primary px-6 py-5 min-w-[150px]" onClick={onClick}>
            {t("promo_code.button_confirm_cgv")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default List;
