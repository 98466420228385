import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-tagsinput/react-tagsinput.css";

import api from "@services/api";
import { useNavigate } from "react-router-dom";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import FileInput from "../../components/utils/FileInput";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "@redux/auth/actions";
import { hexToRgb } from "../../utils";
import Divider from "../../components/utils/Divider";
import { LuCalendar, LuPlus, LuTrash2 } from "react-icons/lu";
import factureLogo from "../../assets/facture-logo.png";
import gocardlessLogo from "../../assets/gocardless.png";
import { PRICING_FEATURES } from "../../constants";

import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import PaymentModal from "./components/paymentModal";
import { useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const BRAND_COLORS = {
  RED: "#EE605C",
  CORAL: "#EF727A",
  PINK: "#EE7DAC",
  PURPLE: "#B55DF0",
  BLUE: "#5E90EA",
  LIGHT_BLUE: "#82c9de",
  TURQUOISE: "#58CAD1",
  MINT: "#53CFAC",
  GREEN: "#8DD076",
  YELLOW: "#F5C742",
  ORANGE: "#F59C42",
};

const MyOrganizations = ({ setTab }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const organization = useSelector((state) => state.Auth.organization);
  const [values, setValues] = useState();
  const dispatch = useDispatch();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [gocardlessStatus, setGocardlessStatus] = useState("not_connected");

  useEffect(() => {
    setValues(organization);
  }, [organization]);

  useEffect(() => {
    if (location.state?.scrollTo === "gocardless") {
      setTimeout(() => {
        const element = document.getElementById("gocardless");
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }, 100);
    }
  }, [location, values]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_settings);
    getGocardlessAccountStatus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await api.put(`/organization/${values._id}`, { ...values });
    if (!res.ok) return toast.error(t("toast.error.organization_updating"));
    dispatch(setOrganization(values));
    toast.success(t("toast.sucess.organization_updated"));
  };

  const handleDeleteTax = async (id) => {
    try {
      if (!window.confirm(t("confirm_delete_message"))) return;
      const res = await api.remove(`/tax/${id}`);
      if (!res.ok) return toast.error(t("toast.error.deleting_tax"));
      const taxes = values.Taxes.filter((tax) => tax._id !== id);
      dispatch(setOrganization({ ...values, Taxes: taxes }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTax = async () => {
    try {
      const res = await api.post(`/tax`, { name: "tax", value: 0, description: "" });
      if (!res.ok) return toast.error(t("toast.error.adding_tax"));
      const taxes = values.Taxes;
      taxes.push(res.data.tax);
      dispatch(setOrganization({ ...values, Taxes: taxes }));
    } catch (err) {
      console.log(err);
    }
  };

  const getGocardlessAccountStatus = async () => {
    try {
      const { data } = await api.get(`/gocardless/status/${organization._id}`);
      setGocardlessStatus(data.creditor.verification_status);
      console.log(data);
    } catch (error) {
      console.error("Error fetching Gocardless account status:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="flex flex-col w-full">
      <form className="flex flex-col gap-4 mt-4" onSubmit={handleSubmit}>
        <h2 className="font-semibold text-xl">{values.name}</h2>
        {/* Logo */}
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-2">
            <h2 className="text-primary text-lg font-semibold">{t("settings.add_logo")}</h2>
            <FileInput value={values.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} folder={`/organization`} name={`logo-${values._id}`} />
          </div>
          {/* Color */}
          <div className="flex flex-col gap-2">
            <h2 className="text-primary text-lg font-semibold flex items-center gap-2">
              {t("settings.invoice_color")}
              {!PRICING_FEATURES[organization?.plan]?.customInvoice && <span className="bg-yellow-300 text-xs px-2 py-1 rounded-tr-lg rounded-bl-lg font-medium">Premium</span>}
            </h2>
            <div className="flex flex-wrap gap-2">
              {!PRICING_FEATURES[organization?.plan]?.customInvoice ? (
                <div className="flex flex-col gap-1 justify-center">
                  <div className="flex flex-wrap gap-3 ">
                    {Object.values(BRAND_COLORS).map((color) => (
                      <button
                        key={color}
                        disabled
                        title="Upgrade to Premium to customize invoice colors"
                        style={{ backgroundColor: hexToRgb(color), opacity: 0.6 }}
                        className="w-10 h-10 rounded-lg border-2 border-gray-200 shadow-sm transition-all duration-200 hover:opacity-80 relative">
                        {(values.color || BRAND_COLORS.LIGHT_BLUE) === color && (
                          <span className="absolute inset-0 flex items-center justify-center text-white">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                  <p className="text-gray-700 mt-4 font-medium leading-relaxed">{t("settings.invoice_color_premium")}</p>
                  <span
                    onClick={() => {
                      Mixpanel.track(MIXPANEL_EVENTS.btn_upgrade_plan_custom_invoice);
                      setTab("my-subscription");
                    }}
                    className="text-sm w-fit font-semibold bg-gradient-to-r from-blue-400 to-blue-500 text-white px-4 py-1.5 rounded-full shadow-lg hover:from-blue-500 hover:to-blue-600 transform hover:scale-105 transition-all duration-200 cursor-pointer">
                    ✨ {t("upgrade_your_plan")}
                  </span>
                </div>
              ) : (
                Object.values(BRAND_COLORS).map((color) => (
                  <button
                    key={color}
                    onClick={() => setValues({ ...values, color })}
                    style={{ backgroundColor: hexToRgb(color) }}
                    className="w-8 h-8 rounded-md border-2 border-gray-50 transition-transform hover:scale-110">
                    {(values.color || BRAND_COLORS.LIGHT_BLUE) === color && <span className="text-white text-sm">✔</span>}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold">{t("settings.organization_info")}</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* Email */}
            <div className="flex flex-col">
              <label htmlFor="email" className="text-gray-800 text-base mb-1">
                {t("email")}
              </label>
              <input
                id="email"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.email ?? ""}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
                placeholder={t("enter_email")}
              />
            </div>
            {/* IBAN */}
            <div className="flex flex-col">
              <label htmlFor="iban" className="text-gray-800 text-base mb-1">
                {t("iban")}
              </label>
              <input
                id="iban"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.iban ?? ""}
                onChange={(e) => setValues({ ...values, iban: e.target.value })}
                placeholder="What is the organization's IBAN?"
              />
            </div>
            {/* Registration number */}
            <div className="flex flex-col">
              <label htmlFor="registrationNumber" className="text-gray-800 text-base mb-1">
                {t("business_registration_number")}
              </label>
              <input
                id="registrationNumber"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.registrationNumber ?? ""}
                onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
                placeholder="What is the organization's registration number? (e.g. SIREN)"
              />
            </div>
            {/* VAT */}
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("vat_number")}</div>
              <input
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.vat ?? ""}
                onChange={(e) => setValues({ ...values, vat: e.target.value })}
                placeholder={t("organization.vat_placeholder")}
              />
            </div>

            {/* Currency */}
            <div className="flex flex-col">
              <label htmlFor="currency" className="text-gray-800 text-base mb-1">
                {t("currency")}
              </label>
              <div className="relative">
                <select
                  id="currency"
                  className="border-[1px] rounded-md text-gray-800 px-3 py-2 bg-white appearance-none w-full pr-8"
                  value={values?.currency ?? "EUR"}
                  onChange={(e) => setValues({ ...values, currency: e.target.value })}>
                  <option value="EUR">EUR (€)</option>
                  <option value="GBP">GBP (£)</option>
                  <option value="USD">USD ($)</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <IoIosArrowDown className="h-4 w-4" />
                </div>
              </div>
            </div>
            {/* Activité */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="activity" className="text-gray-800 text-base mb-1">
                {t("organization.activity")}
              </label>
              <textarea
                id="activity"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.activity ?? ""}
                onChange={(e) => setValues({ ...values, activity: e.target.value })}
                placeholder={t("organization.activity_placeholder")}
              />
            </div>
          </div>
        </div>
        {/* Billing address */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold"> {t("billing_address")}</h2>
          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("street")}</div>
              <input
                type="text"
                value={values.billingAddressStreet ?? ""}
                onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.street_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("city")}</div>
              <input
                type="text"
                value={values.billingAddressCity ?? ""}
                onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.city_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-gray-800 text-base mb-1"> {t("zip_code")} </div>
              <input
                type="text"
                value={values.billingAddressZipCode ?? ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    billingAddressZipCode: e.target.value,
                  })
                }
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.zip_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-gray-800 text-base mb-1"> {t("country")} </div>
              <input
                type="text"
                value={values.billingAddressCountry ?? ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    billingAddressCountry: e.target.value,
                  })
                }
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.country_placeholder")}
              />
            </div>
          </div>
        </div>
        <Divider />
        {/* VAT + add new VAT */}
        <div className="w-full flex flex-col gap-2 col-span-2">
          <h2 className="text-primary text-lg font-semibold">{t("vat")}</h2>
          <div className="flex flex-col gap-4">
            {values.Taxes?.map((tax, index) => (
              <div key={index} className="flex items-end w-full gap-4">
                <div className="w-54 flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("name")} </div>
                  <input
                    type="text"
                    value={tax.name ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].name = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                    placeholder={t("name")}
                  />
                </div>
                <div className="w-54 flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("VAT_rate")} </div>
                  <input
                    type="text"
                    value={tax.value ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].value = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                    placeholder={t("VAT_rate")}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("description")} </div>
                  <input
                    type="text"
                    value={tax.description ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].description = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                    placeholder={t("description")}
                  />
                </div>
                <button
                  className="bg-transparent ml-2 font-semibold hover:text-red-500 hover:border-red-500 p-3 text-sm border rounded-md"
                  onClick={() => handleDeleteTax(tax._id)}
                  type="button">
                  <LuTrash2 size={16} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddTax}
              className="w-fit px-4 py-2 text-blue-400 text-sm mb-1 font-semibold border rounded-md border-blue-400 hover:bg-blue-50 transition-colors flex items-center gap-1">
              <LuPlus size={16} /> {t("add_vat")}
            </button>
          </div>
        </div>
        <Divider />
        {/* Due date */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold mb-2">{t("settings.payment_delay_title")}</h2>
          <div className="flex items-center border rounded-md max-w-xs overflow-hidden">
            <div className="px-3 py-2 text-gray-400">
              <LuCalendar size={20} />
            </div>
            <div className="relative flex-1">
              <input
                id="dueDate"
                type="number"
                min="0"
                max="120"
                value={values.dueDate ?? 30}
                onChange={(e) => setValues({ ...values, dueDate: e.target.value })}
                className="w-full outline-none text-gray-800 py-2 pr-12"
                placeholder="30"
              />
              <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none">{t("settings.number_of_days")}</div>
            </div>
          </div>
        </div>
        <Divider />
        {/* Email templates section */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold mb-2">{t("settings.email_template")}</h2>

          {/* Tabs */}
          <div className="flex border rounded-md overflow-hidden">
            <div className="px-6 py-2 relative bg-blue-50 text-primary border-b-[3px] border-blue-400">{t("settings.invoice_email")}</div>
          </div>

          {/* Email form */}
          <div className="p-4 flex flex-col gap-4 border rounded-md shadow-sm">
            <div className="flex flex-col gap-2">
              <label htmlFor="emailSubject" className="text-gray-800 font-medium text-sm">
                {t("settings.email_subject")}
              </label>
              <input
                id="emailSubject"
                type="text"
                value={values.subjectEmailTemplate}
                onChange={(e) => setValues({ ...values, subjectEmailTemplate: e.target.value })}
                placeholder={t("settings.email_subject_placeholder")}
                className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="emailBody" className="text-gray-800 font-medium text-sm">
                {t("settings.email_body")}
              </label>
              <textarea
                id="emailBody"
                value={values.bodyEmailTemplate}
                onChange={(e) => setValues({ ...values, bodyEmailTemplate: e.target.value })}
                placeholder={t("settings.email_body_placeholder")}
                rows={6}
                className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2 resize-y"
              />
            </div>
          </div>
        </div>
        <Divider />
        {/* Terms & conditions */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold mb-2">{t("settings.legal_information")}</h2>

          <div className="flex w-full gap-8">
            <div className="flex items-center justify-center">
              <img src={factureLogo} alt="Créez votre première facture" className="w-44" />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="terms" className="text-gray-800 font-medium">
                {t("settings.terms_conditions")}
              </label>
              <textarea
                id="terms"
                value={values.termsEmailTemplate}
                onChange={(e) => setValues({ ...values, termsEmailTemplate: e.target.value })}
                placeholder={t("settings.terms_conditions_placeholder")}
                rows={5}
                className="border-[1px] placeholder:text-slate-300 rounded-md text-gray-800 px-3 py-2 resize-y"
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-2" id="gocardless">
          <div className="flex w-full gap-8">
            <div className="flex items-start justify-center">
              <img src={gocardlessLogo} alt="Créez votre première facture" className="w-44" />
            </div>
            <div className="flex flex-col w-full gap-2">
              <h2 className="text-primary text-lg font-semibold">{t("settings.gocardless.modal_title")}</h2>
              {gocardlessStatus === "successful" ? (
                <div className="flex flex-col gap-4 bg-green-50 rounded-lg p-6 border border-green-200">
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    <p className="text-green-700 font-semibold">{t("settings.gocardless.account_configured")}</p>
                  </div>
                  <div className="flex items-center gap-2 text-green-600">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className="text-sm">{t("settings.gocardless.can_create_payment_links")}</p>
                  </div>
                </div>
              ) : (
                <>
                  <label htmlFor="terms" className="text-gray-400 font-medium text-sm mb-4">
                    {t("settings.gocardless_decription")}
                  </label>
                  <button type="button" className="border border-black px-4 py-1 rounded-md w-fit" onClick={() => setOpenPaymentModal(true)}>
                    {t("settings.gocardless_link")}
                  </button>
                  <PaymentModal isOpen={openPaymentModal} onClose={() => setOpenPaymentModal(false)} />
                </>
              )}
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex items-center justify-center mt-4 w-full">
          <button type="submit" className="btn-primary">
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MyOrganizations;
