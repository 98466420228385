import { useEffect, useRef } from "react";

// timeout is the timeout in milliseconds before the chat is opened, default is 1 minute
// delayInSeconds is the minimum time in seconds that must pass between chat openings, default is 24 hours (86400 seconds)
const useCrispInactivity = (timeout = 60000, delayInSeconds = 86400, enabled = true) => {
  // Using useRef to persist the timer between renders without causing re-renders
  // when the timer value changes, and to maintain the same reference across component lifecycle
  const timer = useRef(null);
  const isActive = useRef(true);

  const resetTimer = () => {
    if (!isActive.current) {
      isActive.current = true;
    }

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      handleInactivity();
    }, timeout);
  };

  const handleInactivity = () => {
    isActive.current = false;

    const lastOpened = localStorage.getItem("crispChatOpenedAt");
    const now = new Date();

    const shouldOpen = () => {
      if (!lastOpened) return true;

      const last = new Date(lastOpened);
      const diff = now - last;
      const secondsPassed = diff / 1000;
      return secondsPassed >= delayInSeconds;
    };

    if (shouldOpen() && window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
      localStorage.setItem("crispChatOpenedAt", now.toISOString());
    }
  };

  useEffect(() => {
    if (!enabled) return;

    const handleClick = () => resetTimer();

    window.addEventListener("click", handleClick);
    resetTimer(); // Start initially

    return () => {
      window.removeEventListener("click", handleClick);
      clearTimeout(timer.current);
    };
  }, [timeout, delayInSeconds, enabled]);
};

export default useCrispInactivity;
