import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "@components/utils/Modal";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "@services/mixpanel";
import { Combobox } from "@components/utils/Combobox";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const CreatePettyCash = ({ onCreated, open, onClose }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [currency, setCurrency] = useState(organization?.currency || "EUR");

  useEffect(() => {
    if (open) {
      Mixpanel.track(MIXPANEL_EVENTS.btn_open_create_petty_cash);
    }
  }, [open]);

  const handleCreatePettyCash = async (e) => {
    try {
      e.preventDefault();
      const { ok, code, data } = await api.post(`/account/`, { currency, details: "petty_cash", OrganizationId: organization._id });
      if (!ok) throw { code };
      onCreated(data);
      toast.success(t("toast.sucess.created"));
      onClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  return (
    <div>
      <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)] !overflow-y-visible">
        <div className="w-full max-h-full flex flex-col gap-3 transform rounded-2xl py-4 px-4 bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-4 text-left text-[26px] font-semibold"> {t("myintegrations.create_petty_cash")} </div>
          <div className="py-4 px-4 ">
            <label htmlFor="currency" className="mb-2">
              {t("currency")}
            </label>
            <Combobox
              id="currency"
              options={["EUR", "USD", "GBP", "CHF", "CAD", "AUD", "YEN", "JPY", "NZD", "SEK", "CNH", "PLN", "AED"]}
              value={currency}
              onChange={(value) => setCurrency(value)}
              placeholder={t("myintegrations.currency_placeholder")}
              nullable={false}
            />
          </div>
          <div className="flex gap-2 justify-between">
            <button className="btn-secondary" onClick={onClose}>
              {t("cancel")}
            </button>
            <button className="btn-primary" onClick={handleCreatePettyCash}>
              {t("create")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreatePettyCash;
