import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaRegLightbulb, FaRocket, FaBriefcase } from "react-icons/fa";

import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import Loader from "@components/utils/Loader";
import { STRIPE_CHECKOUT_LINK_STARTER, STRIPE_CHECKOUT_LINK_LITE, STRIPE_CHECKOUT_LINK_BUSINESS } from "../../config";

const MySubscription = () => {
  const user = useSelector((state) => state.Auth.user);
  const organization = useSelector((state) => state.Auth.organization);
  const { t } = useTranslation();

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_subscription);
  }, []);

  if (!user) return <Loader />;

  return (
    <div className="p-6 flex flex-col gap-12">
      <div className="flex flex-col items-start gap-2">
        <h1 className="text-2xl font-bold text-gray-900">{t("my_subscription_title")}</h1>
        <p className="text-gray-600">{t("my_subscription_description")}</p>
      </div>
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <PlanCard
            icon={<FaRegLightbulb />}
            title="Starter"
            price={t("free")}
            link={`${STRIPE_CHECKOUT_LINK_STARTER}?prefilled_email=${user.email}&client_reference_id=${organization._id}`}
            features={[
              t("my_subscription_plans.one_user"),
              t("my_subscription_plans.one_bank_connection"),
              t("my_subscription_plans.unlimited_invoices"),
              t("my_subscription_plans.unlimited_clients"),
              t("my_subscription_plans.tax_report_and_ledger"),
            ]}
            isCurrentPlan={organization?.plan === "starter"}
          />

          <PlanCard
            icon={<FaRocket />}
            title="Lite"
            price="19€"
            link={`${STRIPE_CHECKOUT_LINK_LITE}?prefilled_email=${user.email}&client_reference_id=${organization._id}`}
            features={[
              t("my_subscription_plans.unlimited_users"),
              t("my_subscription_plans.unlimited_bank_integrations"),
              t("my_subscription_plans.all_starter_features"),
              t("my_subscription_plans.stripe_integration"),
              t("my_subscription_plans.ai_copilot"),
              t("my_subscription_plans.premium_support"),
            ]}
            isCurrentPlan={organization?.plan === "lite"}
          />

          <PlanCard
            icon={<FaBriefcase />}
            title="Business"
            price="99€"
            link={`${STRIPE_CHECKOUT_LINK_BUSINESS}?prefilled_email=${user.email}&client_reference_id=${organization._id}`}
            features={[
              t("my_subscription_plans.all_lite_features"),
              t("my_subscription_plans.cash_flow"),
              t("my_subscription_plans.profit_loss"),
              t("my_subscription_plans.custom_invoice"),
              t("my_subscription_plans.send_invoice_by_email"),
              t("my_subscription_plans.payment_link"),
              t("my_subscription_plans.mobile_ocr_scan"),
              t("my_subscription_plans.dedicated_onboarding"),
            ]}
            isCurrentPlan={organization?.plan === "business"}
            isPopular
          />
        </div>
      </div>
    </div>
  );
};

const PlanCard = ({ icon, title, price, link, features, isCurrentPlan, isPopular }) => {
  const { t } = useTranslation();

  const handlePlanClick = () => {
    if (isCurrentPlan) return;

    if (title === "Starter") {
      Mixpanel.track(MIXPANEL_EVENTS.plan_clicked_starter);
    } else if (title === "Lite") {
      Mixpanel.track(MIXPANEL_EVENTS.plan_clicked_lite);
    } else if (title === "Business") {
      Mixpanel.track(MIXPANEL_EVENTS.plan_clicked_business);
    }
  };

  return (
    <div
      className={`relative bg-white rounded-2xl p-8 h-full w-[350px] transition-all duration-300 transform hover:scale-105 ${
        isCurrentPlan ? "ring-2 ring-yellow-500 shadow-xl" : "border border-gray-100 hover:shadow-xl"
      }`}>
      {isPopular && <div className="absolute -top-3 left-1/2 -translate-x-1/2 bg-yellow-500 text-white px-4 py-1 rounded-full text-sm font-medium">{t("most_popular")}</div>}
      <div className="space-y-6 flex flex-col h-full">
        <div className="flex items-center space-x-3">
          <div className={`p-3 rounded-lg ${isCurrentPlan ? "bg-yellow-100 text-yellow-600" : "bg-gray-100 text-gray-600"}`}>{icon}</div>
          <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
        </div>
        <div>
          <span className="text-4xl font-bold text-gray-900">{price}</span>
          {price !== t("free") && <span className="text-gray-500 text-lg ml-2">{t("per_month")}</span>}
        </div>

        <ul className="space-y-4 flex-grow">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start text-gray-600">
              <FaCheck className="text-blue-500 mr-3 mt-1 flex-shrink-0" />
              <span className="text-base">{feature}</span>
            </li>
          ))}
        </ul>

        <a href={link} className="w-full" onClick={handlePlanClick}>
          <button
            className={`w-full py-3 px-6 rounded-xl text-base font-semibold transition-all duration-300 ${
              isCurrentPlan ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg hover:shadow-blue-100"
            }`}
            disabled={isCurrentPlan}>
            {isCurrentPlan ? t("current_plan") : t("get_started")}
          </button>
        </a>
      </div>
    </div>
  );
};

export default MySubscription;
