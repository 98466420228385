import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { Select } from "@components/utils/Select";
import { HiOutlineSparkles } from "react-icons/hi2";

const TypeSelection = ({ transaction, updateTransaction }) => {
  const { t } = useTranslation();

  // Translation keys:
  // transaction.ai_type_tooltip - Tooltip explaining AI suggested transaction types

  const handleRemoveType = () => {
    updateTransaction({ params: { ...transaction, type: null } });
  };

  const handleRemoveAiType = () => {
    updateTransaction({ params: { ...transaction, aiType: null } });
  };

  const handleTypeChange = (type) => {
    const body = { type };
    if (type === "TRANSFER") {
      body.category = "8080";
    }
    updateTransaction({ params: { ...transaction, ...body } });
  };

  // If there's a selected type, show a chip with an X to clear it
  if (transaction.type) {
    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-gray-100 via-gray-50 to-gray-100 text-black px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1.5">
          <span className="truncate">{t(transaction.type.toLowerCase())}</span>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px]"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveType();
            }}
          />
        </div>
      </div>
    );
  }

  // If there's an AI suggestion but no selected type
  if (transaction.aiType && transaction.aiTypeConfidence > 0.65) {
    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div className="bg-gradient-to-r w-full from-purple-100 via-purple-50 to-purple-100 text-purple-500 px-2 py-1.5 rounded-md text-xs flex items-center justify-between gap-1">
          <div className="flex items-center gap-1 overflow-hidden">
            <HiOutlineSparkles className="text-sm flex-shrink-0" />
            <span className="truncate">{t(transaction.aiType.toLowerCase())}</span>
          </div>
          <IoMdClose
            className="cursor-pointer w-3.5 h-3.5 my-[3px] flex-shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveAiType();
            }}
          />
        </div>
      </div>
    );
  }

  // If no type is selected and no AI suggestion, show the select
  return (
    <div className="relative">
      <Select
        options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
        value={transaction.type}
        onChange={handleTypeChange}
        getLabel={(e) => t(e.toLowerCase())}
        placeholder={t("type")}
        containerClassName="h-8 rounded-md border-gray-300"
        nullable={false}
        width="w-32"
      />
    </div>
  );
};

export default TypeSelection;
